import React, { memo, useState, useEffect } from 'react';
import { VictoryPie, VictoryContainer } from 'victory';
import styled from 'styled-components';
import { viewports } from '../../utils/variables';

const PieChart = ({ stats, colorScale }) => {
  const initialData = [
    ...stats.map(_ => ({
      x: _.name,
      y: 0,
    })),
    { x: 'empty', y: 100 },
  ];

  const [data, setData] = useState(initialData);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const total = stats.reduce((sum, item) => sum + item.percent, 0);
      const newData = [
        ...stats.map(_ => ({
          x: _.name,
          y: _.percent,
        })),
        { x: 'empty', y: 100 - total },
      ];
      setData(newData);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const colorScheme = () => {
    if (data.length < 4) {
      const slicedColors = [...colorScale.slice(0, data.length - 1), colorScale[3]];
      return slicedColors;
    }
    return colorScale;
  };

  return (
    <VictoryPie
      data={data}
      colorScale={colorScheme()}
      height={160}
      width={160}
      radius={80}
      innerRadius={40}
      animate={{
        duration: 2000,
      }}
      labels={() => ''}
      containerComponent={<Container height={160} width={160} responsive={false} />}
    />
  );
};

export default memo(PieChart);

const Container = styled(VictoryContainer)`
  margin: 0 auto;

  @media ${viewports.medium} {
    margin: 0;
  }
`;
