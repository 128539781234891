import gql from 'graphql-tag';

const getActiveLocation = gql`
  query ActiveLocation($level: Int!, $id: Int) {
    activeLocation(level: $level, id: $id) {
      id
      name
      parentId
      parentName
      parentType
      locations {
        id
        name
        type
      }
      type
    }
  }
`;

export default getActiveLocation;
