export const fileNameFromPath = path => {
  const dirNames = path.split('/');
  return dirNames[dirNames.length - 1];
};

const filePathsToDraggableData = (userMedia, maxNumItems = 0) => {
  const filePaths = userMedia.map(media => media.url);
  const mediaItems = {};

  const files = Array.isArray(filePaths) ? [...filePaths] : [];

  userMedia.forEach(media => {
    mediaItems[`${media.url}`] = {
      id: media.id,
      url: media.url,
      name: fileNameFromPath(media.url),
    };
  });

  const column = {
    id: 'media-list',
    // sort by order
    itemIds: userMedia
      .sort((a, b) => a.order - b.order)
      .map(media => media.url),
  };

  // if (files.length < maxNumItems && maxNumItems !== 0) {
  //   mediaItems.uploadItem = { id: 'uploadItem', name: null };
  //   column.itemIds.unshift('uploadItem');
  // }
  return { mediaItems, column };
};

// const dummyData = {
// 	mediaItems: {
// 	  'item-1': { id: 'item-1', name: 'testing' },
// 	  'item-2': { id: 'item-2', name: 'testing1' },
// 	  'item-3': { id: 'item-3', name: 'testing2' },
// 	  'item-4': { id: 'item-4', name: 'testing3' },
// 	},
// 	column: {
// 	  id: 'media-list',
// 	  itemIds: ['item-1', 'item-2', 'item-3', 'item-4'],
// 	},
//   };
export default filePathsToDraggableData;
