import styled from 'styled-components';
import { fontFamilies, colors, viewports } from '../../utils/variables';

const Input = styled.input`
  align-items: center;
  background: ${(props) => (props.disabled ? '#efefef' : '#f8f8f8')};
  border: 1px ${(props) => (props.error ? colors.red : '#f8f8f8')} solid;
  border-radius: 1.25rem;
  color: #777777;
  display: flex;
  font-family: ${fontFamilies.regular};
  font-size: 1rem;
  height: 2.5rem;
  justify-content: center;
  outline: none;
  padding: 0 1.25rem 0 1rem;
  width: 100%;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  @media ${viewports.medium} {
    font-size: 0.875rem;
  }

  &::placeholder {
    color: #ccc;
    opacity: 1;
  }

  &::-moz-placeholder {
    color: #ccc;
    opacity: 1;
  }

  &:focus {
    border: 1px #dddddd solid;
  }
`;

export default Input;
