const localIds = {
  NA: 6,
  europe: 5,
  asia: 3,
  SA: 7,
  africa: 1,
  oceania: 4,
};

const productionIds = {
  NA: 18,
  europe: 15,
  asia: 9,
  SA: 21,
  africa: 3,
  oceania: 12,
};
const indicatorMarkers = [
  {
    id: process.env.GATSBY_ACTIVE_ENV === 'production' ? productionIds.NA : localIds.NA,
    x: 319,
    y: 239,
    size: 15,
    color: '#00C1A1',
    level: 'continent',
    route: 'north-america',
    label: `North America`,
  },
  {
    id: process.env.GATSBY_ACTIVE_ENV === 'production' ? productionIds.europe : localIds.europe,
    x: 870,
    y: 330,
    size: 16.25,
    color: '#ff4cdb',
    level: 'continent',
    route: 'europe',
    label: 'Europe',
  },
  {
    id: process.env.GATSBY_ACTIVE_ENV === 'production' ? productionIds.asia : localIds.asia,
    x: 1200,
    y: 339,
    size: 13.75,
    color: '#00BFE7',
    level: 'continent',
    route: 'asia',
    label: 'Asia',
  },
  {
    id: process.env.GATSBY_ACTIVE_ENV === 'production' ? productionIds.SA : localIds.SA,
    x: 510,
    y: 600,
    size: 13.75,
    color: '#338FFF',
    level: 'continent',
    route: 'south-america',
    label: 'South America',
  },
  {
    id: process.env.GATSBY_ACTIVE_ENV === 'production' ? productionIds.africa : localIds.africa,
    x: 859,
    y: 499,
    size: 15,
    color: '#FF9C00',
    level: 'continent',
    route: 'africa',
    label: 'Africa',
  },
  {
    id: process.env.GATSBY_ACTIVE_ENV === 'production' ? productionIds.oceania : localIds.oceania,
    x: 1250,
    y: 600,
    size: 13.75,
    color: '#58CF00',
    level: 'continent',
    route: 'oceania',
    label: 'Oceania',
  },
];

export default indicatorMarkers;
