import React, { memo } from 'react';
import styled from 'styled-components';

const Avatar = ({ alum, small }) =>
  alum.profileImage ? (
    <Base
      small={small}
      style={{
        backgroundImage: `url(${process.env.UPLOADS_URL}${alum.profileImage})`,
      }}
    />
  ) : (
    <Base small={small}>
      {alum.firstName.charAt(0).toUpperCase()}
      {alum.lastName.charAt(0).toUpperCase()}
    </Base>
  );

export default memo(Avatar);

const Base = styled.div`
  align-items: center;
  background-color: #555;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  color: #222;
  display: flex;
  flex: 0 0 ${props => (props.small ? '2.875rem' : '7.5rem')};
  font-size: ${props => (props.small ? '1rem' : '2.5rem')};
  height: ${props => (props.small ? '2.875rem' : '7.5rem')};
  justify-content: center;
  width: ${props => (props.small ? '2.875rem' : '7.5rem')};
`;
