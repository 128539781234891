import React, { memo, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Contact from './Contact';
import Info from './Info';
import { viewports } from '../../utils/variables';
import CloseButton from '../shared/CloseButton';

const AlumProfile = ({ alum, setActiveScreen, sidebar }) => {
  return (
    <Base>
      <Container sidebar={sidebar}>
        <Info alum={alum} />
        <Contact alum={alum} />
        <ExitPanelLink onClick={() => setActiveScreen(undefined)} />
      </Container>
    </Base>
  );
};

export default memo(AlumProfile);

const Base = styled.div`
  background-color: #f4f4f4;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 15;

  @media ${viewports.medium} {
    z-index: 5;
  }
`;

const Container = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;

  @media ${viewports.medium} {
    position: absolute;
    width: ${({ sidebar }) =>
      sidebar ? css`calc(100vw - 20rem);` : css`100%;`};
  }
`;

const ExitPanelLink = styled(CloseButton)`
  position: absolute;
  right: 1.9375rem;
  top: 1.9375rem;

  @media ${viewports.medium} {
    right: calc(2% + 1.9375rem);
  }
`;
