import React, { memo } from 'react';
import styled from 'styled-components';

const Visibility = ({ style, className, onClick }) => (
  <Base
    className={className}
    style={style}
    onClick={onClick}
    viewBox="0 0 9.47 9.47"
    xmlns="http://www.w3.org/2000/svg"
  >
    <svg
      className="MuiSvgIcon-root jss81 jss86"
      focusable="false" viewBox="0 0 24 24" aria-hidden="true"
    >
      <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
    </svg>
  </Base>
);

export default memo(Visibility);

const Base = styled.svg`
  fill: currentColor;
  width: 1em;
  height: 1em;
  // display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;

  // margin: 4px;
  // padding: 8px 16px;
  box-sizing: content-box;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.54);
`;
