import React, { memo } from 'react';
import styled from 'styled-components';
import Indicator from '../shared/Indicator';
import { viewports } from '../../utils/variables';

const Indicators = ({ indicators, visible, removeIndicator }) => (
  <Base visible={visible}>
    {indicators.map(({ key, color, size, x, y }) => (
      <Indicator
        key={key}
        color={color}
        size={size}
        x={`${x}px`}
        y={`${y}px`}
        removeIndicator={() => removeIndicator(key)}
      />
    ))}
  </Base>
);

export default memo(Indicators);

const Base = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  transition: visibility 1ms linear ${props => !props.visible && '1000ms'};

  @media ${viewports.medium} {
    visibility: visible;
  }
`;
