import React, { memo } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Formik, Form, Field } from 'formik';
import { Mutation } from 'react-apollo';
import { forgotPasswordValidation } from '../../utils/validationSchema';
import { fontFamilies, colors, viewports } from '../../utils/variables';
import TextInput from '../inputs/TextInput';
import SubmitButton from '../shared/SubmitButton';
import { Heading, Subheading } from '../styled/Typography';
import ServerErrorMessage from '../inputs/ServerErrorMessage';
import Button from '../styled/Button';
import CloseButton from '../shared/CloseButton';
import ResetSuccess from './ResetSuccess';
import forgotPassword from '../../data/mutations/forgotPassword';

const ForgotPassword = ({ toggleSidebar, activeScreen, setActiveScreen }) => (
  <Base>
    <Mutation mutation={forgotPassword}>
      {(handleForgotPassword, { error }) => (
        <Formik
          initialValues={{
            email: '',
          }}
          validate={forgotPasswordValidation}
          onSubmit={async (values, actions) => {
            try {
              const res = await handleForgotPassword({
                variables: {
                  email: values.email,
                },
              });
              console.log(res);
              actions.setSubmitting(false);
              // setActiveScreen('success');
            } catch (err) {
              if (!err.message.startsWith('GraphQL error: Please check'))
                console.error(err);

              actions.setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, errors, touched, values }) =>
            activeScreen === 'forgot' ? (
              <Form>
                <Heading>Forgot Password</Heading>
                <Subheading>
                  Enter your email associated with your account.
                </Subheading>
                {!isSubmitting && (
                  <ServerErrorMessage visible={error} error={error} />
                )}
                <Field name="email">
                  {({ field }) => (
                    <EmailInput
                      {...field}
                      label="Email Address"
                      placeholder="Enter email address"
                      error={errors.email}
                      touched={touched.email}
                    />
                  )}
                </Field>
                <NavContainer>
                  <BackButton
                    secondary
                    onClick={() => setActiveScreen(undefined)}
                  >
                    Back
                  </BackButton>
                  <ForgotButton
                    disabled={isSubmitting}
                    submitting={isSubmitting}
                  >
                    Submit
                  </ForgotButton>
                </NavContainer>
                <Register>
                  Don't have an account?
                  <RegisterLink onClick={() => toggleSidebar('register')}>
                    Register Now
                  </RegisterLink>
                </Register>
              </Form>
            ) : (
              <ResetSuccess email={values.email} />
            )
          }
        </Formik>
      )}
    </Mutation>
    <CloseLink
      onClick={() => {
        setActiveScreen(undefined);
        toggleSidebar(undefined);
      }}
    />
  </Base>
);

export default memo(ForgotPassword);

const Base = styled.div`
  background-color: #fff;
  bottom: 0;
  height: 100vh;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  padding: 3.4375rem 1.875rem;
  z-index: 100;

  @media ${viewports.medium} {
    padding: 3.4375rem 3.75rem;
    width: 30rem;
  }
`;

const CloseLink = styled(CloseButton)`
  position: absolute;
  right: 1.9375rem;
  top: 1.9375rem;
`;

const Register = styled.div`
  color: #999;
  font-size: 0.875rem;
  margin-top: 3.75rem;
  text-align: center;
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.125rem;
  margin-top: 3.125rem;
`;

const BackButton = styled(Button)`
  margin: 0;
`;

const ForgotButton = styled(SubmitButton)`
  margin: 0;
`;

const RegisterLink = styled.a`
  color: ${colors.blue};
  cursor: pointer;
  font-family: ${fontFamilies.bold};

  @media ${viewports.medium} {
    &:hover {
      color: ${colors.hoverBlue};
    }
  }
`;

const EmailInput = styled(TextInput)`
  margin-top: 0.5rem;
`;
