import gql from 'graphql-tag';

const getUserMedia = gql`
  query UserMedia($id: Int) {
    userMedia(id: $id) {
      media {
        url
        id
        order
      }
      bio
    }
  }
`;

export default getUserMedia;
