import React, { memo, useState, useContext } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { useWindowSize, useMedia } from 'the-platform';
import ExploreIcon from './ExploreIcon';
import LocationPin from './LocationPin';
import Logo from './Logo';
import SearchIcon from './SearchIcon';
import SettingsDropdown from './SettingsDropdown';
import { CreamSolid } from '../styled/Background';
import ExploreSidebar from './ExploreSidebar';
import SearchSidebar from './SearchSidebar';
import LocationRouter from './LocationRouter';
import { AlumniFetcherContext } from './AlumniFetcher';
import { colors, viewports } from '../../utils/variables';

const ExploreLayout = ({ tourScreenIndex, sidebar, setSidebar }) => {
  const exploreVisible = sidebar === 'explore';
  const searchVisible = sidebar === 'search';
  const { width } = useWindowSize();
  const medium = useMedia(viewports.medium);

  const layoutSpring = useSpring({
    x: exploreVisible ? 320 : 0,
    w: sidebar ? width - 320 : width,
  });

  const {
    setActiveScreen,
    activeScreen,
    activeLocation,
    fetchLocation,
    locations,
    flow,
    numAlumni,
    loading,
    level,
  } = useContext(AlumniFetcherContext);

  return (
    <Base>
      <animated.div
        style={
          medium
            ? {
                height: '100vh',
                overflow: 'hidden',
                position: 'relative',
                transform: layoutSpring.x.interpolate(
                  (x) => `translateX(${x}px)`
                ),
                width: layoutSpring.w.interpolate((w) => `${w}px`),
              }
            : {}
        }
      >
        {/* <CreamSolid /> */}
        <LocationRouter
          sidebar={sidebar}
          level={level}
          activeLocation={activeLocation}
          activeScreen={activeScreen}
          flow={flow}
          locations={locations}
          loading={loading}
          numAlumni={numAlumni}
        />
      </animated.div>
      {activeScreen !== 'setup' && (
        <>
          <ExploreSidebar toggleSidebar={setSidebar} visible={exploreVisible} />
          <ExploreIcon
            onClick={() => setSidebar('explore')}
            highlighted={activeScreen === 'tour' && tourScreenIndex === 0}
          />
          <SearchSidebar toggleSidebar={setSidebar} visible={searchVisible} />
          <SearchIcon
            onClick={() => setSidebar('search')}
            highlighted={activeScreen === 'tour' && tourScreenIndex === 1}
            forceVisible={activeScreen === 'alum' && sidebar !== 'search'}
            forceLowerRight={activeScreen === 'alum'}
          />
          <SettingsDropdown
            setActiveScreen={setActiveScreen}
            activeScreen={activeScreen}
            searchVisible={searchVisible}
            highlighted={activeScreen === 'tour' && tourScreenIndex === 2}
          />
          <LocationPin
            fetchLocation={fetchLocation}
            highlighted={activeScreen === 'tour' && tourScreenIndex === 3}
          />
          <Logo />
        </>
      )}
    </Base>
  );
};

export default memo(ExploreLayout);

const Base = styled.div`
  background: ${colors.offWhite};
  overflow: hidden;
  position: relative;
  width: 100%;

  @media ${viewports.medium} {
    height: 100vh;
    height: -webkit-fill-available;
  }
`;
