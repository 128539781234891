import React, { memo } from 'react';
import { Field } from 'formik';
import styled from 'styled-components';
import TextInput from '../inputs/TextInput';
import SelectInput, { SelectCountry } from '../inputs/SelectInput';
import { Label } from '../styled/Typography';
import {
  birthdateMonths,
  getBirthdateDays,
  birthdateYears,
  majors,
} from '../../utils/selectOptions';
import { viewports } from '../../utils/variables';

const EditForm = () => {
  return (
    <Base>
      <Row>
        <Column>
          <Field name="firstName">
            {({ field, form }) => (
              <TextInput
                {...field}
                form={form}
                label="First Name"
                placeholder="Enter first name"
                error={form.errors.firstName}
                touched={form.touched.firstName}
              />
            )}
          </Field>
        </Column>
        <Column>
          <Field name="lastName">
            {({ field, form }) => (
              <TextInput
                {...field}
                form={form}
                label="Last Name"
                placeholder="Enter last name"
                error={form.errors.lastName}
                touched={form.touched.lastName}
              />
            )}
          </Field>
        </Column>
      </Row>
      <Row>
        <Column>
          <Field name="preferredName">
            {({ field, form }) => (
              <TextInput
                {...field}
                form={form}
                label="Preferred First Name (Optional)"
                placeholder="Enter preferred first name"
                error={form.errors.preferredName}
                touched={form.touched.preferredName}
              />
            )}
          </Field>
        </Column>
        <Column>
          <Field name="email">
            {({ field, form }) => {
              return (
                <TextInput
                  {...field}
                  form={form}
                  label="Email"
                  placeholder="Enter email"
                  error={form.errors.email}
                  touched={form.touched.email}
                />
              );
            }}
          </Field>
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Birthdate</Label>
          <SelectContainer>
            <Field name="birthdateMonth">
              {({ field, form }) => (
                <SelectInput
                  {...field}
                  form={form}
                  placeholder="Month"
                  width="8.25rem"
                  options={birthdateMonths}
                  error={form.errors.birthdateMonth}
                  touched={form.touched.birthdateMonth}
                />
              )}
            </Field>
            <Field name="birthdateDay">
              {({ field, form }) => (
                <SelectInput
                  {...field}
                  form={form}
                  placeholder="Day"
                  maxWidth="6.25rem"
                  minWidth="4.5rem"
                  options={getBirthdateDays(form.values.birthdateMonth)}
                  error={form.errors.birthdateDay}
                  touched={form.touched.birthdateDay}
                />
              )}
            </Field>
            <Field name="birthdateYear">
              {({ field, form }) => (
                <SelectInput
                  {...field}
                  form={form}
                  placeholder="Year"
                  maxWidth="6.25rem"
                  minWidth="6rem"
                  options={birthdateYears}
                  error={form.errors.birthdateYear}
                  touched={form.touched.birthdateYear}
                />
              )}
            </Field>
          </SelectContainer>
        </Column>
      </Row>
      <Row>
        <Column>
          <Field name="major">
            {({ field, form }) => {
              return (
                <SelectInput
                  {...field}
                  form={form}
                  label="Major"
                  placeholder={'Enter major'}
                  error={form.errors.major}
                  touched={form.touched.major}
                  options={majors}
                />
              );
            }}
          </Field>
        </Column>
        <Column>
          <Field name="secondaryMajor">
            {({ field, form }) => {
              return (
                <SelectInput
                  {...field}
                  form={form}
                  label="Second Major (if applicable)"
                  placeholder={'Enter major'}
                  error={form.errors.secondaryMajor}
                  touched={form.touched.secondaryMajor}
                  options={majors}
                />
              );
            }}
          </Field>
        </Column>
      </Row>
      <Row>
        <Column>
          <SelectContainer>
            <Field name="portalId">
              {({ field, form }) => (
                <HalfInput
                  {...field}
                  form={form}
                  label="Portal ID Number"
                  placeholder="Enter Portal ID number"
                  error={form.errors.portalId}
                  touched={form.touched.portalId}
                />
              )}
            </Field>

            <Field name="graduationYear">
              {({ field, form }) => (
                <HalfInput
                  {...field}
                  form={form}
                  label="Graduation Year"
                  placeholder="Enter graduation year"
                  error={form.errors.graduationYear}
                  touched={form.touched.graduationYear}
                />
              )}
            </Field>
          </SelectContainer>
        </Column>
      </Row>
      <Divider />
      <Row>
        <Column>
          <Field name="streetAddress1">
            {({ field, form }) => (
              <TextInput
                {...field}
                form={form}
                label="Street Address 1"
                placeholder="Enter street address 1"
                error={form.errors.streetAddress1}
                touched={form.touched.streetAddress1}
              />
            )}
          </Field>
          <Field name="streetAddress2">
            {({ field, form }) => (
              <TextInput
                {...field}
                form={form}
                label="Street Address 2"
                placeholder="Enter street address 2"
                error={form.errors.streetAddress2}
                touched={form.touched.streetAddress2}
              />
            )}
          </Field>
        </Column>
        <Column>
          <Field name="city">
            {({ field, form }) => (
              <TextInput
                {...field}
                form={form}
                label="City"
                placeholder="Enter city"
                error={form.errors.city}
                touched={form.touched.city}
              />
            )}
          </Field>

          <SelectContainer>
            <Field name="state">
              {({ field, form }) => (
                <HalfInput
                  {...field}
                  form={form}
                  label="State/Territory"
                  placeholder="Enter state/territory"
                  error={form.errors.state}
                  touched={form.touched.state}
                />
              )}
            </Field>
            <Field name="postalCode">
              {({ field, form }) => (
                <HalfInput
                  {...field}
                  form={form}
                  label="Postal Code"
                  placeholder="Enter postal code"
                  error={form.errors.postalCode}
                  touched={form.touched.postalCode}
                />
              )}
            </Field>
          </SelectContainer>

          <Field name="country">
            {({ field, form }) => {
              return (
                <PlaceheldValue>
                  <SelectCountry
                    {...field}
                    value={{
                      value: field.value.label
                        ? field.value.label
                        : field.value,
                      label: field.value.label
                        ? field.value.label
                        : field.value,
                    }}
                    form={form}
                    label="Country"
                    placeholder={'Enter country'}
                    error={form.errors.country}
                    touched={form.touched.country}
                  />
                </PlaceheldValue>
              );
            }}
          </Field>
        </Column>
      </Row>
    </Base>
  );
};

export default memo(EditForm);

const Base = styled.div`
  padding-bottom: 2.5rem;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  min-width: 18.75rem;
`;

const Column = styled.div`
  width: 100%;
  min-width: 18.75rem;
  @media screen and (min-width: 730px) {
    width: 50%;
    &:nth-of-type(odd) {
      padding-right: 15px;
    }

    &:nth-of-type(even) {
      padding-left: 15px;
    }
  }
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Divider = styled.div`
  background: #f4f4f4;
  height: 2px;
  margin-bottom: 2.5rem;
  width: 100%;
`;

const HalfInput = styled(TextInput)`
  width: calc(100% - 8px);
`;

const PlaceheldValue = styled.span`
  &&&:placeholder {
    color: #000s;
  }
`;
