import React, { memo } from 'react';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';
import { fontFamilies, viewports } from '../../utils/variables';
import AnimatedTextContainer from './AnimatedTextContainer';
import { landingLocations } from './landingData';

const AnimatedText = ({ activeScene }) => {
  const locationTransition = useTransition(
    landingLocations(activeScene),
    (l) => l,
    {
      from: { o: 0, p: 'absolute', y: 2 },
      enter: { o: 1, p: 'relative', y: 0 },
      leave: { o: 0, p: 'absolute', y: -2 },
    }
  );
  return (
    <>
      <TitleContainer>
        <AnimatedTextContainer visible={activeScene >= 0 && activeScene <= 3}>
          <Pretitle>Welcome to the</Pretitle>
          <Title>ArtCenter Alumni Portal</Title>
        </AnimatedTextContainer>
      </TitleContainer>
      <ConnectWithContainer>
        <AnimatedTextContainer visible={activeScene >= 4}>
          <ConnectWith>Connect with</ConnectWith>
        </AnimatedTextContainer>
      </ConnectWithContainer>
      <LocationContainer>
        {locationTransition.map(({ item, props, key }) => (
          <animated.div
            key={key}
            style={{
              position: props.p,
              opacity: props.o.interpolate((o) => o),
              transform: props.y.interpolate((y) => `translateY(${y}rem)`),
            }}
          >
            <Location>{item}</Location>
          </animated.div>
        ))}
      </LocationContainer>
    </>
  );
};

export default memo(AnimatedText);

const TitleContainer = styled.div`
  height: 10.5rem;
  left: 0;
  overflow: hidden;
  top: 0;

  @media ${viewports.medium} {
    height: 12rem;
    width: 34.375rem;
  }
`;

const Pretitle = styled.div`
  font-size: 1.25rem;
  letter-spacing: -0.54px;
  margin-bottom: 0.5rem;

  @media ${viewports.medium} {
    font-size: 2.25rem;
    line-height: 56px;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-family: ${fontFamilies.bold};
  letter-spacing: -0.5px;

  @media ${viewports.medium} {
    font-size: 3.75rem;
    line-height: 58px;
  }
`;

const ConnectWithContainer = styled.div`
  height: 5rem;
  overflow: hidden;
  position: absolute;
  top: 0;
`;

const ConnectWith = styled.h2`
  color: #fff;
  font-size: 2.5rem;
  letter-spacing: -0.5px;

  @media ${viewports.medium} {
    font-size: 3.75rem;
  }
`;

const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  left: 0;
  overflow: hidden;
  position: absolute;
  height: 5rem;
  right: 0;
  top: 2.5rem;

  @media ${viewports.medium} {
    top: 3.625rem;
  }
`;

const Location = styled.h3`
  font-size: 2.5rem;
  font-family: ${fontFamilies.bold};
  letter-spacing: -0.5px;

  @media ${viewports.medium} {
    font-size: 3.75rem;
    line-height: 58px;
  }
`;
