import React, { memo } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { useMedia } from 'the-platform';
import countryList from 'react-select-country-list';
import countryCorrections from '../../data/countryCorrections';

import { Label } from '../styled/Typography';
import { fontFamilies, colors, viewports } from '../../utils/variables';
import Toggle from '../shared/Toggle';
import DropdownIndicator from './DropdownIndicator';
import ErrorMessage from './ErrorMessage';
import MultiValueRemove from './MultiValueRemove';

const SelectInput = ({
  label,
  placeholder,
  width,
  minWidth,
  maxWidth,
  toggle,
  error,
  options,
  value,
  name,
  form,
  touched,
  className,
  toggled,
  multi,
  isClearable = false,
  blurInputOnSelect = true,
  handleChange,
  handleInputChange,
  noOptionsText,
  isSearchable = false,
  menuPlacement = 'bottom',
  tabIndex,
  tabSelectsValue = true,
}) => {
  const medium = useMedia(viewports.medium);
  const customStyles = {
    // inside the select
    valueContainer: (base) => ({
      ...base,
      color: '#777',
      padding: 0,
    }),
    container: () => ({
      width: width || '100%',
      minWidth: minWidth || '0px',
      maxWidth: maxWidth || '100%',
      display: 'flex',
    }),
    // inside the select
    control: (base, state) => {
      let border;
      if (error) {
        border = `1px ${colors.red} solid`;
      } else {
        border = state.isFocused == true ? `1px #dddddd solid` : 'none';
      }
      return {
      alignItems: 'center',
      background: '#f8f8f8',
      border: border,
      borderRadius: '1.25rem',
      color: '#777',
      display: 'flex',
      fontFamily: fontFamilies.regular,
      fontSize: medium ? '0.875rem' : '1rem',
      minHeight: '2.5rem',
      justifyContent: 'center',
      outline: 'none',
      padding: multi ? '0.25rem 1rem 0.25rem 0.5rem' : '0.25rem 1.25rem 0.25rem 1rem',
      position: 'relative',
      width: '100%',
    }},
    // inside the select before a value is set
    placeholder: (base) => ({
      ...base,
      color: !value || value === '' ? '#ccc' : '#777',
      fontFamily: fontFamilies.regular,
      fontSize: medium ? '0.875rem' : '1rem',
      left: 0,
      marginLeft: 0,
      marginRight: 0,
      opacity: 1,
    }),
    indicatorSeparator: () => ({ opacity: 0 }),
    // the box around all the options, popup panel
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      border: '2px #D7D7D7 solid',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.06)',
      width: '100%',
    }),
    // the box around all the options, popup panel
    menuList: (base) => ({
      ...base,
      background: '#fff',
      padding: 0,
      width: '100%',
    }),
    // the one of many options
    option: (base, state) => ({
      ...base,
      alignItems: 'center',
      background: state.isFocused ? '#338FFF' : '#fff',
      color: state.isFocused ? '#fff' : '#999999',
      display: 'flex',
      fontFamily: fontFamilies.regular,
      fontSize: medium ? '0.875rem' : '1rem',
      height: '2.5rem',
      lineHeight: '133%',
      paddingLeft: '0.9375rem',
      margin: 0,
    }),
    // the value in the select after being set
    singleValue: (base) => ({
      ...base,
      color: '#777777',
      fontFamily: fontFamilies.regular,
      fontSize: medium ? '0.875rem' : '1rem',
      left: 0,
      marginLeft: 0,
      marginRight: 0,
      opacity: 1,
      overflow: 'visible',
    }),
    // I guess if there are no options, it sets a message?
    noOptionsMessage: (base) => {
      return {
        ...base,
        alignItems: 'center',
        background: '#fff',
        color: '#999999',
        display: 'flex',
        fontFamily: fontFamilies.regular,
        fontSize: medium ? '0.875rem' : '1rem',
        height: '2.5rem',
        margin: 0,
        paddingLeft: '0.9375rem',
      };
    },
    // not sure how to show this
    multiValue: (base) => ({
      ...base,
      alignItems: 'center',
      background: colors.blue,
      borderRadius: '0.75rem',
      display: 'flex',
      height: '1.5rem',
      justifyContent: 'center',
      margin: '2px 2px 2px 0',
      width: 'calc(50% - 2px)',
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: '#fff',
      fontSize: '0.75rem',
    }),
    multiValueRemove: () => ({
      cursor: 'pointer',
      padding: '0 0.5rem',
      marginLeft: 'auto',
    }),
  };

  return (
    <Base className={className}>
      {label && <Label>{label}</Label>}
      <InputWrapper>
        <Select
          // https://react-select.com/props

          // onInputChange (fn)
          // Handle change events on the input
          onInputChange={handleInputChange}

          // blurInputOnSelect (default: boolean = isTouchCapable())
          // Remove focus from the input when the user selects an option (handy for dismissing the keyboard on touch devices)
          // our own SelectInput param default: true
          blurInputOnSelect={true}
          

          // captureMenuScroll (default: boolean = !isTouchCapable())
          // When the user reaches the top/bottom of the menu, prevent scroll on the scroll-parent
          captureMenuScroll={false}

          // closeMenuOnSelect (default: boolean = true)
          // Close the select menu when the user selects an option
          closeMenuOnSelect={false}

          // autoFocus (boolean)
          // Focus the control when it is mounted
          // NOTE: Don't use this otherwise will focus on bottom elements

          // maxMenuHeight number = 300
          // Maximum height of the menu before scrolling
          maxMenuHeight={300}
          
          // menuShouldBlockScroll (default: boolean = false)
          // Whether to block scroll events when the menu is open
          menuShouldBlockScroll={true}

          // isSearchable (default: boolean = true)
          // Whether to enable search functionality
          // our own SelectInput param default: false
          // isSearchable={medium}
          isSearchable={isSearchable}

          // menuPlacement (default: "bottom")
          // choices "auto", "bottom", "top"
          // Default placement of the menu in relation to the control. 'auto' will flip when there isn't enough space below the control.
          // our own SelectInput param default: "bottom"
          menuPlacement={menuPlacement}

          // isMulti (boolean)
          // Support multiple selected options
          isMulti={multi}

          // isClearable boolean
          // Is the select value clearable
          // our own SelectInput param default: false
          // NOTE: if true, will have a single X to clear all, if false, each selected item will have its own X
          isClearable={isClearable}
          
          name={name}
          value={value}
          onChange={
            handleChange
              ? (option) => handleChange(option)
              : (option) => form.setFieldValue(name, option)
          }
          styles={customStyles}
          options={options}
          placeholder={value || placeholder}
          components={{ DropdownIndicator, MultiValueRemove }}
          noOptionsMessage={() => {
            return noOptionsText;
          }}
          tabIndex={tabIndex}
          tabSelectsValue={tabSelectsValue}
        />
        {toggle && <Toggle form={form} toggled={toggled} name={name} />}
      </InputWrapper>
      <ErrorMessage visible={touched && error} error={error} />
    </Base>
  );
};

export default memo(SelectInput);

// TODO: update to functional component with useEffect
export class SelectCountry extends React.Component {
  state = { countryOptions: [] };

  componentDidMount() {
    let list = this.correctCountries(countryList().getData());

    // put usa in the front as well as where it normally is
    const usa = list.find((country) => country.value === 'US');
    if (usa) {
      list.unshift(usa);
    }

    this.setState({ countryOptions: list });
    const value = list.find((country) => country.label === this.props.value.label);
    this.props.form.values.country = value;
    this.props.form.setFieldValue('country', value);
  }

  correctCountries(cntryList) {
    const updatedList = [...cntryList];
    updatedList.forEach((cntry, i) => {
      const correction = countryCorrections[cntry.label];
      if (correction) {
        updatedList[i] = { label: correction, value: updatedList.value };
      }
    });

    return updatedList;
  }

  render() {
    const { countryOptions } = this.state;
    return <SelectInput {...this.props} options={countryOptions} />;
  }
}

const Base = styled.div`
  margin-bottom: 1.875rem;
  flex-shrink: 0;
`;

const InputWrapper = styled.div`
  align-items: center;
  position: relative;
  flex-shrink: 0;

  @media ${viewports.medium} {
    display: flex;
  }
`;
