import gql from 'graphql-tag';

const getPendingUsers = gql`
  query pendingUsers {
    pendingUsers {
      id
      email
      portalId
      firstName
      lastName
      preferredName
      major
      secondaryMajor
      birthdate
      graduationYear
      streetAddress1
      streetAddress2
      city
      state
      postalCode
      country
    }
  }
`;

export default getPendingUsers;
