const { userFromLocalCookie } = require('./src/utils/auth');
const metadata = require('./src/metadata.json');

const preferDefault = (m) => (m && m.default) || m;
exports.wrapRootElement = preferDefault(require(`./inject-provider`));
/*
exports.onInitialClientRender = () => {
  window.onUsersnapCXLoad = function (api) {
    api.init({
      custom: { buildNo: metadata.buildRevision, userId: +userFromLocalCookie() },
    });
  };
  const script = document.createElement('script');
  script.defer = 1;
  script.src =
    'https://widget.usersnap.com/global/load/921bb642-2074-43c8-ad0d-c44d1dde8dff?onload=onUsersnapCXLoad';
  document.getElementsByTagName('head')[0].appendChild(script);
};
*/