import React, { createContext, memo, useState } from 'react';

export const ActiveFiltersContext = createContext();

const ActiveFilters = ({ children }) => {
  const [filterPage, setFilterPage] = useState(undefined);
  const [activeFilters, setActiveFilters] = useState({
    majors: [],
    graduationYearFrom: null,
    graduationYearTo: null,
    company: '',
    jobTitle: '',
    industries: [],
  });
  const [filtered, setFilterered] = useState(false);

  const handleSetActiveFilters = (filters) => {
    const filterCopy = { ...filters };
    const { graduationYearFrom, graduationYearTo, company, jobTitle } = filterCopy;
    // convert null to empty arrays
    if (filterCopy.majors === null) filterCopy.majors = [];
    if (filterCopy.industries === null) filterCopy.industries = [];
    const { majors, industries } = filterCopy;
    setActiveFilters(filterCopy);
    const isFiltered = !(
      majors.length === 0 &&
      graduationYearFrom === null &&
      graduationYearTo === null &&
      company === '' &&
      jobTitle === '' &&
      industries.length === 0
    );
    setFilterered(isFiltered);
  };

  const resetActiveFilters = () => {
    handleSetActiveFilters({
      majors: [],
      graduationYearFrom: null,
      graduationYearTo: null,
      company: '',
      jobTitle: '',
      industries: [],
    });
  };

  return (
    <ActiveFiltersContext.Provider
      value={{
        filtered,
        activeFilters,
        filterPage,
        setFilterPage,
        handleSetActiveFilters,
        resetActiveFilters,
      }}
    >
      {children}
    </ActiveFiltersContext.Provider>
  );
};

export default memo(ActiveFilters);
