import gql from 'graphql-tag';

const getUsers = gql`
  query Users(
    $level: Int
    $locationId: Int
    $searchValue: String
    $activeFilters: Filters
    $perPage: Int
    $page: Int
  ) {
    users(
      level: $level
      locationId: $locationId
      searchValue: $searchValue
      activeFilters: $activeFilters
      page: $page
      perPage: $perPage
    ) {
      total
      users {
        id
        email
        firstName
        lastName
        preferredName
        portalId
        graduationYear
        major
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        country
        currentCompany
        currentJobTitle
        currentIndustry
        website
        linkedIn
        instagram
        facebook
        twitter
        profileImage
        bio
        cityId
        stateId
        countryId
        continentId
        graduationYearId
        majorId
        industryId
        visibleFields
        accepted
        firstTime
      }
    }
  }
`;

export default getUsers;
