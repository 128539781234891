import React, { memo } from 'react';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Mutation } from 'react-apollo';
import { viewports } from '../../utils/variables';
import TextInput from '../inputs/TextInput';
import PasswordInput from '../inputs/PasswordInput';
import SubmitButton from '../shared/SubmitButton';
import { setAdminCookies } from '../../utils/auth';
import { Heading, Subheading } from '../styled/Typography';
import ServerErrorMessage from '../inputs/ServerErrorMessage';
import { signInValidation } from '../../utils/validationSchema';
import signInAdmin from '../../data/mutations/signInAdmin';

const SignInForm = () => {
  return (
    <Mutation mutation={signInAdmin}>
      {(handleSignInAdmin, { error }) => (
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validate={signInValidation}
          onSubmit={async (values, actions) => {
            try {
              const { data } = await handleSignInAdmin({
                variables: {
                  email: values.email,
                  password: values.password,
                },
              });
              if (data.signInAdmin) {
                const { token } = data.signInAdmin;
                setAdminCookies({ token });
                navigate('/dashboard');
              }
            } catch (err) {
              actions.setSubmitting(false);
            }
          }}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ isSubmitting }) => (
            <Base>
              <Heading>Admin: Sign In</Heading>
              <Subheading>Sign in to your account below</Subheading>
              {!isSubmitting && (
                <ServerErrorMessage visible={error} error={error} />
              )}
              <Field name="email">
                {({ field, form }) => (
                  <TextInput
                    {...field}
                    form={form}
                    label="Email"
                    placeholder="Enter your email"
                    error={form.errors.email}
                    touched={form.touched.email}
                  />
                )}
              </Field>
              <Field name="password">
                {({ field, form }) => (
                  <PasswordInput
                    {...field}
                    form={form}
                    label="Password"
                    placeholder="Enter your password"
                    error={form.errors.email}
                    touched={form.touched.email}
                  />
                )}
              </Field>
              <SignInButton disabled={isSubmitting} submitting={isSubmitting}>
                Sign In
              </SignInButton>
            </Base>
          )}
        </Formik>
      )}
    </Mutation>
  );
};

export default memo(SignInForm);

const Base = styled(Form)`
  background-color: #fff;
  bottom: 0;
  box-shadow: 8px 8px 20px 0 rgba(34, 34, 34, 0.06);
  display: flex;
  left: 0;
  overflow-y: auto;
  padding: 2.75rem 1.875rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  flex-direction: column;
  margin-top: 6.5rem;

  @media ${viewports.medium} {
    flex-direction: column;
    display: block;
    overflow-y: visible;
    padding: 3.75rem;
    position: relative;
    width: 30rem;
    margin-top: 0;
  }
`;

const SignInButton = styled(SubmitButton)`
  margin-top: 2.5rem;
  width: 13.375rem;
`;
