import React, { PureComponent } from 'react';
import ErrorModal from './ErrorModal';

class ErrorHandler extends PureComponent {
  state = { errored: false };

  componentDidCatch = () => {
    this.setState({ errored: true });
  };

  render() {
    const { children } = this.props;
    const { errored } = this.state;
    return errored ? (
      <ErrorModal
        heading="An error has occured."
        bodyText="We're sorry for the trouble. Please try again later"
      />
    ) : (
      children
    );
  }
}

export default ErrorHandler;
