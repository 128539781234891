import provinces from 'provinces';
import values from 'lodash.values';

const picRegExp = new RegExp(/^.*(?=png$|jpg$|jpeg$|gif$|bmp$)/i);
const vidRegExp = new RegExp(/^.*(?=mp4$|mov$)/i);

export const isPicture = (fileName) => {
  const components = fileName.split('.');
  const ext = components[components.length - 1];
  return picRegExp.test(ext);
};

export const isVideo = (fileName) => {
  const components = fileName.split('.');
  const ext = components[components.length - 1];
  return vidRegExp.test(ext);
};

export const isPreviewable = (fileName) => isPicture(fileName) && !fileName.endsWith('pdf');

export const getProgramCode = (programName) => {
  const codes = programName.match(/\(.*\)/);
  const removeParen = codes[0].replace(/[()]/g, '');
  const res = removeParen.split(' ');

  return res;
};

export const getStateData = (input) => {
  try {
    const cleanInput = input.toUpperCase();
    const state = values(provinces).filter((_) => {
      const { name, short } = _;
      if (name && short) {
        return name.toUpperCase() === cleanInput || short.toUpperCase() === cleanInput;
      }
      return name === input || short === input;
    })[0];
    if (state && state.name) return state;
    const fallBackState = { name: input };
    return fallBackState;
  } catch (error) {
    console.error(error);
  }
};

export const removeSpaces = (s) => {
  return s.replace(new RegExp(/\s/g), '-');
};
