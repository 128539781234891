import React, { memo } from 'react';
import ProgressNav from '../shared/ProgressNav';
import { Heading, Subheading } from '../styled/Typography';

const Wrapper = ({ children, heading, subheading }) => (
  <>
    <ProgressNav numScreens={3} firstIndex={0} />
    <Heading>{heading}</Heading>
    <Subheading>{subheading}</Subheading>
    {children}
  </>
);

export default memo(Wrapper);
