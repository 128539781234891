import React, { memo, useState, createContext } from 'react';
import { Query } from 'react-apollo';
import getActiveLocation from '../../data/queries/getActiveLocation';
import PageLoader from '../../components/shared/PageLoader';

export const ActiveLocationContext = createContext();

const ActiveLocation = ({ children }) => {
  const [locationVariables, setLocationVariables] = useState({
    id: 0,
    level: 0,
    parentId: null,
    parentName: null,
  });

  const [flow, setFlow] = useState('forward');

  const fetchLocation = ({ level, id }) => {
    console.log('ActiveLocation.fetchLocation(level, id)', level, id);
    setFlow(level >= locationVariables.level ? 'forward' : 'backward');
    setLocationVariables({ level: +level, id: +id });
  };

  return (
    <Query
      query={getActiveLocation}
      variables={locationVariables}
      fetchPolicy="cache-and-network"
    >
      {({ data, loading: activeLocationLoading }) => {
        let activeLocation;
        if (data) activeLocation = data.activeLocation;
        return activeLocation ? (
          <ActiveLocationContext.Provider
            value={{
              fetchLocation,
              activeLocation,
              activeLocationLoading,
              flow,
              locationVariables,
            }}
          >
            {children}
          </ActiveLocationContext.Provider>
        ) : (
          <PageLoader />
        );
      }}
    </Query>
  );
};

export default memo(ActiveLocation);
