import gql from 'graphql-tag';

const signInAdmin = gql`
  mutation signInAdmin($email: String!, $password: String!) {
    signInAdmin(email: $email, password: $password) {
      token
    }
  }
`;

export default signInAdmin;
