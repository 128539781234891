import gql from 'graphql-tag';

const acceptUser = gql`
  mutation acceptUser($id: Int!) {
    acceptUser(id: $id) {
      id
    }
  }
`;

export default acceptUser;
