import React, { memo } from 'react';
import styled from 'styled-components';
import Avatar from '../shared/Avatar';
import Button from '../styled/Button';
import { Heading } from '../styled/Typography';
import SocialIcon from '../icons/SocialIcon';
import { viewports } from '../../utils/variables';

const Contact = ({ alum }) => {
  const { visibleFields } = alum;
  const locationName = `${alum.city}, ${alum.state ? alum.state : alum.country}`;

  return (
    alum && (
      <Base>
        <Avatar alum={alum} />
        <Name>{`${alum.preferredName || alum.firstName} ${alum.lastName}`}</Name>
        <Location>{locationName}</Location>
        {visibleFields.email && (
          <EmailButton as="a" href={`mailto:${alum.email}`}>
            Send Email
          </EmailButton>
        )}
        {((alum.facebook && visibleFields.facebook) ||
          (alum.twitter && visibleFields.twitter) ||
          (alum.instagram && visibleFields.instagram) ||
          (alum.linkedIn && visibleFields.linkedIn)) && (
          <Social>
            {alum.facebook && visibleFields.facebook && (
              <SocialIcon platform="facebook" url={alum.facebook} />
            )}
            {alum.twitter && visibleFields.twitter && (
              <SocialIcon platform="twitter" url={alum.twitter} />
            )}
            {alum.instagram && visibleFields.instagram && (
              <SocialIcon platform="instagram" url={alum.instagram} />
            )}
            {alum.linkedIn && visibleFields.linkedIn && (
              <SocialIcon platform="linkedIn" url={alum.linkedIn} />
            )}
          </Social>
        )}
      </Base>
    )
  );
};

export default memo(Contact);

const Base = styled.div`
  align-items: center;
  background-color: #f4f4f4;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 2.5rem 1.875rem 1.875rem;
  left: 0;
  top: 0;

  @media ${viewports.medium} {
    padding: 2.5rem 1.875rem 3.75rem;
    position: absolute;
    width: 30%;
  }
`;

const Name = styled(Heading)`
  margin-top: 1.875rem;
  text-align: center;
`;

const Location = styled.div`
  color: #999999;
  font-size: 1rem;
  line-height: 22px;
  margin-bottom: 1.875rem;
`;

const EmailButton = styled(Button)`
  margin-bottom: 1.875rem;
  width: 10rem;
`;

const Social = styled.div`
  display: flex;
`;
