import validator from 'validator';
import COUNTRIES_WITHOUT_POSTCODES from './countriesWithoutPostalCodes';

const isValidUrl = /^(?:(?:https?):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;

export const signInValidation = (values) => {
  const errors = {};
  if (!validator.isEmail(values.email)) errors.email = 'Invalid email address';
  if (!values.email) errors.email = 'Required';
  if (!values.password) errors.password = 'Required';
  return errors;
};

export const createValidation = (values) => {
  const errors = {};
  if (!validator.isInt(values.portalId)) errors.portalId = 'Portal ID must only contain numbers';
  if (!values.portalId) errors.portalId = 'Required';
  if (!validator.isEmail(values.email)) errors.email = 'Invalid email address';
  if (!values.email) errors.email = 'Required';
  if (!values.password) errors.password = 'Required';
  const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
  if (!passwordRegex.test(values.confirmPassword))
    errors.confirmPassword =
      'Password must be more than 8 characters, include 1 lowercase character, 1 capital character, and 1 numeric character';
  if (!validator.equals(values.confirmPassword, values.password))
    errors.confirmPassword = 'Passwords do not match';
  if (!values.confirmPassword) errors.confirmPassword = 'Required';
  return errors;
};

export const locationValidation = (values) => {
  const errors = {};
  const nonNumeric = ['city', 'state'];
  const nonWhiteSpace = ['streetAddress1', 'city'];
  // eslint-disable-next-line prettier/prettier
  const nonNumTest = new RegExp("^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF \\-\\']*$");

  const nonWhiteSpaceTest = new RegExp('^\\s*$');
  // try to accept language characters only

  try {
    nonWhiteSpace.forEach((field) => {
      // if (!nonNumTest.test(values[field])) {
      //   console.log(!nonNumTest.test(values[field]), field);
      //   errors[field] = 'Invalid Characters';
      //   console.log(errors);
      // }
      if (nonWhiteSpaceTest.test(values[field])) {
        // console.log(values[field]);
        errors[field] = 'Required';
      }
    });
    nonNumeric.forEach((field) => {
      if (!nonNumTest.test(values[field])) {
        errors[field] = 'Invalid characters, letters only';
      }
    });
  } catch (err) {
    console.log(err);
  }
  if (!values.streetAddress1) errors.streetAddress1 = 'Required';
  if (!values.country) errors.country = 'Required';
  if (!values.city) errors.city = 'Required';

  if (values.city && values.city.length < 3)
    errors.city = 'Please enter a non-abbreviated city name';

  // did we comment this out for certain countries?
  // if (!values.state) errors.state = 'Required';


  let countryName;
  let countryCode;
  if (values.country && values.country.value && values.country.label) {
    countryName = values.country.label;
    countryCode = values.country.value;
  }

  // make sure US addresses have state
  if (countryCode === 'US' && !values.state) errors.state = 'Required';

  const hasPostal = !COUNTRIES_WITHOUT_POSTCODES.flat().includes(countryName);
  let canVerify = true;
  // console.log(hasPostal, countryName);

  try {
    if (hasPostal && !validator.isPostalCode(values.postalCode, countryName))
      errors.postalCode = `Invalid postal code for country: ${values.country.label}`;
  } catch (err) {
    if (err.message.includes('Invalid locale')) {
      console.log('Unable to validate postal code for', countryName);
      canVerify = false;
    } else console.error(err);
  }

  if (hasPostal && !values.postalCode && canVerify) errors.postalCode = 'Required';

  return errors;
};
export const adminEditValidation = (values) => {
  let errors = {};
  try {
    if (!values.firstName) errors.firstName = 'Required';
    if (!values.lastName) errors.lastName = 'Required';

    if (!validator.isEmail(values.email)) errors.email = 'Invalid email address';
    if (!values.email) errors.email = 'Required';

    if (!values.birthdateMonth) errors.birthdateMonth = 'Required';
    if (!values.birthdateDay) errors.birthdateDay = 'Required';
    if (!values.birthdateYear) errors.birthdateYear = 'Required';
    if (!values.major) errors.major = 'Required';
    if (!values.portalId) errors.portalId = 'Required';
    if (!validator.isInt(values.portalId)) errors.portalId = 'Portal ID must only contain numbers';

    if (!values.graduationYear) errors.graduationYear = 'Required';
    errors = { ...locationValidation(values), ...errors };
    // console.log(errors);
    // if (!values.streetAddress1) errors.streetAddress1 = 'Required';
    // if (!values.city) errors.city = 'Required';
    // if (!values.state) errors.state = 'Required';

    // if (!values.country.label) errors.country = 'Required';
    // if (!values.postalCode) errors.postalCode = 'Required';
    // if (
    //   !validator.isPostalCode(
    //     values.postalCode,
    //     values.country.value || values.country
    //   )
    // )
    //   errors.postalCode = `Invalid postal code for country: ${
    //     values.country.value || values.label
    //   }`;
  } catch (err) {
    if (err.message.startsWith('Invalid locale')) {
      // errors because the inital value for country doesn't fill the two letter country code
      // not a huge deal, could be fixed if neccesary
    } else {
      console.error(err);
    }
  }
  return errors;
};

export const personalValidation = (values) => {
  const errors = {};
  if (!values.firstName) errors.firstName = 'Required';
  if (!values.lastName) errors.lastName = 'Required';
  if (!values.birthdateMonth) errors.birthdateMonth = 'Required';
  if (!values.birthdateDay) errors.birthdateDay = 'Required';
  if (!values.birthdateYear) errors.birthdateYear = 'Required';
  if (!values.graduationYear) errors.graduationYear = 'Required';
  if (!values.major) errors.major = 'Required';
  return errors;
};

export const careerValidation = (values) => {
  const errors = {};
  // if (!values.currentCompany) errors.currentCompany = 'Required';
  // if (!values.currentJobTitle) errors.currentJobTitle = 'Required';
  // if (!values.currentIndustry) errors.currentIndustry = 'Required';
  return errors;
};

export const moreFiltersValidation = (values) => {
  const errors = {};
  if (values.graduationYearFrom || values.graduationYearTo) {
    if (
      (values.graduationYearFrom && values.graduationYearFrom.value) >
      (values.graduationYearTo && values.graduationYearTo.value)
    ) {
      errors.graduationYear = 'First graduation year must be earlier than second graduation year';
    }
  }
  return errors;
};

export const profileUpdateValidation = (values) => {
  const profileErrors = {};

  if (!validator.isEmail(values.email)) profileErrors.email = 'Invalid email address';
  if (!values.email) profileErrors.email = 'Required';

  const addressErrors = locationValidation(values);

  const websiteErrors = websiteValidation(values);

  const errors = Object.assign(profileErrors, addressErrors, websiteErrors);

  // if (!values.currentCompany) errors.currentCompany = 'Required';
  // if (!values.currentJobTitle) errors.currentJobTitle = 'Required';
  // if (!values.currentIndustry) errors.currentIndustry = 'Required';
  return errors;
};

export const websiteValidation = (values) => {
  const websiteKeys = ['website', 'linkedIn', 'instagram', 'facebook', 'twitter'];
  const errors = {};
  try {
    websiteKeys.forEach((k) => {
      const value = values[k];
      if (value !== '' && !isValidUrl.test(value)) {
        const fixedValue = `https://${value}`;
        if (isValidUrl.test(fixedValue)) {
          values[k] = fixedValue;
        } else {
          errors[k] = 'Invalid URL';
        }
      }
    });

    return errors;
  } catch (err) {
    console.error(err);
  }
};

export const passwordUpdateValidation = (values) => {
  const errors = {};
  if (!values.currentPassword) errors.currentPassword = 'Required';
  if (!values.newPassword) errors.newPassword = 'Required';
  const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
  if (!passwordRegex.test(values.confirmNewPassword))
    errors.confirmNewPassword =
      'Password must be more than 8 characters, include 1 lowercase character, 1 capital character, and 1 numeric character';
  if (!validator.equals(values.confirmNewPassword, values.newPassword))
    errors.confirmNewPassword = 'Passwords do not match';
  if (!values.confirmNewPassword) errors.confirmNewPassword = 'Required';
  return errors;
};

export const resetPasswordValidation = (values) => {
  const errors = {};
  if (!values.newPassword) errors.newPassword = 'Required';
  const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
  if (!passwordRegex.test(values.confirmNewPassword))
    errors.confirmNewPassword =
      'Password must be more than 8 characters, include 1 lowercase character, 1 capital character, and 1 numeric character';
  if (!validator.equals(values.confirmNewPassword, values.newPassword))
    errors.confirmNewPassword = 'Passwords do not match';
  if (!values.confirmNewPassword) errors.confirmNewPassword = 'Required';
  return errors;
};

export const forgotPasswordValidation = (values) => {
  const errors = {};
  if (!validator.isEmail(values.email)) errors.email = 'Invalid email address';
  if (!values.email) errors.email = 'Required';
  return errors;
};
