import React, { memo } from 'react';
import styled from 'styled-components';
import RightNavArrow from '../icons/RightNavArrow';
import { fontFamilies, viewports } from '../../utils/variables';

const ExploreSidebarNav = ({ activeLocation, fetchLocation, type }) => {
  const { parentId, parentName, parentType } = activeLocation;

  if (type === 'world') {
    return <Title>Explore</Title>;
  }

  if (type === 'continent') {
    return (
      <BackLink
        onClick={() =>
          fetchLocation({
            level: 0,
            id: undefined,
          })
        }
      >
        <BackIcon />
        <Back>World</Back>
      </BackLink>
    );
  }

  if (type === 'country') {
    return (
      <BackLink
        onClick={() =>
          fetchLocation({
            level: 1,
            id: parentId,
          })
        }
      >
        <BackIcon />
        {parentName ? <Back>{parentName}</Back> : <Back>World</Back>}
      </BackLink>
    );
  }

  if (type === 'state') {
    return (
      <BackLink
        onClick={() =>
          fetchLocation({
            level: 2,
            id: parentId,
          })
        }
      >
        <BackIcon />
        {parentName && <Back>{parentName}</Back>}
      </BackLink>
    );
  }

  if (type === 'city') {
    return (
      <BackLink
        onClick={() => {
          if (parentType === 'country') {
            fetchLocation({
              level: 1,
              id: parentId,
            });
          }

          if (parentType === 'state') {
            fetchLocation({
              level: 2,
              id: parentId,
            });
          }
        }}
      >
        <BackIcon />
        {parentName && <Back>{parentName}</Back>}
      </BackLink>
    );
  }

  return null;
};

export default memo(ExploreSidebarNav);

const Title = styled.h4`
  color: #ffffff;
  font-family: ${fontFamilies.bold};
  font-size: 1.25rem;
`;

const BackLink = styled.a`
  cursor: pointer;
  width: 80%;
  margin-bottom: 1rem;
`;

const Back = styled.span`
  color: #ffffff;
  font-size: 1.25rem;
  padding-left: 1rem;

  @media ${viewports.medium} {
    ${BackLink}:hover & {
      color: #ffd1b7;
    }
  }
`;

const BackIcon = styled(RightNavArrow)`
  fill: #fff;
  transform: rotate(180deg);

  @media ${viewports.medium} {
    ${BackLink}:hover & {
      fill: #ffd1b7;
    }
  }
`;
