import React, { memo, useEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import CloseButton from './CloseButton';
import { viewports } from '../../utils/variables';

const Sidebar = ({ visible, exitSidebar, children }) => {
  const sidebarRef = useRef(null);

  const spring = useSpring({
    transform: visible ? 'translateX(0)' : 'translateX(100%)',
  });

  useEffect(() => {
    if (visible) {
      // disableBodyScroll(sidebarRef.current);
    } else {
      enableBodyScroll(sidebarRef.current);
    }
    return () => clearAllBodyScrollLocks();
  });

  return (
    <Base ref={sidebarRef} style={spring}>
      {children}
      <CloseLink onClick={exitSidebar}>
        <CloseButton />
      </CloseLink>
    </Base>
  );
};

export default memo(Sidebar);

const Base = styled(animated.div)`
  background-color: #fff;
  bottom: 0;
  height: 100vh;
  height: -webkit-fill-available;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;

  @media ${viewports.medium} {
    width: 30rem;
  }
`;

const CloseLink = styled.a`
  position: absolute;
  right: 1.9375rem;
  top: 1.9375rem;
`;
