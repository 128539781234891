import React, { memo } from 'react';
import styled from 'styled-components';
import { viewports } from '../../utils/variables';

const CloseButton = ({ className, onClick }) => (
  <Base
    height="13"
    viewBox="0 0 13 13"
    width="13"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path
      d="m7.5 5.13636364 4.1363636-4.13636364 2.3636364 2.36363636-4.13636364 4.13636364 4.13636364 4.1363636-2.3636364 2.3636364-4.1363636-4.13636364-4.13636364 4.13636364-2.36363636-2.3636364 4.13636364-4.1363636-4.13636364-4.13636364 2.36363636-2.36363636z"
      fillRule="evenodd"
      transform="translate(-1 -1)"
    />
  </Base>
);

export default memo(CloseButton);

const Base = styled.svg`
  cursor: pointer;
  fill: #ccc;

  @media ${viewports.medium} {
    &:hover {
      fill: #999;
    }
  }
`;
