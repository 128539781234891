import reset from 'styled-reset';
import { createGlobalStyle } from 'styled-components';
import { fontFamilies, colors } from '../../utils/variables';

const GlobalStyle = createGlobalStyle`
  ${reset}

  * {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-kerning: auto;
  }

  html {
    box-sizing: border-box;
    font-family: ${fontFamilies.regular};
    font-size: 16px;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    color: ${colors.contentBlack};
  }
`;

export default GlobalStyle;
