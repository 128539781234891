import React, { memo } from 'react';
import styled from 'styled-components';

const DenyIcon = ({ onClick }) => {
  return (
    <Base
      height="30"
      viewBox="0 0 30 30"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="15" cy="15" fillRule="nonzero" r="14" stroke="#fc4600" strokeWidth="2" />
        <path
          d="m19.8502936 18.7730812-1.078454 1.078454c-.1979531.1979531-.5193608.1979531-.7180233 0l-3.0544371-3.0551466-3.0544371 3.0551466c-.1986626.1979531-.5200703.1979531-.7180233 0l-1.078454-1.078454c-.19795307-.1986626-.19795307-.5200702 0-.7187328l3.0544371-3.0544371-3.0544371-3.0544371c-.19795307-.197953-.19795307-.5200702 0-.7187328l1.078454-1.0777445c.197953-.19866254.5193607-.19866254.7180233 0l3.0544371 3.0537276 3.0544371-3.0537276c.1986625-.19866254.5200702-.19866254.7180233 0l1.078454 1.0777445c.1986625.1986626.1986625.5207798 0 .7187328l-3.0544371 3.0544371 3.0544371 3.0544371c.1986625.1986626.1986625.5200702 0 .7187328"
          fill="#fc4600"
        />
      </g>
    </Base>
  );
};

export default memo(DenyIcon);

const Base = styled.svg`
  cursor: pointer;
  margin-left: 0.625rem;
`;
