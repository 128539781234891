import React, { memo } from 'react';
import { components } from 'react-select';
import ClearIcon from '../icons/ClearIcon';

const MultiValueRemove = props =>
  components.MultiValueRemove && (
    <components.MultiValueRemove {...props}>
      <ClearIcon />
    </components.MultiValueRemove>
  );

export default memo(MultiValueRemove);
