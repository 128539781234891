import React, { memo, useState } from 'react';
import { ApolloConsumer } from 'react-apollo';
import ErrorModal from '../shared/ErrorModal';
import getGeolocation from '../../data/queries/getGeolocation';

const LocationFinder = ({ children, toggleLoading, fetchLocation }) => {
  const [errored, setErrored] = useState(false);

  const geolocate = async (client) => {
    if (navigator) {
      await toggleLoading(true);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { longitude, latitude } = position.coords;
          try {
            const { data, loading } = await client.query({
              query: getGeolocation,
              variables: { longitude, latitude },
              fetchPolicy: 'network-only',
            });
            const { level, id } = data.geolocation;
            fetchLocation({ level: +level, id: +id });
            await toggleLoading(false);
          } catch (error) {
            await toggleLoading(false);
            setErrored(true);
          }
        },
        (error) => {
          toggleLoading(false);
        }
      );
    }
  };

  return (
    <>
      <ApolloConsumer query={getGeolocation}>
        {(client) => children({ geolocate: () => geolocate(client) })}
      </ApolloConsumer>
      {errored && (
        <ErrorModal
          heading="No alumni here."
          bodyText="There are no current alumni in this region. Try using filtering
            to narrow down your search and find nearby alumni."
          onClose={setErrored(false)}
        />
      )}
    </>
  );
};

export default memo(LocationFinder);
