import gql from 'graphql-tag';

const uploadMedia = gql`
  mutation UploadMedia($id: Int!, $media: [Upload!]) {
    uploadMedia(id: $id, media: $media) {
      url
      id
      order
    }
  }
`;

export default uploadMedia;
