import React, { memo } from 'react';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import PendingUser from './PendingUser';
import PageLoader from '../shared/PageLoader';
import getPendingUsers from '../../data/queries/getPendingUsers';

const PendingUsers = () => {
  return (
    <Base>
      <Query query={getPendingUsers}>
        {({ data, loading, refetch }) =>
          loading ? (
            <PageLoader />
          ) : (
            data.pendingUsers.map((user) => (
              <PendingUser key={user.id} user={user} refetch={refetch} />
            ))
          )
        }
      </Query>
    </Base>
  );
};

export default memo(PendingUsers);

const Base = styled.div`
  padding: 5rem 0;
`;
