import React, { memo } from 'react';
import { Heading, Subheading } from '../styled/Typography';

const ResetSuccess = ({ email }) => (
  <>
    <Heading>Reset link sent!</Heading>
    <Subheading>
      Check your email. We sent a password reset link to&nbsp;
      <strong>{email}</strong>
    </Subheading>
  </>
);

export default memo(ResetSuccess);
