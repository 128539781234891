import React, { memo } from 'react';
import styled from 'styled-components';
import { colors, fontFamilies, viewports } from '../../utils/variables';
import Logo from './Logo';
import Button from '../styled/Button';

const Header = ({ toggleSidebar }) => (
  <Base>
    <Logo />
    <LoginContainer>
      <SignInLink onClick={() => toggleSidebar('sign-in')}>Sign In</SignInLink>
      <RegisterButton onClick={() => toggleSidebar('register')}>Register</RegisterButton>
    </LoginContainer>
  </Base>
);

export default memo(Header);

const Base = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1.25rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
`;

const LoginContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const SignInLink = styled.a`
  color: ${colors.linkBlack};
  cursor: pointer;
  font-family: ${fontFamilies.medium};
  font-size: 0.875rem;

  @media ${viewports.medium} {
    margin-right: 1.5rem;

    &:hover {
      color: ${colors.hoverBlack};
    }
  }
`;

const RegisterButton = styled(Button)`
  display: none;

  @media ${viewports.medium} {
    display: block;
  }
`;
