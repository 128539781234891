import gql from 'graphql-tag';

const getLocations = gql`
  query Locations($type: String!) {
    locations(type: $type) {
      id
      name
      parentName
      parentShortName
    }
  }
`;

export default getLocations;
