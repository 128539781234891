import React, { memo } from 'react';
import styled from 'styled-components';
import { MapBackground } from '../styled/Background';
import WorldIndicators from './WorldIndicators';
import SectionHeading from './SectionHeading';
import { colors, viewports } from '../../utils/variables';

const World = ({ numAlumni, loading, locations, sidebar }) => {
  const exploreVisible = sidebar === 'explore';
  const searchVisible = sidebar === 'search';
  console.log('World.locations', locations);
  return (
    <>
      <MapBackground />
      <>
        <Content>
          <SectionHeading
            isWorld
            numAlumni={numAlumni}
            loading={loading}
            sectionName={
              (searchVisible && 'Search') ||
              (exploreVisible && 'Explore') ||
              'Explore or Search'
            }
          />
        </Content>
        <WorldIndicators locations={locations} />
      </>
    </>
  );
};

export default memo(World);

const Content = styled.div`
  bottom: 0;
  color: ${colors.contentBlack};
  pointer-events: none;
  position: relative;
  width: 100%;
  z-index: 8;

  @media ${viewports.medium} {
    margin-left: 5rem;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: 50%;
  }
`;
