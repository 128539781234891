import React, { memo, useEffect, useRef, useState } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import styled from 'styled-components';
import Overlay from '../styled/Overlay';
import { fontFamilies, viewports } from '../../utils/variables';
import { BodyText } from '../styled/Typography';
import Button from '../styled/Button';
import CloseButton from '../shared/CloseButton';
import LoadingIcon from '../shared/LoadingIcon';

const ActionPopup = ({ heading, description, handleConfirmClick, handleCancelClick, user }) => {
  const overlayRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    disableBodyScroll(overlayRef.current);
    return () => clearAllBodyScrollLocks();
  });

  return (
    <Overlay ref={overlayRef}>
      <Base>
        <Heading>
          {heading} {user.preferredName || user.firstName}
        </Heading>
        <BodyText>{description}</BodyText>
        <Buttons>
          <ActionButton secondary onClick={handleCancelClick}>
            Cancel
          </ActionButton>
          <ActionButton
            submitting={loading}
            onClick={() => {
              setLoading(true);
              handleConfirmClick();
            }}
          >
            {loading ? <LoadingIcon /> : heading}
          </ActionButton>
        </Buttons>
        <Close onClick={handleCancelClick} />
      </Base>
    </Overlay>
  );
};

export default memo(ActionPopup);

const Base = styled.div`
  background-color: #fff;
  bottom: 0;
  box-shadow: 8px 8px 20px 0 rgba(34, 34, 34, 0.06);
  left: 0;
  padding: 2.5rem 3.75rem 2.1875rem;
  right: 0;
  text-align: center;
  top: 0;

  display: block;
  position: relative;
  width: 31.25rem;
`;

const Heading = styled.h2`
  font-size: 1.25rem;
  font-family: ${fontFamilies.bold};
  letter-spacing: -0.18px;
  margin-bottom: 0.875rem;
`;

const Buttons = styled.div`
  display: flex;
`;

const ActionButton = styled(Button)`
  margin: 0;
  width: 100%;

  &:first-of-type {
    margin-right: 1.25rem;
  }
`;

const Close = styled(CloseButton)`
  position: absolute;
  right: 1.9375rem;
  top: 1.9375rem;
`;
