import React, { memo } from 'react';
import styled from 'styled-components';
import { Form } from 'formik';
import { Mutation } from 'react-apollo';
import Sidebar from '../shared/Sidebar';
import Create from './Create';
import Location from './Location';
import Personal from './Personal';
import Success from './Success';
import MultiPageForm, { MultiPageFormContext } from '../shared/MultiPageForm';
import {
  createValidation,
  personalValidation,
  locationValidation,
} from '../../utils/validationSchema';
import { viewports } from '../../utils/variables';
import register from '../../data/mutations/register';

const Register = ({ toggleSidebar, visible }) => {
  return (
    <Sidebar visible={visible} exitSidebar={() => toggleSidebar(undefined)}>
      <Mutation mutation={register}>
        {(handleRegister, { error }) => (
          <MultiPageForm
            screens={[
              <Create
                toggleSidebar={toggleSidebar}
                validate={createValidation}
              />,
              <Personal validate={personalValidation} />,
              <Location validate={locationValidation} error={error} />,
              <Success />,
            ]}
            initialValues={{
              email: '',
              password: '',
              confirmPassword: '',
              firstName: '',
              lastName: '',
              portalId: '',
              birthdateMonth: null,
              birthdateDay: null,
              birthdateYear: null,
              graduationYear: null,
              major: null,
              secondaryMajor: null,
              streetAddress1: '',
              streetAddress2: '',
              country: '',
              postalCode: '',
              city: '',
              state: '',
            }}
            submit={async (values) => {
              const cleanValues = { ...values };
              cleanValues.country = values.country.label;
              const { data } = await handleRegister({ variables: cleanValues });
              return data;
            }}
          >
            <MultiPageFormContext.Consumer>
              {({ activeScreen }) => <Base>{activeScreen}</Base>}
            </MultiPageFormContext.Consumer>
          </MultiPageForm>
        )}
      </Mutation>
    </Sidebar>
  );
};

export default memo(Register);

const Base = styled(Form)`
  padding: 3.4375rem 0.45rem;
  @media ${viewports.min} {
    padding: 3.4375rem 2rem;
  }
  @media ${viewports.medium} {
    padding: 3.4375rem 3.75rem;
  }
`;
