import React, { memo, useContext } from 'react';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
// import { Scrollbar } from 'react-scrollbars-custom';
import { Formik, Form, Field } from 'formik';
import { fontFamilies, colors } from '../../utils/variables';
import FilterIcon from '../icons/FilterIcon';
import SelectInput from '../inputs/SelectInput';
import { BodyText, Label } from '../styled/Typography';
import TextInput from '../inputs/TextInput';
import FilterConfirmation from './FilterConfirmation';
import { graduationYears, industries, majors } from '../../utils/selectOptions';
import { moreFiltersValidation } from '../../utils/validationSchema';
import ErrorMessage from '../inputs/ErrorMessage';
import { ActiveFiltersContext } from './ActiveFilters';

const MoreFilters = () => {
  const { activeFilters, handleSetActiveFilters, setFilterPage, resetActiveFilters } = useContext(
    ActiveFiltersContext
  );

  return (
    <Base>
      <Scrollbars autoHide universal>
        {/* <Scrollbar removeTracksWhenNotUsed> */}
        <Formik
          initialValues={{
            majors: activeFilters.majors.length > 0 ? activeFilters.majors : null,
            graduationYearFrom: activeFilters.graduationYearFrom,
            graduationYearTo: activeFilters.graduationYearTo,
            company: activeFilters.company,
            jobTitle: activeFilters.jobTitle,
            industries: activeFilters.industries.length > 0 ? activeFilters.industries : null,
          }}
          onSubmit={(values) => {
            handleSetActiveFilters(values);
            setFilterPage(undefined);
          }}
          validate={moreFiltersValidation}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ setFieldValue, handleSubmit, touched, errors, resetForm }) => (
            <>
              <FormWrapper>
                <TitleContainer>
                  <Title>More Filters</Title>
                  <FilterIcon />
                </TitleContainer>
                <Copy>Filter by majors, class years, companies, job titles, and companies.</Copy>
                <Field name="majors">
                  {({ field, form }) => (
                    <SelectInput
                      multi
                      {...field}
                      form={form}
                      label="Major"
                      placeholder="Select majors"
                      isClearable
                      options={majors}
                    />
                  )}
                </Field>
                <Label>Graduation Year</Label>
                <SelectContainer>
                  <Field name="graduationYearFrom">
                    {({ field, form }) => (
                      <SelectInput
                        {...field}
                        form={form}
                        handleChange={(option) => {
                          form.setFieldValue(field.name, option);
                          if (!form.values.graduationYearTo) {
                            form.setFieldValue('graduationYearTo', option);
                          }
                        }}
                        placeholder="Select"
                        width="6.875rem"
                        options={graduationYears}
                        error={errors.graduationYear}
                      />
                    )}
                  </Field>
                  <SelectText>to</SelectText>
                  <Field name="graduationYearTo">
                    {({ field, form }) => (
                      <SelectInput
                        {...field}
                        form={form}
                        handleChange={(option) => {
                          form.setFieldValue(field.name, option);
                          if (!form.values.graduationYearTo) {
                            form.setFieldValue('graduationYearFrom', option);
                          }
                        }}
                        placeholder="Select"
                        width="6.875rem"
                        options={graduationYears}
                        error={errors.graduationYear}
                      />
                    )}
                  </Field>
                </SelectContainer>
                <GraduationYearError
                  visible={
                    (touched.graduationYearFrom || touched.graduationYearTo) &&
                    errors.graduationYear
                  }
                  error={errors.graduationYear}
                  height={36}
                />
                <Field name="company">
                  {({ field }) => (
                    <TextInput {...field} label="Company" placeholder="Enter company name" />
                  )}
                </Field>
                <Field name="jobTitle">
                  {({ field }) => (
                    <TextInput {...field} label="Job Title" placeholder="Enter job title" />
                  )}
                </Field>
                <Field name="industries">
                  {({ field, form }) => (
                    <SelectInput
                      {...field}
                      multi
                      form={form}
                      label="Industry"
                      placeholder="Enter industry"
                      options={industries}
                      menuPlacement="top"
                    />
                  )}
                </Field>
              </FormWrapper>
              <FilterConfirmation
                clear={() => {
                  resetForm();
                  setFieldValue('majors', null);
                  setFieldValue('graduationYearFrom', null);
                  setFieldValue('graduationYearTo', null);
                  setFieldValue('company', '');
                  setFieldValue('jobTitle', '');
                  setFieldValue('industries', null);
                  resetActiveFilters();
                }}
                submit={handleSubmit}
              />
            </>
          )}
        </Formik>
      </Scrollbars>
    </Base>
  );
};

export default memo(MoreFilters);

const Base = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  /* left: 0; */
  background: #fff;
  position: fixed;
  right: 0;
  top: 0;
  width: inherit;
  //doesn't change anything but should be necessary, add as part of AC-161
  -webkit-overflow-scrolling: touch;
`;

const FormWrapper = styled(Form)`
  min-height: 50%;
  padding: 1.6875rem 1.875rem;
`;

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
`;

const Title = styled.div`
  color: ${colors.contentBlack};
  font-family: ${fontFamilies.bold};
  font-size: 1.25rem;
  padding-right: 0.375rem;
`;

const SelectContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const SelectText = styled.span`
  color: #222222;
  font-size: 0.9375rem;
  margin-top: -1.875rem;
`;

const Copy = styled(BodyText)`
  font-size: 0.875rem;
  line-height: 18px;
  margin-bottom: 1.875rem;
`;

const GraduationYearError = styled(ErrorMessage)`
  top: -1.65rem;
`;
