import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import CloseButton from '../shared/CloseButton';
import { fontFamilies, colors, viewports } from '../../utils/variables';
import TextInput from '../inputs/TextInput';
import Button from '../styled/Button';
import AlumniList from './AlumniList';
import LocationFilter from './LocationFilter';
import MoreFilters from './MoreFilters';
import { AlumniFetcherContext } from './AlumniFetcher';
import ClearIcon from '../icons/ClearIcon';
import { ActiveFiltersContext } from './ActiveFilters';

const SearchSidebar = ({ toggleSidebar, visible }) => {
  const {
    searchValue,
    handleSearchInputChange,
    activeLocation,
    activeScreen,
    setActiveScreen,
    resetSearch,
    resetLocation,
  } = useContext(AlumniFetcherContext);
  const { filtered, setFilterPage, filterPage, resetActiveFilters } = useContext(
    ActiveFiltersContext
  );

  const sidebarRef = useRef(null);

  const currWidth = sidebarRef.current ? sidebarRef.current.clientWidth : 750;
  const springOpen = {
    from: { marginRight: `${-currWidth}px` },
    to: { marginRight: '0px' },
  };

  const springClosed = {
    from: { marginRight: '0px' },
    to: { marginRight: `${-currWidth}px` },
  };
  const [testSpring, setTestSpring] = useSpring(() => {
    return springOpen;
  });

  const [spring, setSpring] = useState(undefined);
  useEffect(() => {
    if (visible) {
      setSpring({ transform: 'translateX(0)' });
    } else {
      setSpring({ transform: 'translateX(100%)' });
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      // disableBodyScroll(sidebarRef.current);
      setTestSpring(springOpen);
    } else {
      enableBodyScroll(sidebarRef.current);
      setTestSpring(springClosed);
    }
    // return () => clearAllBodyScrollLocks();
  }, [visible]);

  return (
    <Base style={testSpring} ref={sidebarRef}>
      {!filterPage && (
        <>
          <ContentWrapper>
            <Title>Search</Title>
            <SearchInputContainer>
              <SearchInput
                name="search"
                placeholder="Search people..."
                value={searchValue}
                onChange={handleSearchInputChange}
              />
              <>
                <SearchClear onClick={resetSearch} />
              </>
            </SearchInputContainer>
            <ToggleButtons>
              <LocationButton
                onClick={() => setFilterPage('location')}
                active={activeLocation.name !== 'World'}
              >
                {activeLocation.name !== 'World' ? activeLocation.name : 'Location'}
              </LocationButton>
              <FilterButton onClick={() => setFilterPage('moreFilters')} active={filtered}>
                More Filters
              </FilterButton>
            </ToggleButtons>
          </ContentWrapper>
          <AlumniList
            resetFilters={() => {
              resetLocation();
              resetActiveFilters();
            }}
            filtersActive={filtered || activeLocation.name}
            setSidebar={toggleSidebar}
          />
        </>
      )}
      {filterPage === 'location' && <LocationFilter />}
      {filterPage === 'moreFilters' && <MoreFilters />}
      <CloseLink
        onClick={() => {
          filterPage ? toggleSidebar('search') : toggleSidebar(undefined);
          setFilterPage(undefined);
          if (activeScreen !== 'alum') setActiveScreen(undefined);
        }}
      />
    </Base>
  );
};

export default memo(SearchSidebar);

// AC-161 animated.div causing body-scroll-lock (disableBodyScroll) to fail
// const Base = styled.div`
const Base = styled(animated.div)`
  background-color: #222;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 1.6875rem 0;
  right: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;

  @media ${viewports.medium} {
    position: absolute;
    width: 20rem;
  }
`;

const ContentWrapper = styled.div`
  padding: 0 1.875rem;
`;

const Title = styled.h4`
  color: #ccc;
  font-family: ${fontFamilies.bold};
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
`;

const SearchInputContainer = styled.div`
  position: relative;
`;

const SearchInput = styled(TextInput)`
  margin-bottom: 0rem;

  & input {
    background: ${colors.linkBlack};
    border: 1px ${colors.linkBlack} solid;
    color: #ddd;

    &::placeholder {
      color: #777777;
      opacity: 1;
    }

    &::-moz-placeholder {
      color: #777777;
      opacity: 1;
    }

    &:focus {
      border: 1px #777777 solid;
    }
  }
`;

const SearchClear = styled(ClearIcon)`
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
`;

const ToggleButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ToggleButton = styled(Button)`
  background: ${(props) => (props.active ? colors.blue : 'transparent')};
  border: 2px solid ${(props) => (props.active ? colors.blue : '#777')};
  color: ${(props) => (props.active ? '#fff' : '#777')};
  font-family: ${fontFamilies.regular};
  height: 1.875rem;
  flex: 1;
  margin: 0;
  width: auto;

  @media ${viewports.medium} {
    width: 7.5rem;

    &:hover {
      background: ${(props) => (props.active ? colors.hoverBlue : '#777')};
      border: 2px solid ${(props) => (props.active ? colors.hoverBlue : '#777')};
    }
  }
`;

const LocationButton = styled(ToggleButton)`
  margin-right: 0.3125rem;
`;

const FilterButton = styled(ToggleButton)`
  margin-left: 0.3125rem;
`;

const CloseLink = styled(CloseButton)`
  position: absolute;
  right: 1.9375rem;
  top: 1.9375rem;
  z-index: 2;
`;
