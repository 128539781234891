import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import { Mutation } from 'react-apollo';
import Button from '../../styled/Button';
import uploadMedia from '../../../data/mutations/uploadMedia';
import getUserMedia from '../../../data/queries/getUserMedia';
import getUser from '../../../data/queries/getUser';
import { userFromLocalCookie } from '../../../utils/auth';
import deleteMediaMutation from '../../../data/mutations/deleteMedia';
import { fileNameFromPath } from '../../../utils/filePathsToDraggableData';
import drag from '../../../images/drag.svg';
import filePreview from '../../../images/file-preview.svg';
import download from '../../../images/download.svg';
import { isPreviewable, removeSpaces } from '../../../utils/helperFunctions';

const MAX_FILE_SIZE = 52428800;
const PLACEHOLDER = 'No file selected';

const MediaUploadItem = ({
  id,
  downstreamId,
  index,
  name,
  url,
  form,
  toDelete,
  setToDelete,
  toUpload,
  setToUpload,
  itemHelpers,
  toggleModal,
  setIndex,
}) => {
  const inputEl = useRef(null);
  const [mediaName, setMediaName] = useState(name || PLACEHOLDER); // url ? `${process.env.UPLOADS_URL}${url}` : undefined)

  let fileReader;

  const handleChangeFile = (file, handleUploadMedia) => {
    // form.setFieldValue('uploadItem', file);
    console.log(toUpload);
    if (!file) return;
    const fileNameClean = new RegExp(/[\w\-\_]+/, 'ig');
    if (file.name.match(fileNameClean).length !== 2) {
      alert('File names may only contain letters, numbers, dashes, and underscores.');
      return;
    }
    const cleanFileName = removeSpaces(file.name);
    if (file.size < MAX_FILE_SIZE) {
      fileReader = new FileReader();
      fileReader.onloadend = () => setToUpload([...toUpload, file]);
      fileReader.readAsDataURL(file);

      const listItem = {
        url: cleanFileName,
        id: file.lastModified + cleanFileName,
        name: cleanFileName,
      };
      if (!itemHelpers.containsName(cleanFileName)) {
        itemHelpers.addItem(listItem, cleanFileName);
      } else {
        console.log('Duplicate files names not allowed');
        alert('Please choose a file with a unique name');
      }
    }
  };

  const handleClick = () => {
    inputEl.current.click();
  };

  const handleClear = (deleteMedia) => {
    // form.setFieldValue(`column[${index}]`, '');
    // deleteMedia({
    //   variables: { userId: +userFromLocalCookie(), downstreamId, fileId: id },
    // });
    const newToDelete = [...toDelete, id];
    setToDelete(newToDelete);

    itemHelpers.removeItem(url);
  };

  if (mediaName !== PLACEHOLDER) {
    return (
      <Draggable draggableId={id} index={index}>
        {(provided) => (
          <Base {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
            <Mutation
              mutation={deleteMediaMutation}
              refetchQueries={[
                {
                  query: getUserMedia,
                  variables: { id: +userFromLocalCookie() },
                },
              ]}
              onCompleted={({ deleteMedia: { id } }) => {
                // console.log(id);
                // itemHelpers.removeItem(id);
              }}
            >
              {(deleteMedia) => {
                const canPreview = isPreviewable(mediaName);
                const isUploaded = name !== url;

                return (
                  <>
                    <DragHandle src={drag} />
                    <FileNameLabel>
                      {mediaName}
                      <PreviewContainer
                        onClick={() => {
                          if (canPreview || !isUploaded) {
                            setIndex(
                              itemHelpers.getPreviewList().findIndex((item) => item.url === url)
                            );
                            toggleModal();
                          } else {
                            window.open(itemHelpers.getItemUrl(url));
                          }
                        }}
                        type="button"
                      >
                        <FilePreview
                          src={canPreview ? filePreview : download}
                          height={canPreview ? 1.5 : 1.4}
                        />
                      </PreviewContainer>
                    </FileNameLabel>
                    <DeleteMediaButton as="a" secondary onClick={() => handleClear(deleteMedia)}>
                      Remove
                    </DeleteMediaButton>
                  </>
                );
              }}
            </Mutation>
          </Base>
        )}
      </Draggable>
    );
  }
  // if upload item
  return (
    <Base>
      <Mutation
        mutation={uploadMedia}
        refetchQueries={[
          { query: getUserMedia, variables: { id: +userFromLocalCookie() } },
          { query: getUser, variables: { id: +userFromLocalCookie() } },
        ]}
        onCompleted={(data) => {
          const file = data.uploadMedia;
          console.log('uploadMedia', data.uploadMedia);
          itemHelpers.addItem(file, fileNameFromPath(file.url));
        }}
      >
        {(handleUploadMedia) => {
          return (
            <>
              <Input
                type="file"
                name="image"
                accept="image/png image/jpeg image/bmp image/gif application/pdf video/mp4 video/mov"
                onChange={(e) => handleChangeFile(e.target.files[0], handleUploadMedia)}
                ref={inputEl}
              />

              <FileNameLabel>
                {index} {PLACEHOLDER}
              </FileNameLabel>
              <UploadMediaButton as="a" htmlFor={name} onClick={handleClick}>
                Choose File
              </UploadMediaButton>
            </>
          );
        }}
      </Mutation>
    </Base>
  );
};

export default MediaUploadItem;

const Base = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 0.675rem;
  color: #999;
`;

const UploadMediaButton = styled(Button)`
  margin: auto 0 auto 0.625rem;
`;

const DeleteMediaButton = styled(Button)`
  margin: auto 0 auto 0.625rem;
`;

const PreviewContainer = styled.button`
  all: unset;
`;

const FileNameLabel = styled.div`
  height: 2.5rem;
  border-radius: 2rem;
  padding: 0.5rem 0.75rem;
  border: 1px solid #999;
  flex: 1;
  margin: auto 0 auto 0;
  font-size: 0.875rem;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DragHandle = styled.img`
  padding: 0.3rem;
  height: 2.5rem;
`;

const FilePreview = styled.img`
  height: ${(props) => props.height}rem;
`;

const Input = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
