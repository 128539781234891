const COUNTRIES_WITHOUT_POSTCODES = [
  ['Angola', 'AO'],
  ['Antigua and Barbuda', 'AG'],
  ['Aruba', 'AW'],
  ['Bahamas', 'BS'],
  ['Belize', 'BZ'],
  ['Benin', 'BJ'],
  ['Botswana', 'BW'],
  ['Burkina Faso', 'BF'],
  ['Burundi', 'BI'],
  ['Cameroon', 'CM'],
  ['Central African Republic', 'CF'],
  ['Comoros', 'KM'],
  ['Congo', 'CG'],
  ['Congo, the Democratic Republic of the', 'CD'],
  ['Cook Islands', 'CK'],
  ["Cote d'Ivoire", 'CI'],
  ['Djibouti', 'DJ'],
  ['Dominica', 'DM'],
  ['Equatorial Guinea', 'GQ'],
  ['Eritrea', 'ER'],
  ['Fiji', 'FJ'],
  ['French Southern Territories', 'TF'],
  ['Gambia', 'GM'],
  ['Ghana', 'GH'],
  ['Grenada', 'GD'],
  ['Guinea', 'GN'],
  ['Guyana', 'GY'],
  ['Hong Kong', 'HK'],
  ['Ireland', 'IE'],
  ['Jamaica', 'JM'],
  ['Kenya', 'KE'],
  ['Kiribati', 'KI'],
  ['Macao', 'MO'],
  ['Malawi', 'MW'],
  ['Mali', 'ML'],
  ['Mauritania', 'MR'],
  ['Mauritius', 'MU'],
  ['Montserrat', 'MS'],
  ['Nauru', 'NR'],
  ['Netherlands Antilles', 'AN'],
  ['Niue', 'NU'],
  ['North Korea', 'KP'],
  ['Panama', 'PA'],
  ['Qatar', 'QA'],
  ['Rwanda', 'RW'],
  ['Saint Kitts and Nevis', 'KN'],
  ['Saint Lucia', 'LC'],
  ['Sao Tome and Principe', 'ST'],
  ['Seychelles', 'SC'],
  ['Sierra Leone', 'SL'],
  ['Solomon Islands', 'SB'],
  ['Somalia', 'SO'],
  ['South Africa', 'ZA'],
  ['Suriname', 'SR'],
  ['Syria', 'SY'],
  ['Tanzania, United Republic of', 'TZ'],
  ['Timor-Leste', 'TL'],
  ['Tokelau', 'TK'],
  ['Tonga', 'TO'],
  ['Trinidad and Tobago', 'TT'],
  ['Tuvalu', 'TV'],
  ['Uganda', 'UG'],
  ['United Arab Emirates', 'AE'],
  ['Vanuatu', 'VU'],
  ['Yemen', 'YE'],
  ['Zimbabwe', 'ZW'],
];
export default COUNTRIES_WITHOUT_POSTCODES;
