import React, { memo } from 'react';
import styled from 'styled-components';

const ConfirmIcon = ({ onClick }) => {
  return (
    <Base
      height="30"
      viewBox="0 0 30 30"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="15" cy="15" fill="#00c1a1" fillRule="nonzero" r="15" />
        <path
          d="m13.376111 19.8516342c-.1985379.1978211-.5203556.1978211-.7188934 0l-.1598337-.1598337-.0007168.0007167-.1684346-.1705849-.7489965-.7489965c-.002867-.0021502-.0014335-.0064507-.0043005-.0093177l-3.42603212-3.4511181c-.19853784-.1985378-.19853784-.5210722 0-.7188933l1.07798165-1.0779817c.19853785-.1985378.52035551-.1985378.71889335 0l3.07482802 3.097047 6.4643062-6.4643062c.1992545-.19782108.5203555-.19782108.7188933 0l1.0779817 1.0786984c.1985378.1978211.1985378.5196388 0 .7181766z"
          fill="#fff"
        />
      </g>
    </Base>
  );
};

export default memo(ConfirmIcon);

const Base = styled.svg`
  cursor: pointer;
  margin-left: 0.625rem;
`;
