import React, { memo, useContext } from 'react';
import styled from 'styled-components';
import Button from '../styled/Button';
import { MultiPageFormContext } from './MultiPageForm';
import SubmitButton from './SubmitButton';
import { viewports } from '../../utils/variables';

const Pagination = ({
  centered,
  prevActive,
  nextActive,
  submitActive,
  submitText,
  onClickPrev,
  onClickNext,
  onClickSubmit,
}) => {
  const { handlePrevScreen, isSubmitting } = useContext(MultiPageFormContext);

  return (
    <Base centered={centered}>
      {prevActive && (
        <PrevLink
          centered={centered}
          active={prevActive}
          onClick={(args) => {
            handlePrevScreen(args);
            onClickPrev ? onClickPrev() : null;
          }}
          tabIndex={-1}
          secondary
        >
          Back
        </PrevLink>
      )}
      {nextActive && (
        <NextLink type="submit" centered={centered} active={nextActive}>
          Next
        </NextLink>
      )}
      {submitActive && (
        <SubmitLink
          centered={centered}
          submitting={isSubmitting}
          onClick={onClickSubmit}
        >
          {submitText}
        </SubmitLink>
      )}
    </Base>
  );
};

export default memo(Pagination);

const Base = styled.div`
  display: flex;
  justify-content: ${(props) => (props.centered ? 'center' : 'space-between')};
  margin: 0.625rem 0 0;
  padding-bottom: 4rem;

  @media ${viewports.medium} {
    padding-bottom: 0;
  }
`;

const PrevLink = styled(Button)`
  margin-left: 0;
  margin-right: ${(props) =>
    props.centered || props.active ? 'auto' : '0.625rem'};
`;

const NextLink = styled(Button)`
  margin-left: ${(props) =>
    props.centered || props.active ? 'auto' : '0.625rem'};
  margin-right: 0;
`;

const SubmitLink = styled(SubmitButton)`
  margin-left: ${(props) =>
    props.centered || props.active ? 'auto' : '0.625rem'};
  margin-right: 0;
`;
