import React, { memo, useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/shared/Layout';
import LandingController from '../components/landing/LandingController';
import Header from '../components/landing/Header';
import AnimatedBackground from '../components/landing/AnimatedBackground';
import Content from '../components/landing/Content';
import Indicators from '../components/landing/Indicators';
import SignIn from '../components/sign-in/SignIn';
import Register from '../components/register/Register';
import metadata from '../metadata.json';

const IndexPage = ({ location: { state } }) => {
  const [sidebar, setSidebar] = useState(state ? state.sidebar : undefined);
  console.log(`Current build: ${metadata.buildRevision}`);
  return (
    <Layout redirect="/explore">
      <LandingController>
        {({ activeScene, indicators, removeIndicator }) => (
          <Base>
            <Header toggleSidebar={setSidebar} />
            <AnimatedBackground activeScene={activeScene} visible={!sidebar} />
            <Indicators
              indicators={indicators}
              visible={!sidebar}
              removeIndicator={removeIndicator}
            />
            <Content activeScene={activeScene} toggleSidebar={setSidebar} visible={!sidebar} />
            <SignIn visible={sidebar === 'sign-in'} toggleSidebar={setSidebar} />
            <Register visible={sidebar === 'register'} toggleSidebar={setSidebar} />
          </Base>
        )}
      </LandingController>
    </Layout>
  );
};

export default memo(IndexPage);

const Base = styled.div`
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
`;
