import React, { memo, useState } from 'react';
import queryString from 'querystring';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { Mutation } from 'react-apollo';
import Layout from '../components/shared/Layout';
import PasswordInput from '../components/inputs/PasswordInput';
import SubmitButton from '../components/shared/SubmitButton';
import { resetPasswordValidation } from '../utils/validationSchema';
import { MapBackground } from '../components/styled/Background';
import { viewports } from '../utils/variables';
import { Heading, Subheading } from '../components/styled/Typography';
import ServerErrorMessage from '../components/inputs/ServerErrorMessage';
import Success from '../components/reset-password/Success';
import resetPassword from '../data/mutations/resetPassword';

const ResetPasswordPage = ({ location }) => {
  const [reset, setReset] = useState(false);
  const { search } = location;
  const { email, token } = queryString.parse(search.slice(1, search.length));
  return (
    <Layout title="ArtCenter - Reset Password">
      <Base>
        <MapBackground />
        <Container>
          {!reset ? (
            <Mutation mutation={resetPassword}>
              {(handleResetPassword, { error }) => (
                <Formik
                  initialValues={{
                    newPassword: '',
                    confirmNewPassword: '',
                  }}
                  validate={resetPasswordValidation}
                  onSubmit={async (values, actions) => {
                    try {
                      await handleResetPassword({
                        variables: {
                          token,
                          password: values.newPassword,
                        },
                      });
                      actions.setSubmitting(false);
                      setReset(true);
                    } catch (err) {
                      actions.setSubmitting(false);
                    }
                  }}
                  validateOnChange={false}
                  validateOnBlur={false}
                >
                  {({ isSubmitting, errors, touched }) => {
                    return (
                      <StyledForm>
                        <Heading>Reset Password</Heading>
                        <ResetPasswordHeader>
                          Please reset your password
                        </ResetPasswordHeader>
                        {/* {!isSubmitting && (
                          <ServerErrorMessage visible={error} error={error} email={email} />
                        )} */}
                        {error && error.graphQLErrors ? (
                          !isSubmitting && (
                            <ServerErrorMessage
                              visible={error}
                              error={error}
                              email={email}
                            />
                          )
                        ) : (
                          <>
                            <Field name="newPassword">
                              {({ field }) => (
                                <PasswordInput
                                  {...field}
                                  label="Password"
                                  placeholder="Enter new password"
                                  error={errors.newPassword}
                                  touched={touched.newPassword}
                                />
                              )}
                            </Field>
                            <Field name="confirmNewPassword">
                              {({ field }) => (
                                <PasswordInput
                                  {...field}
                                  label="Confirm password"
                                  placeholder="Enter password again"
                                  error={errors.confirmNewPassword}
                                  touched={touched.confirmNewPassword}
                                  height={36}
                                />
                              )}
                            </Field>

                            <SubmitButton
                              disabled={isSubmitting}
                              submitting={isSubmitting}
                            >
                              Submit
                            </SubmitButton>
                          </>
                        )}
                      </StyledForm>
                    );
                  }}
                </Formik>
              )}
            </Mutation>
          ) : (
            <Success />
          )}
        </Container>
      </Base>
    </Layout>
  );
};

export default memo(ResetPasswordPage);

const Base = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
`;

const Container = styled.div`
  background-color: #fff;
  max-width: 30rem;
  box-shadow: 8px 8px 20px 0 rgba(34, 34, 34, 0.06);
  padding: 2.75rem 1.875rem;
  position: relative;
  width: 100%;

  @media ${viewports.medium} {
    padding: 3.4375rem 3.75rem;
  }
`;

const ResetPasswordHeader = styled(Subheading)`
  &&& {
    margin-bottom: 1.5rem;
  }
`;

const StyledForm = styled(Form)`
  min-height: 10rem;
`;
