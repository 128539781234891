import gql from 'graphql-tag';

const getGeolocation = gql`
  query Geolocation($longitude: Float!, $latitude: Float!) {
    geolocation(longitude: $longitude, latitude: $latitude) {
      id
      level
    }
  }
`;

export default getGeolocation;
