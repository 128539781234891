import React, { memo, useContext } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { Query } from 'react-apollo';
import { fontFamilies, colors } from '../../utils/variables';
import FilterIcon from '../icons/FilterIcon';
import SelectInput from '../inputs/SelectInput';
import { BodyText } from '../styled/Typography';
import FilterConfirmation from './FilterConfirmation';
import { locationTypes } from '../../utils/selectOptions';
import { AlumniFetcherContext } from './AlumniFetcher';
import { ActiveFiltersContext } from './ActiveFilters';
import getLocations from '../../data/queries/getLocations';

const LocationFilter = () => {
  const { level, fetchLocation, activeLocation, resetLocation } = useContext(AlumniFetcherContext);
  const { setFilterPage } = useContext(ActiveFiltersContext);
  return (
    <Base>
      <Scrollbars autoHide universal>
        <Formik
          initialValues={{
            filterType: activeLocation.type !== 'world' ? locationTypes[level - 1] : null,
            continent:
              activeLocation.type === 'continent'
                ? {
                    label: activeLocation.name,
                    value: activeLocation.name,
                  }
                : null,
            country:
              activeLocation.type === 'country'
                ? {
                    label: activeLocation.name,
                    value: activeLocation.name,
                  }
                : null,
            stateTerritory:
              activeLocation.type === 'state'
                ? {
                    label: activeLocation.name,
                    value: activeLocation.name,
                  }
                : null,
            city:
              activeLocation.type === 'city'
                ? {
                    label: activeLocation.name,
                    value: activeLocation.name,
                  }
                : null,
          }}
          onSubmit={(values) => {
            if (!values.filterType) {
              fetchLocation({ level: 0, id: undefined });
            } else {
              switch (values.filterType.value) {
                case 'Continent':
                  fetchLocation({ level: 1, id: +values.continent.value });
                  break;
                case 'Country':
                  fetchLocation({ level: 2, id: +values.country.value });
                  break;
                case 'StateTerritory':
                  fetchLocation({
                    level: 3,
                    id: +values.stateTerritory.value,
                  });
                  break;
                case 'City':
                  fetchLocation({ level: 4, id: +values.city.value });
                  break;
                default:
                  break;
              }
            }
            setFilterPage(undefined);
          }}
        >
          {({ values, setFieldValue, handleSubmit, resetForm }) => (
            <>
              <FormWrapper>
                <TitleContainer>
                  <Title>Location</Title>
                  <FilterIcon />
                </TitleContainer>
                <Copy>Filter by city, state/territory, country, or continent.</Copy>
                <Field name="filterType">
                  {({ field, form }) => (
                    <SelectInput
                      {...field}
                      form={form}
                      label="Filter By"
                      placeholder="Select filter type"
                      options={locationTypes}
                    />
                  )}
                </Field>
                {values.filterType && (
                  <>
                    {values.filterType.value === 'Continent' && (
                      <Query
                        query={getLocations}
                        variables={{ type: 'continent' }}
                        // fetchPolicy="cache-and-network"
                      >
                        {({ data, loading }) => {
                          let locations;
                          if (data) locations = data.locations;
                          return (
                            !loading && (
                              <Field name="continent">
                                {({ field, form }) => (
                                  <SelectInput
                                    {...field}
                                    form={form}
                                    label="Continent"
                                    placeholder="Select continent"
                                    options={locations.map((_) => ({
                                      value: _.id,
                                      label: _.name,
                                    }))}
                                  />
                                )}
                              </Field>
                            )
                          );
                        }}
                      </Query>
                    )}
                    {values.filterType.value === 'Country' && (
                      <Query
                        query={getLocations}
                        variables={{ type: 'country' }}
                        // fetchPolicy="cache-and-network"
                      >
                        {({ data, loading }) => {
                          let locations;
                          if (data) locations = data.locations;
                          return (
                            !loading && (
                              <Field name="country">
                                {({ field, form }) => (
                                  <SelectInput
                                    {...field}
                                    form={form}
                                    label="Country"
                                    placeholder="Select country"
                                    options={locations.map((_) => ({
                                      value: _.id,
                                      label: _.name,
                                    }))}
                                  />
                                )}
                              </Field>
                            )
                          );
                        }}
                      </Query>
                    )}
                    {values.filterType.value === 'StateTerritory' && (
                      <Query
                        query={getLocations}
                        variables={{ type: 'state' }}
                        // fetchPolicy="cache-and-network"
                      >
                        {({ data, loading }) => {
                          let locations;
                          if (data) locations = data.locations;
                          return (
                            !loading && (
                              <Field name="stateTerritory">
                                {({ field, form }) => (
                                  <SelectInput
                                    {...field}
                                    form={form}
                                    label="State/Territory"
                                    placeholder="Select state/territory"
                                    options={locations.map((_) => ({
                                      value: _.id,
                                      label: _.name,
                                    }))}
                                  />
                                )}
                              </Field>
                            )
                          );
                        }}
                      </Query>
                    )}
                    {values.filterType.value === 'City' && (
                      <Query
                        query={getLocations}
                        variables={{ type: 'city' }}
                        // fetchPolicy="cache-and-network"
                      >
                        {({ data, loading }) => {
                          let locations;
                          if (data) locations = data.locations;
                          return (
                            !loading && (
                              <Field name="city">
                                {({ field, form }) => (
                                  <SelectInput
                                    {...field}
                                    form={form}
                                    label="City"
                                    placeholder="Select city"
                                    options={locations.map((_) => ({
                                      value: _.id,
                                      label: `${_.name}, ${_.parentName}`,
                                    }))}
                                  />
                                )}
                              </Field>
                            )
                          );
                        }}
                      </Query>
                    )}
                  </>
                )}
              </FormWrapper>

              <FilterConfirmation
                clear={() => {
                  resetForm();
                  setFieldValue('filterType', null);
                  setFieldValue('continent', null);
                  setFieldValue('country', null);
                  setFieldValue('stateTerritory', null);
                  setFieldValue('city', null);
                  resetLocation();
                }}
                submit={handleSubmit}
              />
            </>
          )}
        </Formik>
      </Scrollbars>
    </Base>
  );
};

export default memo(LocationFilter);

const Base = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  background: #fff;
  position: fixed;
  right: 0;
  top: 0;
  //doesn't change anything but should be necessary, add as part of AC-161
  -webkit-overflow-scrolling: touch;
`;

const FormWrapper = styled(Form)`
  min-height: 70vh;
  padding: 1.6875rem 1.875rem;
`;

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
`;

const Title = styled.div`
  color: ${colors.contentBlack};
  font-family: ${fontFamilies.bold};
  font-size: 1.25rem;
  padding-right: 0.375rem;
`;

const Copy = styled(BodyText)`
  font-size: 0.875rem;
  line-height: 18px;
  margin-bottom: 1.875rem;
`;
