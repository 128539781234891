import gql from 'graphql-tag';

const getInfographic = gql`
  query Infographic($locationId: Int, $searchValue: String, $activeFilters: Filters) {
    infographic(locationId: $locationId, searchValue: $searchValue, activeFilters: $activeFilters) {
      classYears {
        name
        percent
      }
      industries {
        name
        percent
      }
      majors {
        name
        percent
      }
    }
  }
`;

export default getInfographic;
