import React from 'react';
import InvalidToken from '../reset-password/InvalidToken';

const INVALID_TOKEN = 'Invalid token';

const GraphQLErrors = (errors, email) => {
  return errors.graphQLErrors.map(({ message }, i) => {
    switch (message) {
      case INVALID_TOKEN:
        return <InvalidToken email={email} key={i} />;

      default:
        return <span key={i}>{message}</span>;
    }
  });
};

export default GraphQLErrors;
