import React, { memo } from 'react';
import styled from 'styled-components';
import { useTransition, animated, useSpring } from 'react-spring';
import { viewports, colors } from '../../utils/variables';
import { MapBackground } from '../styled/Background';
import { LandingGridCream, LandingGridWhite } from '../styled/Grid';
import { backgroundColor } from './landingData';

const AnimatedBackground = ({ activeScene, visible }) => {
  const backgroundSpring = useSpring({
    from: {
      background: `linear-gradient(to bottom, ${colors.offWhite}, ${colors.offWhite})`,
    },
    to: {
      background: backgroundColor(activeScene),
    },
    config: { duration: 2000 },
  });

  const transition = useTransition(activeScene >= 0 && activeScene < 2, g => g, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 2000 },
  });

  return (
    <Base visible={visible}>
      <animated.div
        style={{
          ...backgroundSpring,
          bottom: 0,
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
        }}
      />
      {transition.map(({ item, props, key }) =>
        item ? (
          <animated.div style={props} key={key}>
            <LandingGridCream />
          </animated.div>
        ) : (
          <animated.div style={props} key={key}>
            <LandingGridWhite />
          </animated.div>
        )
      )}
      {transition.map(
        ({ item, props, key }) =>
          item && (
            <animated.div style={props} key={key}>
              <MapBackground />
            </animated.div>
          )
      )}
    </Base>
  );
};

export default memo(AnimatedBackground);

const Base = styled.div`
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  transition: visibility 1ms linear ${props => !props.visible && '1000ms'};

  @media ${viewports.medium} {
    visibility: visible;
  }
`;
