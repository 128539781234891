import React, { memo, useEffect, useRef } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import Overlay from '../styled/Overlay';
import { viewports } from '../../utils/variables';
import Button from '../styled/Button';
import CloseButton from '../shared/CloseButton';
import EditForm from './EditForm';
import SubmitButton from '../shared/SubmitButton';
import { Subheading } from '../styled/Typography';
import editPendingUser from '../../data/mutations/editPendingUser';
import { adminEditValidation } from '../../utils/validationSchema';
import ServerErrorMessage from '../inputs/ServerErrorMessage';

const EditPopup = ({ handleCancelClick, user, refetch }) => {
  const overlayRef = useRef(null);

  useEffect(() => {
    disableBodyScroll(overlayRef.current);
    return () => clearAllBodyScrollLocks();
  });

  const handleSubmit = async (values, actions, handleEditPendingUser) => {
    try {
      actions.setSubmitting(true);
      const variables = {
        ...values,
        id: +user.id,
        graduationYear: +values.graduationYear,
      };

      // make sure major is a string value
      variables.major = values.major.label ? values.major.label : values.major;

      variables.secondaryMajor = values.secondaryMajor.label
        ? values.secondaryMajor.label
        : values.secondaryMajor;

      variables.country = values.country.label ? values.country.label : values.country;

      await handleEditPendingUser({ variables });
      refetch();

      handleCancelClick();
    } catch (error) {
      console.error(error);
      actions.setSubmitting(false);
    }
  };

  return (
    <Overlay ref={overlayRef}>
      <Mutation mutation={editPendingUser}>
        {(handleEditPendingUser, { error }) => (
          <Base>
            <Formik
              initialValues={{
                firstName: user.firstName || '',
                lastName: user.lastName || '',
                preferredName: user.preferredName || '',
                email: user.email || '',
                portalId: user.portalId || '',
                birthdateMonth: user.birthdate.month
                  ? { value: user.birthdate.month, label: user.birthdate.month }
                  : null,
                birthdateDay: user.birthdate.day
                  ? { value: user.birthdate.day, label: user.birthdate.day }
                  : null,
                birthdateYear: user.birthdate.year
                  ? { value: user.birthdate.year, label: user.birthdate.year }
                  : null,
                graduationYear: user.graduationYear || '',
                major: user.major || '',
                secondaryMajor: user.secondaryMajor || '',
                streetAddress1: user.streetAddress1 || '',
                streetAddress2: user.streetAddress2 || '',
                city: user.city || '',
                state: user.state || '',
                postalCode: user.postalCode || '',
                country: {
                  label: user.country || '',
                  value: user.country || '',
                },
              }}
              validate={adminEditValidation}
              onSubmit={async (values, actions) => {
                handleSubmit(values, actions, handleEditPendingUser);
              }}
            >
              {({ values, isSubmitting }) => {
                return (
                  <Form>
                    <EditForm values={values} />
                    {!isSubmitting && (
                      <ServerErrorMessage visible={error} error={error} />
                    )}
                    <Buttons>
                      <ActionButton secondary onClick={handleCancelClick}>
                        Cancel
                      </ActionButton>
                      <ActionSubmitButton
                        disabled={isSubmitting}
                        submitting={isSubmitting}
                      >
                        Save Changes
                      </ActionSubmitButton>
                    </Buttons>
                  </Form>
                );
              }}
            </Formik>
            <Close onClick={handleCancelClick} />
          </Base>
        )}
      </Mutation>
    </Overlay>
  );
};

export default memo(EditPopup);

const Base = styled.div`
  background-color: #fff;
  bottom: 0;
  box-shadow: 8px 8px 20px 0 rgba(34, 34, 34, 0.06);
  display: flex;
  left: 0;
  padding: 2.8125rem 3.125rem;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;
  overflow-y: auto;
  max-height: 100%;

  @media ${viewports.medium} {
    display: block;
    position: relative;
    width: 50.625rem;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  @media ${viewports.medium} {
    padding-bottom: 0;
  }
`;

const ActionButton = styled(Button)`
  margin: 0 1.25rem 0 0;
  min-width: 6.875rem;
  padding: 0 1.5rem;
  width: auto;
`;

const ActionSubmitButton = styled(SubmitButton)`
  margin: 0;
  min-width: 6.875rem;
  padding: 0 1.5rem;
  width: auto;
`;

const Close = styled(CloseButton)`
  position: absolute;
  right: 1.9375rem;
  top: 1.9375rem;
`;
