import React, { memo } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Formik, Form, Field } from 'formik';
import { Mutation } from 'react-apollo';
import { signInValidation } from '../../utils/validationSchema';
import Sidebar from '../shared/Sidebar';
import { fontFamilies, colors, viewports } from '../../utils/variables';
import TextInput from '../inputs/TextInput';
import PasswordInput from '../inputs/PasswordInput';
import SubmitButton from '../shared/SubmitButton';
import { Heading, Subheading } from '../styled/Typography';
import ServerErrorMessage from '../inputs/ServerErrorMessage';
import { setCookies } from '../../utils/auth';
import ForgotPasswordController from './ForgotPasswordController';
import signIn from '../../data/mutations/signIn';

const SignIn = ({ toggleSidebar, visible }) => {
  return (
    <Sidebar visible={visible} exitSidebar={() => toggleSidebar(undefined)}>
      <ForgotPasswordController toggleSidebar={toggleSidebar}>
        {({ setActiveScreen }) => (
          <Base>
            <Mutation mutation={signIn}>
              {(handleSignIn, { error }) => (
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                  }}
                  validate={signInValidation}
                  onSubmit={async (values, actions) => {
                    try {
                      const { data } = await handleSignIn({
                        variables: {
                          email: values.email,
                          password: values.password,
                        },
                      });
                      if (data.signIn) {
                        const { token, user } = data.signIn;
                        setCookies({ token, user });
                        navigate('/explore');
                      }
                    } catch (err) {
                      actions.setSubmitting(false);
                    }
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Heading>Sign In</Heading>
                      <Subheading>Sign in to your account below.</Subheading>
                      {!isSubmitting && (
                        <ServerErrorMessage visible={error} error={error} />
                      )}
                      <Field name="email">
                        {({ field, form }) => (
                          <TextInput
                            {...field}
                            label="Email Address"
                            placeholder="Enter email address"
                            error={form.errors.email}
                            touched={form.touched.email}
                          />
                        )}
                      </Field>
                      <Field name="password">
                        {({ field, form }) => (
                          <PasswordInput
                            {...field}
                            forgotLink
                            onForgotClick={() => setActiveScreen('forgot')}
                            label="Password"
                            placeholder="Enter password"
                            error={form.errors.password}
                            touched={form.touched.password}
                          />
                        )}
                      </Field>
                      <SignInButton
                        disabled={isSubmitting}
                        submitting={isSubmitting}
                      >
                        Sign In
                      </SignInButton>
                      <Register>
                        Don't have an account?{' '}
                        <RegisterLink onClick={() => toggleSidebar('register')}>
                          Register Now
                        </RegisterLink>
                      </Register>
                    </Form>
                  )}
                </Formik>
              )}
            </Mutation>
          </Base>
        )}
      </ForgotPasswordController>
    </Sidebar>
  );
};

export default memo(SignIn);

const Base = styled.div`
  padding: 3.4375rem 1.875rem;

  @media ${viewports.medium} {
    padding: 3.4375rem 3.75rem;
  }
`;

const Register = styled.div`
  color: #999;
  font-size: 0.875rem;
  margin-top: 3.75rem;
  text-align: center;
`;

const SignInButton = styled(SubmitButton)`
  margin-bottom: 3.125rem;
  margin-top: 3.125rem;
  width: 100%;
`;

const RegisterLink = styled.a`
  color: ${colors.blue};
  cursor: pointer;
  font-family: ${fontFamilies.bold};

  @media ${viewports.medium} {
    &:hover {
      color: ${colors.hoverBlue};
    }
  }
`;
