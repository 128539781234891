import React, { memo } from 'react';
import styled from 'styled-components';
import { colors, viewports } from '../../utils/variables';

const ExploreIcon = ({ onClick, highlighted }) => (
  <Base
    height="50"
    viewBox="0 0 50 50"
    width="50"
    xmlns="http://www.w3.org/2000/svg"
    onClick={!highlighted ? onClick : () => {}}
    highlighted={highlighted}
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="25" cy="25" r="25" />
      <g transform="matrix(.70710678 .70710678 -.70710678 .70710678 30.627417 8)">
        <path
          d="m15.5527864 16.8944272-7.1055728 14.2111456c-.12349463.2469893-.42383115.3471014-.6708204.2236068-.0967637-.0483818-.17522494-.1268431-.2236068-.2236068l-7.1055728-14.2111456c-.28152749-.563055-.28152749-1.2257994 0-1.7888544l7.1055728-14.21114561c.12349463-.24698925.42383115-.34710142.6708204-.2236068.0967637.04838185.17522494.1268431.2236068.2236068l7.1055728 14.21114561c.2815275.563055.2815275 1.2257994 0 1.7888544z"
          fill="#fffff7"
        />
        <circle cx="8" cy="16" r="3" />
      </g>
    </g>
  </Base>
);

export default memo(ExploreIcon);

const Base = styled.svg`
  bottom: 0.9375rem;
  cursor: pointer;
  height: 3.125rem;
  left: 0.9375rem;
  pointer-events: ${props => (props.highlighted ? 'none' : 'auto')};
  position: fixed;
  width: 3.125rem;
  z-index: ${props => (props.highlighted ? '100001' : '5')};

  & circle {
    fill: ${colors.red};
  }

  @media ${viewports.medium} {
    left: 1.25rem;
    position: absolute;
    top: 1.25rem;

    &:hover {
      & circle {
        fill: ${colors.hoverRed};
      }
    }
  }
`;
