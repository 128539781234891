import React, { createContext, memo, useState, useContext, useEffect } from 'react';
import { Query } from 'react-apollo';
import { ActiveFiltersContext } from './ActiveFilters';
import { ActiveLocationContext } from './ActiveLocation';
import useDebounce from '../../hooks/useDebounce';
import getUsers from '../../data/queries/getUsers';
import LoadingIcon from '../shared/LoadingIcon';

export const AlumniFetcherContext = createContext();

const AlumniFetcher = ({ firstTime, children }) => {
  const { activeFilters } = useContext(ActiveFiltersContext);
  const {
    fetchLocation,
    activeLocation,
    activeLocationLoading,
    flow,
    locationVariables,
  } = useContext(ActiveLocationContext);

  /**
   * Active Screen
   */
  const [activeScreen, setActiveScreen] = useState(firstTime ? 'setup' : undefined);

  /**
   * Search
   */
  const [searchValue, setSearchValue] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(searchValue, 500, setIsSearching);

  const resetSearch = () => {
    setSearchValue('');
    setSearchQuery('');
  };

  const handleSearchInputChange = (event) => {
    const {
      target: { value },
    } = event;
    setSearchValue(value);
  };

  /**
   * Active Alumni
   */
  const [activeAlum, setActiveAlum] = useState(undefined);

  /**
   * Location
   */
  const resetLocation = () => {
    fetchLocation({ level: 0, id: undefined });
  };

  /**
   * Use Effect for debouncing search
   */
  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearchQuery(debouncedSearchTerm);
    } else {
      setSearchQuery('');
    }
  });

  return (
    <Query
      query={getUsers}
      variables={{
        level: +locationVariables.level,
        locationId: +locationVariables.id,
        searchValue: searchQuery,
        activeFilters,
        page: 0,
        perPage: 50,
      }}
    >
      {({ data, loading: alumniLoading, fetchMore }) => {
        let users;
        if (data) {
          users = data.users;
        } else {
          return <LoadingIcon />;
        }
        return (
          <AlumniFetcherContext.Provider
            value={{
              /**
               * Screen
               */
              activeScreen,
              setActiveScreen,
              loading: isSearching || alumniLoading || activeLocationLoading,
              /**
               * Search
               */
              searchValue,
              resetSearch,
              handleSearchInputChange,
              /**
               * Alumni
               */
              activeAlum,
              setActiveAlum,
              numAlumni: users && users.total,
              alumni: users && users.users,
              fetchMore,
              /**
               * Locations
               */
              flow,
              locations: activeLocation ? activeLocation.locations : [],
              level: locationVariables.level,
              activeLocation,
              fetchLocation,
              resetLocation,
              locationVariables,
            }}
          >
            {children}
          </AlumniFetcherContext.Provider>
        );
      }}
    </Query>
  );
};

export default memo(AlumniFetcher);
