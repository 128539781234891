import React, { memo } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { colors } from '../../utils/variables';

const ProgressNavDivider = ({ completed, flow, screenIndex, formScreen, firstIndex }) => {
  const spring = useSpring({
    from: { x: -100 },
    to: {
      x: completed ? 0 : -100,
    },
    immediate:
      flow === 'forward'
        ? screenIndex === formScreen - 2 - firstIndex
        : screenIndex === formScreen - 1 - firstIndex,
    reverse: flow === 'backward' && screenIndex === formScreen - firstIndex,
  });

  return (
    <Base>
      <Divider />
      <animated.div
        style={{
          transform: spring.x.interpolate(x => `translateX(${x}%)`),
          backgroundColor: colors.red,
          bottom: 0,
          left: 0,
          right: 0,
          position: 'absolute',
          top: 0,
        }}
      />
    </Base>
  );
};

export default memo(ProgressNavDivider);

const Base = styled.div`
  overflow: hidden;
  position: relative;
  z-index: -1;
`;

const Divider = styled.div`
  background-color: #eee;
  height: 0.25rem;
  position: relative;
  width: 3.125rem;
`;
