import React, { memo, useContext, useState } from 'react';
import { Field, setNestedObjectValues } from 'formik';
import TextInput from '../inputs/TextInput';
import SelectInput, { SelectCountry } from '../inputs/SelectInput';
import Pagination from '../shared/Pagination';
import Wrapper from './Wrapper';
import ServerErrorMessage from '../inputs/ServerErrorMessage';
import { MultiPageFormContext } from '../shared/MultiPageForm';
import AddressAutoComplete from '../shared/AddressAutoComplete';

const Location = ({ error }) => {
  const { isSubmitting, setSubmitting, errorSeen, setErrorSeen } = useContext(
    MultiPageFormContext
  );
  return (
    <Wrapper
      heading="Location & Contact info"
      subheading="Enter your address and contact information."
    >
      {!isSubmitting && !errorSeen && (
        <ServerErrorMessage visible={error} error={error} />
      )}

      <Field name="autoAddress">
        {({ field, form }) => <AddressAutoComplete field={field} form={form} />}
      </Field>
      <Field name="streetAddress1">
        {({ field, form }) => (
          <TextInput
            {...field}
            label="Street Address 1"
            placeholder="Street address"
            error={form.errors.streetAddress1}
            touched={form.touched.streetAddress1}
            autoComplete="off"
          />
        )}
      </Field>
      <Field name="streetAddress2">
        {({ field, form }) => (
          <TextInput
            {...field}
            label="Street Address 2"
            placeholder="Apt number, unit, building, floor"
            error={form.errors.streetAddress2}
            touched={form.touched.streetAddress2}
            autoComplete="off"
          />
        )}
      </Field>
      <PostCityStateInput />
      <Field name="country">
        {({ field, form }) => {
          return (
            <SelectCountry
              {...field}
              form={form}
              label="Country"
              error={form.errors.country}
              touched={form.touched.country}
              menuPlacement="top"
              tabIndex={0}
            />
          );
        }}
      </Field>

      <Pagination
        prevActive
        submitActive
        submitText="Submit"
        submit
        onClickPrev={() => {
          setErrorSeen(true);
        }}
        onClickSubmit={() => {
          // console.log('error not seen');
          setErrorSeen(false);
        }}
      />
    </Wrapper>
  );
};

export default memo(Location);

const PostCityStateInput = () => {
  return (
    <>
      <Field name="postalCode">
        {({ field, form }) => {
          return (
            <TextInput
              {...field}
              label="Postal Code"
              placeholder="Enter postal code"
              error={form.errors.postalCode}
              touched={form.touched.postalCode}
              autoComplete="off"
            />
          );
        }}
      </Field>

      <Field name="city">
        {({ field, form }) => (
          <TextInput
            {...field}
            label="City (Full Name Without Abbreviations)"
            placeholder="Enter city"
            error={form.errors.city}
            touched={form.touched.city}
            autoComplete="off"
          />
        )}
      </Field>
      <Field name="state">
        {({ field, form }) => (
          <>
            <TextInput
              {...field}
              label="State/Territory"
              placeholder="Enter state or territory"
              error={form.errors.state}
              touched={form.touched.state}
              autoComplete="off"
            />
          </>
        )}
      </Field>
    </>
  );
};
