export const colors = {
  contentBlack: '#111',
  linkBlack: '#333',
  red: '#FC4600',
  offWhite: '#FFFFF7',
  blue: '#338fff',
  hoverBlack: '#000000',
  hoverRed: '#d73d00',
  hoverBlue: '#256ECF',
};

export const fontFamilies = {
  regular: '"NeueHassGroteskText W01"',
  medium: '"NeueHaasGroteskMedium"',
  bold: '"NeueHaasGroteskBold"',
};

export const viewports = {
  min: 'screen and (min-width: 360px)',
  small: 'screen and (max-width: 540px)',
  smallMedium: 'screen and (min-width: 540px)',
  medium: 'screen and (min-width: 780px)',
  semiLarge: 'screen and (min-width: 1280px)',
  large: 'screen and (min-width: 1440px)',
};

export const MAX_NUM_UPLOADS = 12;
