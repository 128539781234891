import React, { memo } from 'react';
import { useTransition, animated } from 'react-spring';

const AnimatedTextContainer = ({ children, visible }) => {
  const transition = useTransition(visible, null, {
    from: { o: 0, y: 2 },
    enter: { o: 1, y: 0 },
    leave: { o: 0, y: -2 },
  });

  return transition.map(
    ({ item, props, key }) =>
      item && (
        <animated.div
          style={{
            opacity: props.o.interpolate(o => o),
            transform: props.y.interpolate(y => `translateY(${y}rem)`),
          }}
          key={key}
        >
          {children}
        </animated.div>
      )
  );
};

export default memo(AnimatedTextContainer);
