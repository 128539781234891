import React, { memo } from 'react';
import styled from 'styled-components';
import { BodyText } from '../styled/Typography';
import { colors, fontFamilies } from '../../utils/variables';
import Button from '../styled/Button';
import Wrapper from './Wrapper';

const Location = () => (
  <Wrapper heading="Success!" subheading="Thanks for joining the alumni network.">
    <Copy>
      Before you can sign in to the ArtCenter Alumni Network, we need to verify your information.{' '}
      <strong>This can take from 24 hours to several days.</strong> We will send a verification
      email once we have confirmed that you are an ArtCenter alumni.
      <br />
      <br />
      While you wait for confirmation, check out our upcoming alumni events.
    </Copy>
    <ExternalButton
      as="a"
      href="http://www.artcenter.edu/connect/events/index.html?category=5"
      target="_blank"
      rel="noopener noreferrer"
    >
      ArtCenter Alumni Calendar
    </ExternalButton>
    <ExternalButtonSecondary
      secondary
      as="a"
      href="http://www.artcenter.edu/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Visit ArtCenter.edu
    </ExternalButtonSecondary>
  </Wrapper>
);

export default memo(Location);

const Copy = styled(BodyText)`
  color: #565656;
  margin-bottom: 3.125rem;

  & strong {
    color: ${colors.red};
    font-family: ${fontFamilies.regular};
  }
`;

const ExternalButton = styled(Button)`
  margin-bottom: 1.5rem;
  width: 100%;
`;

const ExternalButtonSecondary = styled(ExternalButton)`
  margin-bottom: 0;
`;
