import React, { PureComponent } from 'react';
import styled from 'styled-components';

import './ToolTip.css';

// const toolTipStyle = {
//   content: { background: '#FFF' },
//   tooltip: { background: '#FFF', border: 'border: medium solid #999' },
// };

class ToolTip extends PureComponent {
  state = { hover: false };

  handleMouseEnter = () => {
    this.setState({ hover: true });
  };

  handleMouseLeave = () => {
    this.setState({ hover: false });
  };

  render() {
    const {
      text,
      children,
      minWidth,
      src,
      alt,
      onClick,
      displayText,
    } = this.props;
    const { hover } = this.state;
    return (
      <span
        className="tooltip"
        onMouseLeave={this.handleMouseLeave}
        onClick={(e) => {
          onClick ? onClick(e) : null;
        }}
      >
        {hover && (
          <TooltipBubble minWidth={minWidth}>
            <div className="tooltip-message">{text}</div>
          </TooltipBubble>
        )}
        <span
          className="tooltip-trigger"
          onMouseOver={this.handleMouseEnter}
          onFocus={this.handleMouseEnter}
          // onClick={(e) => {
          //   debugger;
          //   if (hover) {
          //     this.handleMouseLeave();
          //     onClick ? onClick(e) : null;
          //   } else {
          //     this.handleMouseEnter();
          //   }
          //   // this.setState({ hover: !this.state.hover });
          //   // onClick ? onClick(e) : null;
          // }}
        >
          {displayText}
          <img src={src} alt={alt} />
        </span>
      </span>
    );
  }
}

const TooltipBubble = styled.div`
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}rem` : '18rem')};
  position: absolute;
  z-index: 10;

  ::after {
    content: ' ';
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #999 transparent;
  }

  top: 100%;
  left: 50%;
  /* padding-top: 9px; */
  transform: translateX(-50%);
`;

export default ToolTip;
