import React, { memo, createContext, useState } from 'react';
import { Formik } from 'formik';

export const MultiPageFormContext = createContext();

const MultiPageForm = ({ screens, children, initialValues, submit }) => {
  const [formScreen, setFormScreen] = useState(0); // type is number
  const [errorSeen, setErrorSeen] = useState(false); // type is true or false
  const [flow, setFlow] = useState('forward'); // type is 'forward' or 'backward'

  const handleNextScreen = () => {
    setFlow('forward');
    setFormScreen(formScreen + 1);
  };

  const handlePrevScreen = () => {
    // actions.setSubmitting(false);
    setFlow('backward');
    setFormScreen(formScreen - 1);
  };

  const validate = (values) => {
    const activeScreen = screens[formScreen];
    return activeScreen.props.validate ? activeScreen.props.validate(values) : {};
  };

  const handleSubmit = async (values, actions) => {
    const isLastPage = screens.length - 2 === formScreen;
    if (isLastPage) {
      try {
        await submit(values);
        actions.setSubmitting(false);
        handleNextScreen();
      } catch (err) {
        actions.setSubmitting(false);
      }
    } else {
      handleNextScreen();
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ isSubmitting, submitForm, setSubmitting }) => {
        return (
          <MultiPageFormContext.Provider
            value={{
              formScreen,
              isSubmitting,
              submitForm,
              flow,
              handleNextScreen,
              handlePrevScreen,
              setSubmitting,
              errorSeen,
              setErrorSeen,
              activeScreen: screens[formScreen]
                ? screens[formScreen]
                : screens[screens.length - 1],
            }}
          >
            {children}
          </MultiPageFormContext.Provider>
        );
      }}
    </Formik>
  );
};

export default memo(MultiPageForm);
