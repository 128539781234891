import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import Overlay from '../styled/Overlay';
import LoadingIcon from './LoadingIcon';

class PageLoader extends PureComponent {
  containerEl = document.createElement('div');

  componentDidMount = () => {
    if (typeof document !== 'undefined') {
      document.body.appendChild(this.containerEl);
    }
  };

  componentWillUnmount = () => {
    if (typeof document !== 'undefined') {
      document.body.removeChild(this.containerEl);
    }
  };

  render() {
    return createPortal(
      <Overlay>
        <LoadingIcon />
      </Overlay>,
      this.containerEl
    );
  }
}

export default PageLoader;
