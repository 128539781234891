import React, { memo } from 'react';
import styled from 'styled-components';
import Layout from '../components/shared/Layout';
import { DarkMapBackground } from '../components/styled/Background';
import Logo from '../components/admin/Logo';
import SignInForm from '../components/admin/SignInForm';
import { viewports } from '../utils/variables';

const AdminPage = () => {
  return (
    <Layout protectedRoute={false} redirect="/dashboard" admin>
      <Base>
        <DarkMapBackground />
        <Overlay />
        <Header>
          <Logo />
        </Header>
        <SignInForm />
      </Base>
    </Layout>
  );
};

export default memo(AdminPage);

const Base = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const Overlay = styled.div`
  background: #222;
  bottom: 0;
  left: 0;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
`;

const Header = styled.header`
  left: 0;
  padding: 1.875rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
`;
