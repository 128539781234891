import styled from 'styled-components';
import { colors, fontFamilies, viewports } from '../../utils/variables';

const Button = styled.button`
  align-items: center;
  background-color: ${props => (props.secondary ? 'transparent' : colors.red)};
  border: ${props => (props.secondary ? '2px solid #b0b0b0' : '0')};
  border-radius: 1.25rem;
  color: ${props => (props.secondary ? '#b0b0b0' : '#fff')};
  cursor: pointer;
  display: flex;
  font-family: ${fontFamilies.medium};
  font-size: 0.8125rem;
  height: 2.5rem;
  letter-spacing: -0.2px;
  justify-content: center;
  outline: none;
  margin: 0 auto;
  position: relative;
  text-decoration: none;
  width: 7.5rem;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  @media ${viewports.medium} {
    font-size: 0.875rem;

    &:hover {
      background-color: ${props => (props.secondary ? 'transparent' : colors.hoverRed)};
      border: ${props => (props.secondary ? '2px solid #888888' : '0')};
      color: ${props => (props.secondary ? '#888888' : '#fff')};
    }
  }
`;

export default Button;
