import React, { memo } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import TextInput from '../inputs/TextInput';
import PasswordInput from '../inputs/PasswordInput';
import Pagination from '../shared/Pagination';
import { fontFamilies, colors, viewports } from '../../utils/variables';
import Wrapper from './Wrapper';
import ToolTip from '../shared/ToolTip';
import questionMark from '../../images/tip-icon.svg';

import { Label } from '../styled/Typography';

const Create = ({ toggleSidebar }) => (
  <Wrapper heading="Create your account" subheading="Enter your account details.">
    <div>
      <Label>
        Portal ID Number{' '}
        <ToolTip text={alumTipText} src={questionMark} alt="Portal ID">
          {/* <img src={questionMark} alt="Portal ID tooltip" /> */}
        </ToolTip>
      </Label>
    </div>

    <Field name="portalId">
      {({ field, form }) => (
        <TextInput
          {...field}
          placeholder="Enter Portal ID number"
          error={form.errors.portalId}
          touched={form.touched.portalId}
        />
      )}
    </Field>

    <Field name="email">
      {({ field, form }) => (
        <TextInput
          {...field}
          label="Email Address"
          placeholder="Enter email address"
          error={form.errors.email}
          touched={form.touched.email}
        />
      )}
    </Field>

    <Field name="password">
      {({ field, form }) => (
        <PasswordInput
          {...field}
          label={
            <>
              Password{' '}
              <ToolTip
                text={passwordTipText}
                minWidth={12}
                src={questionMark}
                alt="Password tooltip"
              >
                {/* <img src={questionMark} alt="Password tooltip" /> */}
              </ToolTip>
            </>
          }
          placeholder="Enter password"
          error={form.errors.password}
          touched={form.touched.password}
        />
      )}
    </Field>
    <Field name="confirmPassword">
      {({ field, form }) => (
        <PasswordInput
          {...field}
          label="Confirm password"
          placeholder="Enter password again"
          error={form.errors.confirmPassword}
          touched={form.touched.confirmPassword}
          height={36}
        />
      )}
    </Field>
    <Pagination nextActive />
    <SignIn>
      Already have an account?{' '}
      <SignInLink onClick={() => toggleSidebar('sign-in')}>Sign In</SignInLink>
    </SignIn>
  </Wrapper>
);

export default memo(Create);

const SignIn = styled.div`
  color: #999;
  font-size: 0.875rem;
  margin-top: 3.75rem;
  text-align: center;
`;

const SignInLink = styled.a`
  color: ${colors.blue};
  cursor: pointer;
  font-family: ${fontFamilies.bold};

  @media ${viewports.medium} {
    &:hover {
      color: ${colors.hoverBlue};
    }
  }
`;

const alumTipText = (
  <span>
    Your Portal ID is a unique number assigned to you to connect your login with our existing
    records. This ID is used as a security measure for first-time access into the ArtCenter Alumni
    Portal. To access your Portal ID, please call our office at{' '}
    <a href="tel:+16263962305">626 396-2305</a> or email{' '}
    <a href="mailto:alumni@artcenter.edu">alumni@artcenter.edu</a> (be sure to include your full
    name, major(s) and grad year(s).
  </span>
);

const passwordTipText = (
  <span>
    Password must be more than 8 characters. <br />
    Include 1 lowercase character, 1 capital character, and 1 numeric character.
  </span>
);
