import gql from 'graphql-tag';

const deleteMedia = gql`
  mutation DeleteMedia(
    $userId: Int!
    $downstreamId: String!
    $fileIds: [String!]
  ) {
    deleteMedia(
      userId: $userId
      downstreamId: $downstreamId
      fileIds: $fileIds
    ) {
      url
      id
    }
  }
`;

export default deleteMedia;
