import React from 'react';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import Button from '../styled/Button';
import forgotPassword from '../../data/mutations/forgotPassword';

const InvalidToken = ({ key, email }) => {
  return (
    <Mutation mutation={forgotPassword}>
      {(handleRequestEmail, { error }) => (
        <StyledError key={key}>
          <p>Oops! This link expired. </p>
          <RequestButton
            onClick={async (values, actions) => {
              try {
                const res = await handleRequestEmail({
                  variables: { email },
                });
                console.log(res);
                // actions.setSubmitting(false);
              } catch (err) {
                console.log(err);
                // actions.setSubmitting(false);
              }
            }}
          >
            Request a new link
          </RequestButton>
        </StyledError>
      )}
    </Mutation>
  );
};

export default InvalidToken;

const StyledError = styled.span`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
`;

const RequestButton = styled(Button)`
  &&& {
    width: auto;
    padding: 0.1rem 2.5rem;
    margin: 0.5rem 2.5rem;
  }
`;
