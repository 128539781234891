import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { colors, viewports } from '../../utils/variables';

const SearchIcon = ({
  onClick,
  highlighted,
  forceVisible,
  forceLowerRight,
}) => {
  return (
    <Base
      height="50"
      viewBox="0 0 50 50"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
      onClick={!highlighted ? onClick : () => {}}
      highlighted={highlighted}
      forceVisible={forceVisible}
      forceLowerRight={forceLowerRight}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="25" cy="25" r="25" />
        <g stroke="#fffff7" transform="translate(16 16)">
          <path
            d="m7.36363636 0c4.06676014 0 7.36363634 3.29687625 7.36363634 7.36363636 0 4.06676014-3.2968762 7.36363634-7.36363634 7.36363634-4.06676011 0-7.36363636-3.2968762-7.36363636-7.36363634 0-4.06676011 3.29687625-7.36363636 7.36363636-7.36363636z"
            strokeWidth="3"
          />
          <path
            d="m13.5 12.6 4.5185568 4.5185568"
            strokeLinecap="round"
            strokeWidth="4"
          />
        </g>
      </g>
    </Base>
  );
};

export default memo(SearchIcon);

const Base = styled.svg`
  bottom: 0.9375rem;
  cursor: pointer;
  height: 3.125rem;
  position: fixed;
  right: 0.9375rem;
  pointer-events: ${(props) => (props.highlighted ? 'none' : 'auto')};
  width: 3.125rem;
  z-index: ${(props) =>
    props.highlighted || props.forceVisible ? '100001' : '5'};

  & circle {
    fill: ${colors.linkBlack};
  }
  ${({ forceLowerRight }) =>
    !forceLowerRight
      ? css`
          @media ${viewports.medium} {
            position: absolute;
            right: 1.25rem;
            top: 1.25rem;

            &:hover {
              & circle {
                fill: ${colors.hoverBlack};
              }
            }
          }
        `
      : null}
`;
