import React, { memo } from 'react';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';
import { colors } from '../../utils/variables';
import GraphQLErrors from '../shared/GraphQLErrors';

const ServerErrorMessage = ({ visible, error, email }) => {
  const transition = useTransition(visible, null, {
    from: { h: 0, o: 0 },
    enter: { h: 52, o: 1 },
    leave: { h: 0, o: 0 },
  });
  return transition.map(
    ({ item, key, props }) =>
      item && (
        <animated.div
          key={key}
          style={{
            height: props.h.interpolate(h => `${h}px`),
            opacity: props.o.interpolate(o => o),
          }}
        >
          <Base>{GraphQLErrors(error, email)}</Base>
        </animated.div>
      )
  );
};

export default memo(ServerErrorMessage);

const Base = styled.span`
  color: ${colors.red};
  display: block;
  font-size: 1rem;
  line-height: 125%;
  overflow: hidden;
  margin-bottom: 2rem;
`;
