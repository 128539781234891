import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import { fontFamilies, viewports } from '../../utils/variables';
import DenyIcon from './DenyIcon';
import ConfirmIcon from './ConfirmIcon';
import ActionPopup from './ActionPopup';
import Button from '../styled/Button';
import EditPopup from './EditPopup';
import acceptUser from '../../data/mutations/acceptUser';
import denyUser from '../../data/mutations/denyUser';

const PendingUser = ({ user, refetch }) => {
  const [approveActive, setApproveActive] = useState(false);
  const [denyActive, setDenyActive] = useState(false);
  const [editActive, setEditActive] = useState(false);
  return (
    <Mutation mutation={acceptUser}>
      {(handleAcceptUser, { error }) => (
        <Mutation mutation={denyUser}>
          {(handleDenyUser, { error }) => (
            <>
              <Base>
                <Row>
                  <Name>
                    {user.firstName}
                    {user.preferredName ? ` "${user.preferredName}" ` : ' '}
                    {user.lastName}
                  </Name>
                  <ActionIcons>
                    <Edit secondary onClick={() => setEditActive(true)}>
                      Edit
                    </Edit>
                    <DenyIcon onClick={() => setDenyActive(true)} />
                    <ConfirmIcon onClick={() => setApproveActive(true)} />
                  </ActionIcons>
                </Row>
                <Row>
                  <Column>
                    <Label>Portal ID</Label>
                    <Info>{user.portalId}</Info>
                  </Column>
                  <Column>
                    <Label>Birthdate</Label>
                    <Info>
                      {user.birthdate.month} {user.birthdate.day},{' '}
                      {user.birthdate.year}
                    </Info>
                  </Column>
                  <Column>
                    <Label>Address</Label>
                    <Info>
                      {user.streetAddress1}
                      <br />
                      {user.city}, {user.state} {user.postalCode}
                    </Info>
                  </Column>
                  <Column>
                    <Label>Graduation Year</Label>
                    <Info>{user.graduationYear}</Info>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Label>Major</Label>
                    <Info>{user.major}</Info>
                  </Column>
                  <Column>
                    <Label>Second Major</Label>
                    <Info>{user.secondaryMajor}</Info>
                  </Column>
                  <Column />
                  <Column />
                </Row>
              </Base>
              {editActive && (
                <EditPopup
                  handleCancelClick={() => setEditActive(false)}
                  user={user}
                  refetch={refetch}
                />
              )}
              {approveActive && (
                <ActionPopup
                  heading="Approve"
                  description="Are you sure you want to approve this user?"
                  user={user}
                  handleConfirmClick={async () => {
                    await handleAcceptUser({ variables: { id: +user.id } });
                    setApproveActive(false);
                    refetch();
                  }}
                  handleCancelClick={() => setApproveActive(false)}
                />
              )}
              {denyActive && (
                <ActionPopup
                  heading="Deny"
                  description="Are you sure you want to deny this user?"
                  user={user}
                  handleConfirmClick={async () => {
                    await handleDenyUser({ variables: { id: +user.id } });
                    setDenyActive(false);
                    refetch();
                  }}
                  handleCancelClick={() => setDenyActive(false)}
                />
              )}
            </>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default memo(PendingUser);

const Base = styled.article`
  background-color: #f8f8f8;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  min-width: 220px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Row = styled.div`
  align-items: stretch;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  flex-wrap: wrap;

  &:last-of-type {
    /* justify-content: flex-start; */
    margin-bottom: 0;
  }

  &:first-of-type {
    /* justify-content: flex-start; */
    margin-bottom: 0rem;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  width: 10rem;

  /* &:nth-of-type(4) {
    width: 28%;
  } */
  /* select edit button, removed with change of location */
  /* &:nth-of-type(5) {
    padding-right: 0;
    width: 12%;
  } */
`;

const Label = styled.div`
  color: #b0b0b0;
  font-family: ${fontFamilies.bold};
  font-size: 0.6875rem;
  margin: 1rem 0 0.375rem;

  min-width: 3.25rem;
`;

const Info = styled.div`
  color: #222222;
  font-size: 0.875rem;
  line-height: 125%;
`;

const Name = styled.h2`
  color: #222222;
  font-family: ${fontFamilies.bold};
  font-size: 1.125rem;
  line-height: 1.42;
`;

const ActionIcons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 1rem;
  @media ${viewports.small} {
    width: auto;
    margin-top: 0;
  }
`;

const Edit = styled(Button)`
  font-size: 0.875rem;
  height: 1.875rem;
  /* margin: 0;
  margin-top: auto; */
  margin-left: 0;
  width: 4.375rem;
`;
