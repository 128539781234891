import { colors } from '../../utils/variables';

export const backgroundColor = activeScene => {
  if (activeScene === 0 || activeScene === 1)
    return `linear-gradient(to bottom, ${colors.offWhite}, ${colors.offWhite})`;
  if (activeScene === 2 || activeScene === 3) return 'linear-gradient(to bottom, #fffff7, #ff4cdb)';
  if (activeScene === 4 || activeScene === 5) return 'linear-gradient(to bottom, #ff4cdb, #ff4cdb)';
  if (activeScene === 6 || activeScene === 7) return 'linear-gradient(to bottom, #ff9c00, #ff4cdb)';
  if (activeScene === 8 || activeScene === 9) return 'linear-gradient(to bottom, #ff9c00, #ff9c00)';
  if (activeScene === 10 || activeScene === 11)
    return 'linear-gradient(to bottom, #ff9c00, #ffd800)';
  if (activeScene === 12 || activeScene === 13)
    return 'linear-gradient(to bottom, #ffd800, #ffd800)';
  if (activeScene === 14 || activeScene === 15)
    return 'linear-gradient(to bottom, #00c1a1, #ffd800)';
  if (activeScene === 16 || activeScene === 17)
    return 'linear-gradient(to bottom, #00c1a1, #00c1a1)';
  if (activeScene === 18 || activeScene === 19)
    return 'linear-gradient(to bottom, #00c1a1, #338fff)';
  if (activeScene === 20 || activeScene === 21)
    return 'linear-gradient(to bottom, #338fff, #338fff)';
  if (activeScene === 22 || activeScene === 23)
    return 'linear-gradient(to bottom, #fffff7, #338fff)';
};

export const landingLocations = activeScene => {
  if (activeScene >= 4 && activeScene <= 7) return 'Seoul, South Korea';
  if (activeScene >= 8 && activeScene <= 11) return 'Amsterdam, Netherlands';
  if (activeScene >= 12 && activeScene <= 15) return 'San Francisco, United States';
  if (activeScene >= 16 && activeScene <= 19) return 'Dubai, United Arab Emirates';
  if (activeScene >= 20 && activeScene <= 23) return 'Zürich, Switzerland';
  return undefined;
};
