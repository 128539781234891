export const defaults = {
  pageLoading: false,
};

export const resolvers = {
  Mutation: {
    togglePageLoading: (_, { pageLoading }, { cache }) => {
      const data = { pageLoading };
      cache.writeData({ data });
      return pageLoading;
    },
  },
};
