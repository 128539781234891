import gql from 'graphql-tag';

const getUser = gql`
  query User($id: Int) {
    user(id: $id) {
      id
      email
      firstName
      lastName
      preferredName
      graduationYear
      major
      secondaryMajor
      streetAddress1
      streetAddress2
      city
      state
      postalCode
      country
      currentCompany
      currentJobTitle
      currentIndustry
      website
      linkedIn
      instagram
      facebook
      twitter
      profileImage
      bio
      cityId
      stateId
      countryId
      continentId
      graduationYearId
      majorId
      industryId
      visibleFields
      accepted
      firstTime
      downstreamId
      verifyAddress
    }
  }
`;

export default getUser;
