import React from 'react';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { ApolloProvider } from 'react-apollo';
import { ApolloLink } from 'apollo-link';
import { withClientState } from 'apollo-link-state';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import Cookies from 'universal-cookie';
import { uncrunch } from 'graphql-crunch';

import { defaults, resolvers } from './src/data/resolvers';
import typeDefs from './src/data/typeDefs';
import metadata from './src/metadata.json';

const cache = new InMemoryCache();

const authLink = setContext((_, { headers }) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  const adminToken = cookies.get('adminToken');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      adminAuthorization: adminToken ? `Bearer ${adminToken}` : '',
    },
  };
});

const uncruncher = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    response.data = uncrunch(response.data);
    return response;
  })
);

// const newLink = new HttpLink();

const stateLink = withClientState({
  cache,
  defaults,
  resolvers,
  typeDefs,
});

const errorLink = onError(({ graphQLErrors, networkError, forward }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const uploadLink = createUploadLink({
  uri: `${process.env.API_URL}?crunch=1`,
  credentials: 'same-origin',
});

// setup for @apollo/client 3.0+
// const link = ApolloLink.from([authLink, errorLink, uploadLink]);
// const client = new ApolloClient({ link, cache, resolvers, typeDefs });
// cache.writeData({ data: { ...defaults } });

// setup for apollo-client
const link = ApolloLink.from([
  // uncruncher,
  authLink,
  stateLink,
  errorLink,
  uploadLink,
]);
const client = new ApolloClient({ link, cache, resolvers: {}, version: metadata.buildRevision });

export default ({ element }) => <ApolloProvider client={client}>{element}</ApolloProvider>;
