import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import TextInput from '../inputs/TextInput';
import Pagination from '../shared/Pagination';
import SelectInput from '../inputs/SelectInput';
import { Label } from '../styled/Typography';
import Wrapper from './Wrapper';
import { viewports } from '../../utils/variables';
import {
  birthdateMonths,
  getBirthdateDays,
  birthdateYears,
  graduationYears,
  majors,
} from '../../utils/selectOptions';

const Personal = () => {
  const [sortedMajors, setSortedMajors] = useState(majors);
  const sortMajors = i => {
    const input = i.toLowerCase();
    let newMajors = [...majors].filter(m =>
      m.label.toLowerCase().includes(input)
    );

    newMajors.sort((a_, b_) => {
      const a = a_.label.toLowerCase();
      const b = b_.label.toLowerCase();
      return a.indexOf(input) - b.indexOf(input);
    });

    setSortedMajors(newMajors);
  };
  return (
    <Wrapper
      heading="Personal details"
      subheading="Enter details to confirm alumni status."
    >
      <Field name="firstName">
        {({ field, form }) => (
          <TextInput
            {...field}
            label="First Name"
            placeholder="Enter first name"
            error={form.errors.firstName}
            touched={form.touched.firstName}
          />
        )}
      </Field>
      <Field name="lastName">
        {({ field, form }) => (
          <TextInput
            {...field}
            label="Last Name"
            placeholder="Enter last name"
            error={form.errors.lastName}
            touched={form.touched.lastName}
          />
        )}
      </Field>
      <Field name="preferredName">
        {({ field, form }) => (
          <TextInput
            {...field}
            label="Preferred First Name (Optional)"
            placeholder="Enter preferred first name "
            error={form.errors.preferredName}
            touched={form.touched.preferredName}
          />
        )}
      </Field>
      <Label>Birthdate</Label>
      <SelectContainer>
        <Field name="birthdateMonth">
          {({ field, form }) => (
            <SelectInput
              {...field}
              form={form}
              placeholder="Month"
              width="8.5rem"
              options={birthdateMonths}
              error={form.errors.birthdateMonth}
              touched={form.touched.birthdateMonth}
            />
          )}
        </Field>
        <Field name="birthdateDay">
          {({ field, form }) => (
            <SelectInput
              {...field}
              form={form}
              placeholder="Day"
              width="5.25rem"
              options={getBirthdateDays(form.values.birthdateMonth)}
              error={form.errors.birthdateDay}
              touched={form.touched.birthdateDay}
            />
          )}
        </Field>
        <Field name="birthdateYear">
          {({ field, form }) => (
            <SelectInput
              {...field}
              form={form}
              placeholder="Year"
              width="5.875rem"
              options={birthdateYears}
              error={form.errors.birthdateYear}
              touched={form.touched.birthdateYear}
            />
          )}
        </Field>
      </SelectContainer>
      <Field name="graduationYear">
        {({ field, form }) => (
          <SelectInput
            {...field}
            form={form}
            label="Graduation Year"
            placeholder="Select graduation year"
            options={graduationYears}
            error={form.errors.graduationYear}
            touched={form.touched.graduationYear}
          />
        )}
      </Field>
      <Field name="major">
        {({ field, form }) => (
          <SelectInput
            {...field}
            form={form}
            label="Major"
            placeholder="Select major"
            error={form.errors.major}
            touched={form.touched.major}
            options={sortedMajors}
            handleInputChange={sortMajors}
          />
        )}
      </Field>
      <Field name="secondaryMajor">
        {({ field, form }) => (
          <SelectInput
            {...field}
            form={form}
            label="Secondary Major (Optional)"
            placeholder="Select major"
            error={form.errors.secondaryMajor}
            touched={form.touched.secondaryMajor}
            options={sortedMajors}
            handleInputChange={sortMajors}
          />
        )}
      </Field>
      <Pagination prevActive nextActive />
    </Wrapper>
  );
};

export default memo(Personal);

const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
