import React, { memo, useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import uniqBy from 'lodash.uniqby';
import AlumLink from './AlumLink';
import LoadingIcon from '../shared/LoadingIcon';
import { viewports, fontFamilies } from '../../utils/variables';
import { AlumniFetcherContext } from './AlumniFetcher';
import { ActiveFiltersContext } from './ActiveFilters';

const AlumniList = ({ resetFilters, filtersActive, setSidebar }) => {
  const scrollBarRef = useRef(undefined);
  const {
    alumni,
    activeScreen,
    setActiveScreen,
    setActiveAlum,
    numAlumni,
    activeAlum,
    fetchMore,
    locationVariables,
    searchValue,
    loading,
  } = useContext(AlumniFetcherContext);

  const { activeFilters } = useContext(ActiveFiltersContext);

  const [currentPage, setCurrentPage] = useState(0);
  const [isRefetching, setIsRefetching] = useState(false);

  const handleScroll = ({ top }) => {
    const hasNextPage = alumni.length < numAlumni;

    if (top > 0.98 && hasNextPage) {
      setIsRefetching(true);
      fetchMore({
        variables: {
          level: +locationVariables.level,
          locationId: +locationVariables.id,
          searchValue,
          activeFilters,
          page: currentPage + 1,
          perPage: 50,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          setCurrentPage(currentPage + 1);
          setIsRefetching(false);
          const newUserArray = uniqBy(
            [...prev.users.users, ...fetchMoreResult.users.users],
            (_) => _.id
          );
          return {
            ...prev,
            users: {
              total: fetchMoreResult.users.total,
              users: newUserArray,
              __typename: prev.users.__typename,
            },
          };
        },
      });
    }
  };
  const shouldSearch = searchValue.length <= 10 || (searchValue.length > 10 && numAlumni < 20);
  // console.log(shouldSearch, searchValue, searchValue.length);
  return (
    <Base>
      {!alumni || loading ? (
        <LoadingIcon />
      ) : (
        <>
          <ResultsContainer>
            <Results>
              <strong>Results:</strong> {shouldSearch ? numAlumni : 0} Alumni
            </Results>
            {filtersActive && <ResetButton onClick={resetFilters}>Reset Filters</ResetButton>}
          </ResultsContainer>
          {numAlumni !== 0 && shouldSearch ? (
            <Scrollbars autoHide universal onScrollFrame={handleScroll} ref={scrollBarRef}>
              <AlumniWrapper>
                <Alumni>
                  {alumni.map((alum) => (
                    <AlumLink
                      key={alum.id}
                      alum={alum}
                      activeScreen={activeScreen}
                      setActiveScreen={setActiveScreen}
                      setActiveAlum={setActiveAlum}
                      activeAlum={activeAlum}
                      setSidebar={setSidebar}
                    />
                  ))}
                </Alumni>
              </AlumniWrapper>
              {isRefetching && <LazyLoadingIcon />}
            </Scrollbars>
          ) : (
            <NoAlumniMessage>No alumni found. Try changing your search or filters.</NoAlumniMessage>
          )}
        </>
      )}
    </Base>
  );
};

export default memo(AlumniList);

const Base = styled.div`
  border-top: 1px solid #444444;
  flex: 1;
  margin-top: 1.25rem;
  padding-top: 1rem;
`;

const ResultsContainer = styled.div`
  align-items: center;
  color: #777777;
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  padding: 0 1.875rem;
`;

const Results = styled.div`
  & strong {
    font-family: ${fontFamilies.bold};
  }
`;

const ResetButton = styled.div`
  cursor: pointer;

  @media ${viewports.medium} {
    &:hover {
      color: #444;
    }
  }
`;

const AlumniWrapper = styled.div`
  position: relative;

  &:after {
    background: linear-gradient(to bottom, rgba(34, 34, 34, 0) 0%, rgba(34, 34, 34, 1) 100%);
    bottom: 0;
    content: '';
    display: none;
    height: 6.25rem;
    left: 0;
    pointer-events: none;
    /* position: fixed; */
    right: 0;

    @media ${viewports.medium} {
      display: block;
    }
  }
`;

const Alumni = styled.div`
  padding-bottom: 6.25rem;

  @media ${viewports.medium} {
    padding-bottom: 3.25rem;
  }
`;

const NoAlumniMessage = styled.p`
  color: #777777;
  font-size: 0.875rem;
  line-height: 133%;
  margin-bottom: 1.25rem;
  padding: 0 1.875rem;
`;

const LazyLoadingIcon = styled(LoadingIcon)`
  bottom: 2rem;
  left: 50%;
  position: fixed;
  top: auto;
  transform: translateX(-50%);
`;
