import React, { memo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors, viewports } from '../../utils/variables';
import { Label } from '../styled/Typography';
import Input from '../styled/Input';
import ErrorMessage from './ErrorMessage';
import Visible from '../shared/Visible';

const PasswordInput = ({
  label,
  name,
  placeholder,
  forgotLink,
  value,
  onChange,
  error,
  touched,
  height,
  onForgotClick,
}) => {
  const [inputType, setInputType] = useState("password");

  const toggleShowPassword = () => {
    (inputType == "password") ? setInputType("text") : setInputType("password");
  };

  return (
    <Base>
      {label && <Label htmlFor={name}>{label}</Label>}
      {forgotLink && (
        <ForgotPassword onClick={onForgotClick}>Forgot Password?</ForgotPassword>
      )}
      <InputWrapper>
        <Input
          type={inputType}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          error={touched && error}
        />
        <VisibleStyled onClick={toggleShowPassword} />
      </InputWrapper>

      <ErrorMessage visible={touched && error} error={error} height={height} />
    </Base>
  );
}

export default memo(PasswordInput);

const Base = styled.div`
  margin-bottom: 1.875rem;
  position: relative;
`;

const ForgotPassword = styled.span`
  color: ${colors.blue};
  cursor: pointer;
  font-size: 0.75rem;
  position: absolute;
  right: 0;
  top: 0;

  @media ${viewports.medium} {
    &:hover {
      color: ${colors.hoverBlue};
    }
  }
`;
const InputWrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 14px;
`;

const VisibleStyled = styled(Visible)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  &:hover {
    cursor: pointer;
  }
`;
