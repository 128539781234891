import React, { memo } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { fontFamilies, colors } from '../../utils/variables';

const ProgressNavIndicator = ({ active, completed, screenIndex }) => {
  const spring = useSpring({
    from: { o: 0, backgroundColor: '#ddd' },
    to: {
      o: active ? 1 : 0,
      background: completed || active ? colors.red : '#ddd',
    },
  });

  return (
    <Base>
      <animated.div
        style={{
          opacity: spring.o.interpolate(o => o),
        }}
      >
        <ActiveIndicator />
      </animated.div>
      <animated.div
        style={{
          background: spring.background,
          alignItems: 'center',
          borderRadius: '50%',
          color: '#fff',
          display: 'flex',
          fontFamily: fontFamilies.bold,
          fontSize: '0.75rem',
          height: '1.5rem',
          justifyContent: 'center',
          position: 'relative',
          width: '1.5rem',
        }}
      >
        {screenIndex + 1}
      </animated.div>
    </Base>
  );
};

export default memo(ProgressNavIndicator);

const Base = styled.div`
  position: relative;
  z-index: 0;
`;

const ActiveIndicator = styled.div`
  background-color: rgba(252, 70, 0, 0.2);
  border-radius: 50%;
  height: 2.125rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 2.125rem;
  z-index: -1;
`;
