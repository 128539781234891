import React, { memo } from 'react';
import { useTransition, animated } from 'react-spring';

const GridTransitionContainer = ({ children, condition, flow, ignoreScale }) => {
  const gridTransition = useTransition(condition, null, {
    from: {
      s: flow === 'forward' ? 0 : 1.5,
      o: 0,
    },
    enter: { s: 1, o: 1 },
    leave: {
      s: flow === 'forward' ? 1.5 : 0,
      o: 0,
    },
  });

  return gridTransition.map(
    ({ item, props, key }) =>
      item && (
        <animated.div
          key={key}
          style={{
            bottom: 0,
            left: 0,
            position: 'fixed',
            right: 0,
            top: 0,
            transform: ignoreScale ? 1 : props.s.interpolate(s => `scale(${s})`),
            opacity: props.o.interpolate(o => o),
          }}
        >
          {children}
        </animated.div>
      )
  );
};

export default memo(GridTransitionContainer);
