import React, { memo } from 'react';
import styled from 'styled-components';
import { Label } from '../styled/Typography';
import Input from '../styled/Input';
import Toggle from '../shared/Toggle';
import ErrorMessage from './ErrorMessage';
import { viewports } from '../../utils/variables';

const TextInput = ({
  label,
  name,
  placeholder,
  toggle,
  value,
  onChange,
  className,
  error,
  touched,
  form,
  toggled,
  disabled,
  autoComplete = 'on',
  tabIndex,
}) => {
  // if (error) debugger;
  return (
    <Base>
      {label && <Label htmlFor={name}>{label}</Label>}
      <InputWrapper className={className}>
        <Input
          type="text"
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          error={touched && error}
          disabled={disabled}
          autoComplete={autoComplete}
          tabIndex={tabIndex}
        />
        {toggle && <Toggle form={form} toggled={toggled} name={name} />}
      </InputWrapper>
      <ErrorMessage visible={(touched && error) || error} error={error} />
    </Base>
  );
};

export default memo(TextInput);

const Base = styled.div`
  margin-bottom: 1.875rem;
  @media ${viewports.medium} {
    display: flex;
  }

  @media ${viewports.smallMedium} {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  }
  @media ${viewports.small} {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  }
`;

const InputWrapper = styled.div`
  align-items: center;
  position: relative;

  @media ${viewports.medium} {
    display: flex;
  }
`;
