import React, { memo } from 'react';
import styled, { keyframes } from 'styled-components';

const LoadingIcon = ({ className }) => (
  <Base className={className}>
    <div />
    <div />
    <div />
    <div />
  </Base>
);

export default memo(LoadingIcon);

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Base = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;

  & div {
    animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 3px solid #fff;
    border-radius: 50%;
    border-color: #fff transparent transparent transparent;
    height: 1.5rem;
    position: absolute;
    width: 1.5rem;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`;
