import React, { memo } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { viewports } from '../../utils/variables';

const Toggle = ({ className, form, toggled, name }) => {
  const bgColorSpring = useSpring({
    backgroundColor: toggled ? '#339FFF' : '#DDDDDD',
  });

  const toggleSpring = useSpring({
    x: toggled ? 23 : 3,
  });

  return (
    <Base className={className}>
      <animated.div
        style={{
          ...bgColorSpring,
          borderRadius: '0.8125rem',
          cursor: 'pointer',
          height: '1.625rem',
          position: 'relative',
          width: '2.875rem',
        }}
        onClick={() =>
          form.setFieldValue('visibleFields', {
            ...form.values.visibleFields,
            [name]: !toggled,
          })
        }
      >
        <animated.div
          style={{
            transform: toggleSpring.x.interpolate(
              x => `translate3d(${x}px, -50%, 0)`
            ),
            background: '#fff',
            borderRadius: '50%',
            height: '1.25rem',
            position: 'absolute',
            top: '50%',
            width: '1.25rem',
          }}
        />
      </animated.div>
      <Text>Show</Text>
    </Base>
  );
};

export default memo(Toggle);

const Base = styled.div`
  align-items: center;
  display: flex;
  margin-top: 0.375rem;

  @media ${viewports.medium} {
    margin-left: 1.25rem;
    margin-top: 0;
  }
`;

const Text = styled.span`
  color: #999999;
  font-size: 0.875rem;
  margin-left: 0.625rem;
`;
