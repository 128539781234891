import React, { memo } from 'react';
import { animated, useSpring } from 'react-spring';

const IndicatorCircle = ({ opacity, visible, radius, delay }) => {
  const spring = useSpring({
    from: { o: +opacity, s: 0 },
    to: { o: visible ? +opacity : 0, s: 1 },
    delay,
    config: { tension: 40, friction: 40 },
  });

  return (
    <animated.circle
      style={{
        transformOrigin: 'center',
        opacity: spring.o.interpolate(o => o),
        transform: spring.s.interpolate(s => `scale(${s})`),
      }}
      cx="50"
      cy="50"
      r={radius}
    />
  );
};

export default memo(IndicatorCircle);
