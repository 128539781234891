const typeDefs = `
  type Query {
    pageLoading: Boolean
  }

  type Mutation {
    togglePageLoading(pageLoading: Boolean): Boolean
  }
`;

export default typeDefs;
