import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import Overlay from '../styled/Overlay';
import { viewports } from '../../utils/variables';
import { Heading, BodyText } from '../styled/Typography';
import CloseButton from './CloseButton';

class ErrorModal extends PureComponent {
  containerEl = null;

  componentDidMount = () => {
    if (typeof document !== 'undefined') {
      this.containerEl = document.createElement('div');
      document.body.appendChild(this.containerEl);
    }
  };

  componentWillUnmount = () => {
    if (typeof document !== 'undefined') {
      document.body.removeChild(this.containerEl);
    }
  };

  render() {
    const { heading, bodyText, onClose } = this.props;
    return createPortal(
      <Overlay>
        <Modal>
          {onClose && <Close onClick={onClose} />}
          <Heading>{heading}</Heading>
          <BodyText>{bodyText}</BodyText>
        </Modal>
      </Overlay>,
      this.containerEl
    );
  }
}

export default ErrorModal;

const Modal = styled.div`
  background-color: #fff;
  bottom: 0;
  box-shadow: 8px 8px 20px 0 rgba(34, 34, 34, 0.06);
  display: flex;
  flex-direction: column;
  left: 0;
  overflow-y: auto;
  padding: 5.25rem 1.875rem;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;

  @media ${viewports.medium} {
    display: block;

    overflow-y: visible;
    padding: 3.125rem 3.125rem 0.625rem;
    position: relative;
    width: 40rem;
  }
`;

const Close = styled(CloseButton)`
  position: absolute;
  right: 1.9375rem;
  top: 1.9375rem;
`;
