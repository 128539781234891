import React, { memo } from 'react';
import styled from 'styled-components';
import LocationWrapper from './LocationWrapper';
import World from './World';
import {
  GridWorld,
  GridContinent,
  GridCountry,
  GridState,
  GridCity,
} from '../styled/Grid';
import { DarkMapBackground } from '../styled/Background';
import GridTransitionContainer from './GridTransitionContainer';
import { viewports } from '../../utils/variables';

const LocationRouter = ({
  numAlumni,
  loading,
  level,
  activeScreen,
  flow,
  locations,
  activeLocation,
  sidebar,
}) => (
  <Base sidebar={sidebar}>
    {activeScreen !== 'setup' ? (
      <>
        <GridTransitionContainer
          condition={level === 0}
          flow={flow}
          ignoreScale={level === 0 || level === 1}
        >
          <GridWorld />
        </GridTransitionContainer>
        <GridTransitionContainer
          condition={level === 1}
          flow={flow}
          ignoreScale={
            (flow === 'backward' && level === 0) ||
            (flow === 'forward' && level === 2)
          }
        >
          <GridContinent />
        </GridTransitionContainer>
        <GridTransitionContainer
          condition={level === 2}
          flow={flow}
          ignoreScale={flow === 'forward' && level === 3}
        >
          <GridCountry />
        </GridTransitionContainer>
        <GridTransitionContainer
          condition={level === 3}
          flow={flow}
          ignoreScale={flow === 'forward' && level === 4}
        >
          <GridState />
        </GridTransitionContainer>
        <GridTransitionContainer condition={level === 4} flow={flow}>
          <GridCity />
        </GridTransitionContainer>
        {level === 0 ? (
          <World
            numAlumni={numAlumni}
            loading={loading}
            locations={locations}
            sidebar={sidebar}
          />
        ) : (
          <LocationWrapper
            activeLocation={activeLocation}
            numAlumni={numAlumni}
            loading={loading}
          />
        )}
      </>
    ) : (
      <DarkMapBackground />
    )}
  </Base>
);

export default memo(LocationRouter);

const Base = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  // position: ${(props) => (props.sidebar ? 'fixed' : 'relative')};
  position: 'relative';
  right: 0;
  top: 0;

  @media ${viewports.medium} {
    display: block;
    height: 100vh;
    position: relative;
  }
`;
