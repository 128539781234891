import React, { useState } from 'react';
import styled from 'styled-components';
import Visibility from '../icons/Visibility';
import VisibilityOff from '../icons/VisibilityOff';

const Visible = (props) => {
  const { onClick, className } = props;
  const [toggle, useToggle] = useState(false);

  const handleOnClick = (event) => {
    useToggle(!toggle);
    onClick(event);
  };

  return (
    <Base type="button" className={className} onClick={(event) => handleOnClick(event)}>
      <Visibility style={{ display: toggle ? 'block' : 'none' }} />
      <VisibilityOff style={{ display: toggle ? 'none' : 'block ' }} />
    </Base>
  );
};

export default Visible;

const Base = styled.i``;
