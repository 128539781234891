import React, { memo, useEffect } from 'react';
import SectionHeading from './SectionHeading';
import Infographic from './Infographic';
import PageLoader from '../shared/PageLoader';

const LocationWrapper = ({ activeLocation, numAlumni, loading }) => {
  // useEffect(() => {
  //   // console.log('test');
  //   // console.log(activeLocation, numAlumni, loading);
  // }, []);
  if (!loading) {
    // console.log('info returned', activeLocation);

    return (
      <>
        <SectionHeading sectionName={activeLocation.name} numAlumni={numAlumni} loading={loading} />
        <Infographic />
      </>
    );
  }
  return <PageLoader />;
};

export default memo(LocationWrapper);
