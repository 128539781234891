import React, { memo, useContext, useEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import styled from 'styled-components';
import { titleCase } from 'title-case';
import { animated, useSpring } from 'react-spring';
import { Scrollbars } from 'react-custom-scrollbars';
import CloseButton from '../shared/CloseButton';
import { colors, fontFamilies, viewports } from '../../utils/variables';
import ExploreSidebarNav from './ExploreSidebarNav';
import { AlumniFetcherContext } from './AlumniFetcher';
import LoadingIcon from '../shared/LoadingIcon';

const ExploreSidebar = ({ visible, toggleSidebar }) => {
  const { fetchLocation, activeLocation, locations, level, loading } = useContext(
    AlumniFetcherContext
  );

  const sidebarRef = useRef(null);
  const currWidth = sidebarRef.current ? sidebarRef.current.clientWidth : 750;
  const springOpen = {
    from: { marginLeft: `${-currWidth}px` },
    to: { marginLeft: '0px' },
  };

  const springClosed = {
    from: { marginLeft: '0px' },
    to: { marginLeft: `${-currWidth}px` },
  };
  const [testSpring, setTestSpring] = useSpring(() => {
    return springOpen;
  });

  const [{ transform }, setSpring] = useSpring(() => {
    return {
      transform: visible ? 'translateX(0)' : 'translateX(-100%)',
    };
  });
  useEffect(() => {
    if (visible) {
      setSpring({ transform: 'translateX(0)' });
      setTestSpring(springOpen);
      // disableBodyScroll(sidebarRef.current);
    } else {
      setSpring({ transform: 'translateX(-100%)' });
      setTestSpring(springClosed);
      enableBodyScroll(sidebarRef.current);
    }
    // return () => clearAllBodyScrollLocks();
  });
  // if (!visible) {
  //   return setSpring({ transform: 'translateX(-100%)' });
  // }
  return (
    <Base style={testSpring} ref={sidebarRef}>
      <ExploreSidebarNav
        activeLocation={activeLocation}
        fetchLocation={fetchLocation}
        loading={loading}
        type={activeLocation.type}
        level={level}
      />
      <CloseLink onClick={() => toggleSidebar(undefined)} />
      <Scrollbars autoHide universal>
        <LocationsWrapper>
          {loading ? (
            <ExploreLoadingIcon />
          ) : (
            <Locations>
              {locations
                .sort((a, b) => a.name > b.name)
                .map((location) => (
                  <Location
                    key={location.id}
                    inactive={activeLocation.type === 'city' && +activeLocation.id === +location.id}
                    onClick={() => {
                      fetchLocation({
                        level: location.type !== 'city' ? level + 1 : level,
                        id: +location.id,
                      });
                      if (location.type === 'city') toggleSidebar(undefined);
                    }}
                  >
                    {titleCase(location.name.toLowerCase())}
                  </Location>
                ))}
            </Locations>
          )}
        </LocationsWrapper>
      </Scrollbars>
    </Base>
  );
};

export default memo(ExploreSidebar);

const Base = styled(animated.div)`
  background-color: ${colors.red};
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  padding: 1.6875rem 1.875rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
  //doesn't change anything but should be necessary, add as part of AC-161
  -webkit-overflow-scrolling: touch;
  @media ${viewports.medium} {
    position: absolute;
    width: 20rem;
  }
`;

const CloseLink = styled(CloseButton)`
  fill: #fffff7;
  position: absolute;
  right: 1.9375rem;
  top: 1.9375rem;

  @media ${viewports.medium} {
    &:hover {
      fill: #ffd1b7;
    }
  }
`;

const LocationsWrapper = styled.div`
  flex: 1;
  margin-top: 1.875rem;
  position: relative;

  &:after {
    background: linear-gradient(to bottom, rgba(252, 70, 0, 0) 0%, rgba(252, 70, 0, 1) 100%);
    bottom: 0;
    content: '';
    height: 6.25rem;
    left: 0;
    /* position: fixed; */
    right: 0;
  }
`;

const Locations = styled.div`
  padding-bottom: 3.25rem;
  -webkit-overflow-scrolling: touch;
`;

const Location = styled.a`
  color: ${(props) => (props.inactive ? '#ffd1b7' : '#fffff7')};
  cursor: pointer;
  display: block;
  font-family: ${fontFamilies.bold};
  font-size: 2rem;
  margin-bottom: 0.75rem;
  pointer-events: ${(props) => (props.inactive ? 'none' : 'auto')};

  @media ${viewports.medium} {
    &:hover {
      color: #ffd1b7;
    }
  }
`;

const ExploreLoadingIcon = styled(LoadingIcon)`
  margin-top: 1rem;
`;
