import Cookies from 'universal-cookie';
import { navigate } from 'gatsby';

export const authFromLocalCookie = (admin) => {
  const cookies = new Cookies();
  const authToken = admin ? cookies.get('adminToken') : cookies.get('token');
  return authToken || false;
};

export const userFromLocalCookie = () => {
  const cookies = new Cookies();
  const userId = cookies.get('userId');
  return userId || false;
};

export const setCookies = ({ token, user }) => {
  const cookies = new Cookies();
  cookies.set('token', token, { path: '/', maxAge: 86400 });
  cookies.set('userId', user.id, { path: '/', maxAge: 86400 });
};

export const setAdminCookies = ({ token }) => {
  const cookies = new Cookies();
  cookies.set('adminToken', token, { path: '/', maxAge: 86400 });
};

export const clearCookies = () => {
  const cookies = new Cookies();
  cookies.remove('token', { path: '/' });
  cookies.remove('userId', { path: '/' });
};

export const clearAdminCookies = () => {
  const cookies = new Cookies();
  cookies.remove('adminToken', { path: '/' });
};

export const logout = () => {
  clearCookies();
  typeof window !== `undefined` ? navigate('/') : null;
  return false;
};

export const logoutAdmin = () => {
  clearAdminCookies();
  navigate('/admin');
};
