import React, { memo, lazy, Suspense, useState } from 'react';
import { Query } from 'react-apollo';
import Layout from '../components/shared/Layout';
import AlumniFetcher, {
  AlumniFetcherContext,
} from '../components/explore/AlumniFetcher';
import AlumProfile from '../components/alum-profile/AlumProfile';
import ExploreLayout from '../components/explore/ExploreLayout';
import PageLoader from '../components/shared/PageLoader';
import { userFromLocalCookie, logout } from '../utils/auth';
import ActiveLocation from '../components/explore/ActiveLocation';
import ActiveFilters from '../components/explore/ActiveFilters';
import getUser from '../data/queries/getUser';
import getPageLoading from '../data/queries/getPageLoading';
// import LoadingIcon from '../shared/LoadingIcon';
import metadata from '../metadata.json';


const ProfileSetup = lazy(() =>
  import('../components/profile-setup/ProfileSetup')
);
const ProfileSettings = lazy(() =>
  import('../components/profile-settings/ProfileSettings')
);

const Tour = lazy(() => import('../components/tour/Tour'));

const ExplorePage = () => {
  const [tourScreenIndex, setTourScreenIndex] = useState(0);
  const [sidebar, setSidebar] = useState(undefined);
  console.log(`Current build: ${metadata.buildRevision}`);

  return (
    <Layout title="ArtCenter - Explore" protectedRoute redirect="/">
      <Query
        query={getUser}
        variables={{ id: +userFromLocalCookie() }}
        onCompleted={(data) => {
          if (!data.user) logout();
        }}
      >
        {({ data, loading, refetch }) => {
          // {(res) => {

          let user;
          if (data) user = data.user;

          return !loading && user ? (
            <ActiveFilters>
              <ActiveLocation>
                <AlumniFetcher firstTime={user.firstTime} user={user}>
                  <AlumniFetcherContext.Consumer>
                    {({
                      setActiveScreen,
                      setActiveAlum,
                      activeScreen,
                      activeAlum,
                    }) => (
                      <>
                        <ExploreLayout
                          tourScreenIndex={tourScreenIndex}
                          sidebar={sidebar}
                          setSidebar={setSidebar}
                        />
                        <Suspense fallback={<PageLoader />}>
                          {activeScreen === 'setup' && (
                            <ProfileSetup
                              setActiveScreen={setActiveScreen}
                              user={user}
                              verifyAddress={
                                user.verifyAddress ||
                                !(
                                  user.cityId &&
                                  user.countryId &&
                                  user.continentId
                                )
                              }
                              id={user.id}
                              refetch={refetch}
                            />
                          )}
                        </Suspense>
                        {activeScreen === 'alum' && (
                          <AlumProfile
                            alum={activeAlum}
                            sidebar={sidebar}
                            setActiveScreen={setActiveScreen}
                            setActiveAlum={setActiveAlum}
                          />
                        )}
                        <Suspense fallback={<PageLoader />}>
                          {(activeScreen === 'profile-settings' ||
                            activeScreen === 'account-settings' ||
                            activeScreen === 'alumni-wall') && (
                            <ProfileSettings
                              setActiveScreen={setActiveScreen}
                              activeScreen={activeScreen}
                              user={user}
                            />
                          )}
                        </Suspense>
                        <Suspense fallback={<PageLoader />}>
                          {activeScreen === 'tour' && (
                            <Tour
                              setActiveScreen={setActiveScreen}
                              tourScreenIndex={tourScreenIndex}
                              setTourScreenIndex={setTourScreenIndex}
                            />
                          )}
                        </Suspense>
                      </>
                    )}
                  </AlumniFetcherContext.Consumer>
                </AlumniFetcher>
              </ActiveLocation>
            </ActiveFilters>
          ) : (
            <PageLoader />
          );
        }}
      </Query>
      <Query query={getPageLoading} pollInterval={200}>
        {({ data }) => {
          let pageLoading;
          if (data) pageLoading = data.pageLoading;
          return pageLoading && <PageLoader />;
        }}
      </Query>
    </Layout>
  );
};

export default memo(ExplorePage);
