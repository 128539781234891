import styled from 'styled-components';
import { colors } from '../../utils/variables';
import map from '../../images/map.svg';
import mapDark from '../../images/map-dark.svg';

export const LandingBackground = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const CreamSolid = styled(LandingBackground)`
  background-color: ${colors.offWhite};
  position: fixed;
`;

export const MapBackground = styled(LandingBackground)`
  background-image: url(${map});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90% auto;
  opacity: 0.9;
`;

export const DarkMapBackground = styled(LandingBackground)`
  background-image: url(${mapDark});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90% auto;
`;
