import React, { memo } from 'react';
import styled from 'styled-components';
import Layout from '../components/shared/Layout';
import Overlay from '../components/styled/Overlay';
import { viewports } from '../utils/variables';
import { Heading, BodyText } from '../components/styled/Typography';

const NotFoundPage = () => (
  <Layout>
    <Overlay>
      <Modal>
        <Heading>Not found.</Heading>
        <BodyText>The page you are looking for does not exist.</BodyText>
      </Modal>
    </Overlay>
    ,
  </Layout>
);

export default memo(NotFoundPage);

const Modal = styled.div`
  background-color: #fff;
  bottom: 0;
  box-shadow: 8px 8px 20px 0 rgba(34, 34, 34, 0.06);
  display: flex;
  flex-direction: column;
  left: 0;
  overflow-y: auto;
  padding: 5.25rem 1.875rem;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;

  @media ${viewports.medium} {
    display: block;

    overflow-y: visible;
    padding: 3.125rem 3.125rem 0.625rem;
    position: relative;
    width: 40rem;
  }
`;
