import React, { memo } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { BodyText } from '../styled/Typography';
import Button from '../styled/Button';
import { fontFamilies } from '../../utils/variables';

const Success = () => (
  <Base>
    <Heading>Success.</Heading>
    <BodyText>You've successfully reset your password.</BodyText>
    <Button
      onClick={() => {
        navigate('/', { state: { sidebar: 'sign-in' } });
      }}
    >
      Sign In
    </Button>
  </Base>
);

export default memo(Success);

const Base = styled.div`
  padding: 5rem 0 3.75rem;
  position: relative;
  text-align: center;
`;

const Heading = styled.h2`
  font-size: 3rem;
  font-family: ${fontFamilies.bold};
  letter-spacing: -0.43px;
  line-height: 54px;
  margin-bottom: 1.25rem;
`;
