import React, { memo } from 'react';
import styled from 'styled-components';
import { colors, viewports } from '../../utils/variables';

const SocialIcon = ({ platform, url }) =>
  ({
    facebook: (
      <Base href={url} target="_blank" rel="noopener noreferrer">
        <Svg height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m24.285 20h-2.785v10h-4.17v-10h-2v-3.5h2v-2.31c0-1.635.775-4.19 4.17-4.19h3.075v3.43h-2.21c-.4665-.016-.858.349-.874.815-.002.052.001.1035.009.155v2.1h3.1495zm-4.285-20c-11.0455 0-20 8.9545-20 20 0 11.046 8.9545 20 20 20s20-8.954 20-20c0-11.0455-8.9545-20-20-20z"
            fillRule="evenodd"
          />
        </Svg>
      </Base>
    ),
    twitter: (
      <Base href={url} target="_blank" rel="noopener noreferrer">
        <Svg height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m27.955 15.925v.53c0 5.42-4.125 11.675-11.6755 11.675-2.2265 0-4.4065-.6405-6.2795-1.845.332.0375.666.056 1 .055 1.847.004 3.642-.614 5.095-1.755-1.7545-.035-3.2925-1.18-3.83-2.85.254.0485.5115.0735.77.075.365-.0005.728-.0495 1.08-.145-1.9045-.3875-3.277-2.0565-3.29-4v-.05c.5715.312 1.209.4835 1.86.5-1.8145-1.207-2.3715-3.62-1.27-5.5 2.0765 2.561 5.142 4.122 8.435 4.295-.525-2.2055.838-4.419 3.0435-4.9435 1.4385-.3425 2.9495.1155 3.9565 1.1985.919-.182 1.8005-.52 2.605-1-.3065.9505-.948 1.757-1.805 2.27.8105-.0935 1.6025-.3075 2.35-.635-.55.8245-1.2425 1.544-2.045 2.125m-7.955-15.925c-11.046 0-20 8.9545-20 20 0 11.046 8.954 20 20 20s20-8.954 20-20c0-11.0455-8.954-20-20-20"
            fillRule="evenodd"
          />
        </Svg>
      </Base>
    ),
    instagram: (
      <Base href={url} target="_blank" rel="noopener noreferrer">
        <Svg height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m20 23.535v.02c-1.952 0-3.5345-1.583-3.5345-3.535 0-1.9525 1.5825-3.5355 3.5345-3.5355 1.945.0005 3.524 1.571 3.535 3.5155 0 1.952-1.583 3.535-3.535 3.535m0-8.98c-3.0075 0-5.445 2.4375-5.445 5.445 0 3.007 2.4375 5.4445 5.445 5.4445s5.4455-2.4375 5.4455-5.4445c0-3.0075-2.438-5.445-5.4455-5.445m5.66-1.495c-.704 0-1.2745.5705-1.2745 1.275 0 .704.5705 1.2745 1.2745 1.2745.7045 0 1.275-.5705 1.275-1.2745 0-.7045-.5705-1.275-1.275-1.275m4.885 11.315c-.0195.88-.1885 1.751-.5 2.575-.551 1.4235-1.6765 2.549-3.0995 3.0995-.8245.3115-1.695.4805-2.5755.5-1.13.0505-1.5.0655-4.375.0655s-3.24 0-4.375-.0655c-.8805-.0195-1.751-.1885-2.575-.5-1.4235-.5505-2.549-1.676-3.0995-3.0995-.3115-.824-.4805-1.695-.5-2.575-.0505-1.13-.0655-1.5-.0655-4.375s0-3.24.0655-4.375c.0195-.8805.1885-1.7515.5-2.575.5505-1.4235 1.676-2.549 3.0995-3.1.824-.3115 1.6945-.4805 2.575-.5 1.13-.0505 1.5-.065 4.375-.065s3.24 0 4.375.065c.8805.0195 1.751.1885 2.5755.5 1.423.551 2.5485 1.6765 3.0995 3.1.3115.8235.4805 1.6945.5 2.575.05 1.1295.065 1.5.065 4.375s-.015 3.24-.065 4.375m-10.545-24.375c-11.0455 0-20 8.954-20 20 0 11.0455 8.9545 20 20 20 11.046 0 20-8.9545 20-20 0-11.046-8.954-20-20-20"
            fillRule="evenodd"
          />
        </Svg>
      </Base>
    ),
    linkedIn: (
      <Base href={url} target="_blank" rel="noopener noreferrer">
        <Svg height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m29.516 28h-3.9415v-6.172c0-1.472-.027-3.365-2.0495-3.365-2.053 0-2.367 1.603-2.367 3.2585v6.2785h-3.9415v-12.691h3.7835v1.7335h.0525c.5265-.9975 1.813-2.0495 3.732-2.0495 3.994 0 4.7315 2.6285 4.7315 6.0455zm-16.745-14.4265c-1.264 0-2.2875-1.0245-2.2875-2.2875 0-1.261 1.0235-2.286 2.2875-2.286 1.261 0 2.286 1.025 2.286 2.286 0 1.263-1.025 2.2875-2.286 2.2875zm-1.973 14.4265h3.9445v-12.691h-3.9445zm-10.798-8c0 11.046 8.954 20 20 20s20-8.954 20-20c0-11.0455-8.954-20-20-20s-20 8.9545-20 20z"
            fillRule="evenodd"
          />
        </Svg>
      </Base>
    ),
  }[platform]);

export default memo(SocialIcon);

const Base = styled.a``;

const Svg = styled.svg`
  fill: ${colors.linkBlack};
  margin: 0 0.46875rem;

  @media ${viewports.medium} {
    &:hover {
      fill: ${colors.hoverBlack};
    }
  }
`;
