import React, { memo } from 'react';
import styled from 'styled-components';
import { fontFamilies, viewports, colors } from '../../utils/variables';
import Avatar from '../shared/Avatar';
import { useMedia } from 'the-platform';
import MediaUploadItem from '../profile-settings/alumni-wall/MediaUploadItem';

const AlumLink = ({
  activeScreen,
  setActiveScreen,
  setActiveAlum,
  setSidebar,
  alum,
  activeAlum,
}) => {
  const medium = useMedia(viewports.medium);
  return (
    <Base
      active={
        activeAlum
          ? activeScreen === 'alum' && alum.id === activeAlum.id
          : false
      }
      onClick={() => {
        setActiveAlum(alum);
        setActiveScreen('alum');
        if (!medium) setSidebar(undefined);
      }}
    >
      <Avatar small alum={alum} />
      <Content>
        <Name>{`${alum.preferredName || alum.firstName} ${
          alum.lastName
        }`}</Name>
        {(alum.major || alum.year) && (
          <Info
            active={
              activeAlum
                ? activeScreen === 'alum' && alum.id === activeAlum.id
                : false
            }
          >
            {alum.major}
            {alum.graduationYear && alum.graduationYear !== -1 ? `, ${alum.graduationYear}` : null}
          </Info>
        )}
      </Content>
    </Base>
  );
};

export default memo(AlumLink);

const Base = styled.a`
  align-items: center;
  background: ${(props) => (props.active ? colors.blue : 'transparent')};
  cursor: pointer;
  display: flex;
  height: 4.5rem;
  padding: 0 1.875rem;

  @media ${viewports.medium} {
    &:hover {
      background: ${(props) => (props.active ? colors.blue : '#333')};
    }
  }
`;

const Content = styled.div`
  padding-left: 0.75rem;
`;

const Name = styled.div`
  color: #fffff9;
  font-family: ${fontFamilies.bold};
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: 0.125rem;
`;

const Info = styled.div`
  color: ${(props) => (props.active ? '#fffff9' : '#888')};
  font-size: 0.875rem;
  line-height: 17px;
`;
