import React, { memo } from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, navigate, graphql } from 'gatsby';
import GlobalStyle from '../styled/GlobalStyle';
import ErrorHandler from './ErrorHandler';
import { authFromLocalCookie } from '../../utils/auth';

const Layout = ({ children, title, redirect, protectedRoute, admin }) => {
  /**
   * Redirect the user instead of rendering the layout
   * if required
   */
  if (redirect) {
    const authenticated = authFromLocalCookie(admin);
    if (process.browser) {
      if (protectedRoute && !authenticated) navigate(redirect);
      if (!protectedRoute && authenticated) navigate(redirect);
    }

    if (protectedRoute && !authenticated) return null;
    if (!protectedRoute && authenticated) return null;
  }
  const googleScript = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_KEY}&libraries=places`;
  try {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
      >
        {({ site }) => {
          return (
            <>
              <Helmet title={title || site.siteMetadata.title}>
                <html lang="en" />
                <meta charSet="UTF-8" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"/>
                {/* <link rel="stylesheet" type="text/css" href="../../static/fonts/fonts.css" /> */}
                <script src={`${googleScript}`} />
              </Helmet>
              <GlobalStyle />
              <ErrorHandler>{children}</ErrorHandler>
            </>
          );
        }}
      </StaticQuery>
    );
  } catch (error) {
    console.error(error);
  }
};

export default memo(Layout);
