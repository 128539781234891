import gql from 'graphql-tag';

const editPendingUser = gql`
  mutation editPendingUser(
    $id: ID!
    $email: String!
    $firstName: String!
    $lastName: String!
    $portalId: String!
    $preferredName: String
    $birthdateMonth: SelectInput!
    $birthdateDay: SelectInput!
    $birthdateYear: SelectInput!
    $graduationYear: Int!
    $major: String!
    $secondaryMajor: String!
    $streetAddress1: String!
    $streetAddress2: String
    $city: String!
    $state: String!
    $postalCode: String!
    $country: String!
  ) {
    editPendingUser(
      id: $id
      email: $email
      firstName: $firstName
      lastName: $lastName
      portalId: $portalId
      preferredName: $preferredName
      birthdateMonth: $birthdateMonth
      birthdateDay: $birthdateDay
      birthdateYear: $birthdateYear
      graduationYear: $graduationYear
      major: $major
      secondaryMajor: $secondaryMajor
      streetAddress1: $streetAddress1
      streetAddress2: $streetAddress2
      city: $city
      state: $state
      postalCode: $postalCode
      country: $country
    ) {
      id
      email
    }
  }
`;

export default editPendingUser;
