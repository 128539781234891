import React, { memo } from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { fontFamilies, viewports } from '../../utils/variables';

const SectionHeading = ({ sectionName, numAlumni, loading, className }) => {
  const titleSpring = useSpring({
    from: { o: 0, y: 2 },
    to: { o: !loading ? 1 : 0, y: !loading ? 0 : 2 },
    delay: !loading ? 600 : 0,
    immediate: loading,
  });

  const alumniSpring = useSpring({
    from: { o: 0, y: 2 },
    to: { o: !loading ? 1 : 0, y: !loading ? 0 : 2 },
    delay: !loading ? 1200 : 0,
    immediate: loading,
  });

  return !loading ? (
    <Base className={className}>
      <TitleContainer>
        <animated.div
          style={{
            opacity: titleSpring.o.interpolate((opacity) => opacity),
            transform: titleSpring.y.interpolate((y) => `translateY(${y}rem)`),
          }}
        >
          <Title>{sectionName}</Title>
        </animated.div>
      </TitleContainer>
      <AlumniCountContainer>
        <animated.div
          style={{
            opacity: alumniSpring.o.interpolate((o) => o),
            transform: alumniSpring.y.interpolate((y) => `translateY(${y}rem)`),
          }}
        >
          <AlumniCount>{numAlumni} Alumni</AlumniCount>
        </animated.div>
      </AlumniCountContainer>
    </Base>
  ) : null;
};

export default memo(SectionHeading);

const Base = styled.div`
  margin-top: 5rem;
  text-align: center;
  width: 100%;

  @media ${viewports.medium} {
    left: 10rem;
    margin-top: 0;
    top: 5.25rem;
    position: absolute;
    text-align: left;
    width: 50%;
  }
`;

const TitleContainer = styled.div`
  height: 4rem;
  left: 0;
  overflow: hidden;
  top: 0;

  @media ${viewports.medium} {
    position: absolute;
    width: 34.375rem;
  }
`;

const Title = styled.h3`
  font-size: 2.25rem;
  font-family: ${fontFamilies.bold};
  letter-spacing: -0.5px;
  line-height: 58px;

  @media ${viewports.medium} {
    font-size: 3.5rem;
  }
`;

const AlumniCountContainer = styled.div`
  height: 3rem;
  overflow: hidden;
  top: 4.125rem;
  @media ${viewports.medium} {
    position: absolute;
    overflow: visible;
  }
`;

const AlumniCount = styled.h4`
  color: #adada8;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  line-height: 111%;

  @media ${viewports.medium} {
    font-size: 2.25rem;
  }
`;
