import React, { memo } from 'react';
import styled from 'styled-components';
import Button from '../styled/Button';
import { viewports } from '../../utils/variables';

const FilterConfirmation = ({ clear, submit }) => {
  return (
    <Base>
      <Button secondary onClick={clear}>
        Clear All
      </Button>
      <Button onClick={submit}>Apply Filters</Button>
    </Base>
  );
};

export default memo(FilterConfirmation);

const Base = styled.div`
  background: #fff;
  border-top: 1px solid #f2f2f2;
  display: flex;
  margin-top: auto;

  padding: 1.5rem 1.875rem;

  @media ${viewports.medium} {
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
  }
`;
