import React, { memo } from 'react';
import { components } from 'react-select';
import styled from 'styled-components';
import downArrow from '../../images/down-arrow.svg';

const DropdownIndicator = props =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <DownArrowImage src={downArrow} open={props.isFocused} />
    </components.DropdownIndicator>
  );

export default memo(DropdownIndicator);

const DownArrowImage = styled.img`
  position: absolute;
  right: 1.125rem;
  top: 50%;
  transform: ${props =>
    props.open ? 'translate3d(0, -50%, 0) rotate(180deg)' : 'translate3d(0, -50%, 0)'};
`;
