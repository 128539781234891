import React, { memo, useState, useEffect } from 'react';
import shortid from 'shortid';
import PageVisibility from 'react-page-visibility';

const createRandomInt = (min, max) => {
  const randomInt = Math.floor(Math.random() * (max - min + 1)) + min;
  const randomMultiple = randomInt - (randomInt % 80);
  return randomMultiple - 1;
};

const createRandomColor = (activeScene) => {
  if (activeScene <= 1 || activeScene >= 23) {
    const colors = ['#ff4cdb', '#FF9C00', '#FFD800', '#00C1A1', '#338FFF'];
    return colors[Math.floor(Math.random() * colors.length)];
  }
  return '#fff';
};

const createRandomSize = () => {
  const sizes = [20, 15, 10, 5];
  return sizes[Math.floor(Math.random() * sizes.length)];
};

const createRandomXInt = () => {
  const docWidth = document.body.clientWidth;
  const halfDocWidth = docWidth / 2;
  return createRandomInt(halfDocWidth, docWidth);
};

const createRandomYInt = () => {
  const docHeight = document.body.clientHeight;
  return createRandomInt(0, docHeight);
};

const LandingController = ({ children }) => {
  const [activeScene, setActiveScene] = useState(0);
  const [indicators, setIndicators] = useState([]);
  const [docVisible, setDocVisible] = useState(true);

  const changeScene = () => {
    if (docVisible) {
      const nextScene = activeScene >= 23 ? 0 : activeScene + 1;
      setActiveScene(nextScene);
    }
  };

  const createIndicator = () => {
    if (docVisible && activeScene % 2 !== 0) {
      const newIndicator = {
        color: createRandomColor(activeScene),
        x: createRandomXInt(),
        y: createRandomYInt(),
        size: createRandomSize(),
        key: shortid.generate(),
      };
      setIndicators([...indicators, newIndicator]);
    }
  };

  const removeIndicator = (key) => {
    setIndicators([...indicators.filter((_) => _.key !== key)]);
  };

  // useEffect(() => {
  //   const sceneInterval = setInterval(() => {
  //     changeScene();
  //     createIndicator();
  //   }, 1000);
  //   return () => {
  //     clearInterval(sceneInterval);
  //   };
  // });

  return (
    <PageVisibility onChange={(isVisible) => setDocVisible(isVisible)}>
      {() =>
        children({
          activeScene,
          indicators,
          removeIndicator,
        })
      }
    </PageVisibility>
  );
};

export default memo(LandingController);
