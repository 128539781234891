import styled from 'styled-components';
import gridLandingWhite from '../../images/grid-landing-white.svg';
import gridLandingCream from '../../images/grid-landing-cream.svg';
import gridWorld from '../../images/grid-world.svg';
import gridContinent from '../../images/grid-continent.svg';
import gridCountry from '../../images/grid-country.svg';
import gridState from '../../images/grid-state.svg';
import gridCity from '../../images/grid-city.svg';

export const Grid = styled.div`
  background-repeat: repeat;
  background-position: left top;
  bottom: 0;
  left: 0;
  opacity: 0.3;
  position: absolute;
  right: 0;
  top: 0;
`;

export const LandingGridWhite = styled(Grid)`
  background-image: url(${gridLandingWhite});
  background-size: 5rem 5rem;
`;

export const LandingGridCream = styled(Grid)`
  background-image: url(${gridLandingCream});
  background-size: 5rem 5rem;
`;

export const GridWorld = styled(Grid)`
  background-image: url(${gridWorld});
  background-size: 2.5rem 2.5rem;
`;

export const GridContinent = styled(Grid)`
  background-image: url(${gridContinent});
  background-size: 5rem 5rem;
`;

export const GridCountry = styled(Grid)`
  background-image: url(${gridCountry});
  background-size: 5rem 5rem;
`;

export const GridState = styled(Grid)`
  background-image: url(${gridState});
  background-size: 10rem 10rem;
`;

export const GridCity = styled(Grid)`
  background-image: url(${gridCity});
  background-size: 20rem 20rem;
`;
