import React, { memo, useState, useEffect } from 'react';
import styled from 'styled-components';
import countryList from 'react-select-country-list';
import usePlacesAutocomplete, { getZipCode, getGeocode } from 'use-places-autocomplete';
import SelectInput from '../inputs/SelectInput';
import { getStateData } from '../../utils/helperFunctions';
import googleImg from '../../images/powered-by-google.png';

const AddressAutoComplete = ({ field, form, label }) => {
  const { suggestions, setValue } = usePlacesAutocomplete({
    requestOptions: { types: ['address'] },
  });

  const [countries, setCountries] = useState({});
  useEffect(() => {
    setCountries(countryList());
  }, []);
  const onChange = (val) => {
    setValue(val);
  };
  // get zip code from google place id
  const getZip = async (placeId) => {
    let zipData;
    await getGeocode({ placeId })
      .then((results) => getZipCode(results[0], false))
      .then((zipCode) => {
        zipData = zipCode;
      })
      .catch(console.error);
    return zipData;
  };

  // hard coded list of countries where state can not be verified
  const stateInIgnoredCountry = (country) => {
    const ignoredCountryList = ['South Korea'];

    return ignoredCountryList.includes(country);
  };

  // debugger;
  const termsToAddress = (terms) => {
    // console.log(terms);
    let address;
    // if first term is just a number, add street to it
    if (+terms[0].value) {
      // delete street from terms array
      const street = terms.splice(1, 1)[0];
      terms[0].value = `${terms[0].value} ${street.value}`;
    } else if (+terms[1].value) {
      // sometimes in mexico the street number comes second???? this is going off google's data
      // reverse term 1 w/ term 0
      // delete street from terms array
      const street = terms.splice(0, 1)[0];
      terms[0].value = `${terms[1].value} ${street.value}`;
    }

    if (terms.length === 4) {
      address = {
        streetAddress1: terms[0].value ? terms[0].value : '',
        streetAddress2: '',
        city: terms[1].value ? terms[1].value : '',
        state: terms[2].value ? terms[2].value : '',
        country: terms[3].value ? terms[3].value : '',
      };
    } else if (terms.length === 5) {
      address = {
        streetAddress1: `${terms[0].value} ${terms[1].value}`,
        streetAddress2: '',
        city: terms[2].value ? terms[2].value : '',
        state: terms[3].value ? terms[3].value : '',
        country: terms[4] && terms[4].value ? terms[4].value : '',
      };
    } else if (terms.length === 3) {
      address = {
        streetAddress1: terms[0].value,
        streetAddress2: '',
        city: terms[1].value ? terms[1].value : '',
        state: '',
        country: terms[2].value ? terms[2].value : '',
      };
    }
    if (address.state && !stateInIgnoredCountry(address.country)) {
      address.state = getStateData(address.state).name;
    }
    if (address.country === 'USA') address.country = 'United States';
    if (address.country === 'UK') address.country = 'United Kingdom';
    const countryCodeToLabel = countries.getLabel(address.country);
    if (countryCodeToLabel) address.country = countryCodeToLabel;
    return { ...address };
  };

  const streetAddrs = suggestions.data.filter(
    (add) => add.types.includes('street_address') || add.types.includes('premise')
  );

  // select handler- fills in all other address fields
  const onSelect = async (option, { field, form }) => {
    form.setFieldValue(field.name, option);

    if (!option.value || !option.details) {
      return false;
    }
    const address = termsToAddress(option.details.terms);
    address.postalCode = await getZip(option.details.place_id);
    const { values } = form;
    const countryCode = countries.getValue(address.country);
    for (const line in address) {
      if (line === 'country') {
        form.setFieldValue(line, {
          value: countryCode,
          label: address[line],
        });
      } else {
        form.setFieldValue(line, address[line]);
      }
    }

    // console.log('ZIP Code: ', address.postalCode);
  };

  const options = streetAddrs.map((add) => {
    const res = {
      label: add.description,
      value: add.description,
      // address: suggestionToAddress(add.terms),
      details: add,
    };
    return res;
  });

  let noOptionsText;
  if (suggestions.data.length === 0) {
    noOptionsText = 'No options';
    if (!field.value) {
      noOptionsText = 'Start typing to search';
    }
    if (field.value) {
      noOptionsText = 'Search again or edit below';
    }
  } else {
    noOptionsText = 'Loading...';
  }

  return (
    <>
      <SelectInput
        {...field}
        form={form}
        handleInputChange={onChange}
        handleChange={async (option) => onSelect(option, { field, form })}
        value={field.value}
        options={options}
        placeholder="Search for an address, or enter one below"
        noOptionsText={noOptionsText}
        isSearchable
        label={
          <>
            {label}
            <GoogleLogo src={googleImg} />
          </>
        }
      />
    </>
  );
};
export default memo(AddressAutoComplete);
const GoogleLogo = styled.img`
  height: 0.875rem;
  margin-left: auto;
  margin-right: 1rem;
`;
