import React, { memo } from 'react';
import styled from 'styled-components';
import Layout from '../components/shared/Layout';
import Logo from '../components/landing/Logo';
import { fontFamilies, viewports } from '../utils/variables';
import { logoutAdmin } from '../utils/auth';
import PendingUsers from '../components/admin/PendingUsers';

const DashboardPage = () => {
  return (
    <Layout protectedRoute redirect="/admin" admin>
      <Container>
        <Header>
          <Logo />
          <SignOut onClick={logoutAdmin}>Sign Out</SignOut>
        </Header>
        <PendingUsers />
      </Container>
    </Layout>
  );
};

export default memo(DashboardPage);

const Container = styled.main`
  margin: 0 auto;
  padding: 2rem 3rem;
  max-width: 1280px;

  @media ${viewports.medium} {
    flex-direction: column;
    display: block;
    overflow-y: visible;
    padding: 2rem 10rem;
    position: relative;
  }
`;

const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const SignOut = styled.a`
  color: #111111;
  cursor: pointer;
  font-family: ${fontFamilies.bold};
  font-size: 0.875rem;
  padding-left: 1rem;
`;
