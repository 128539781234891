import React, { memo } from 'react';
import styled from 'styled-components';
import { fontFamilies, viewports } from '../../utils/variables';

const IndicatorHover = ({ size, color, location, className, onClick }) => (
  <Base size={size - 3.5} color={color} className={className} onClick={onClick}>
    {location}
  </Base>
);

export default memo(IndicatorHover);

const Base = styled.span`
  align-items: center;
  background: rgba(255, 255, 247, 0.5);
  border-radius: 50%;
  border: 1rem ${props => props.color} solid;
  color: #424242;
  cursor: pointer;
  display: flex;
  font-family: ${fontFamilies.bold};
  font-size: 0.9375rem;
  height: 7.125rem;
  justify-content: center;
  letter-spacing: -0.64px;
  line-height: 18px;
  margin: 1rem 1.125rem;
  padding: 0 0.5rem;
  position: relative;
  text-align: center;
  width: 7.125rem;
  z-index: 0;

  @media ${viewports.medium} {
    border: 2rem ${props => props.color} solid;
    height: ${props => props.size}rem;
    font-size: 1.125rem;
    left: 50%;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform-origin: center;
    transform: translate3d(-50%, -50%, 0);
    width: ${props => props.size}rem;
  }

  &:after {
    border: 0.5rem ${props => props.color} solid;
    border-radius: 50%;
    content: '';
    height: 8.125rem;
    left: 50%;
    opacity: 0.5;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 8.125rem;

    @media ${viewports.medium} {
      border: 3rem ${props => props.color} solid;
      height: ${props => props.size + 2}rem;
      width: ${props => props.size + 2}rem;
    }
  }
`;
