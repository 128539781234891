import styled from 'styled-components';
import { colors, fontFamilies } from '../../utils/variables';

export const Heading = styled.h3`
  color: ${colors.contentBlack};
  font-size: 1.75rem;
  font-family: ${fontFamilies.bold};
  letter-spacing: -0.3px;
  line-height: 35px;
  margin-bottom: 0.25rem;

  & span {
    color: #999999;
  }
`;

export const Subheading = styled.h4`
  border-bottom: 2px solid #f4f4f4;
  color: #999999;
  font-size: 1rem;
  line-height: 20px;
  margin-bottom: 2.5rem;
  padding-bottom: 1.25rem;

  strong {
    color: ${colors.contentBlack};
    font-family: ${fontFamilies.bold};
  }
`;

export const BodyText = styled.p`
  color: #999;
  font-size: ${(props) => props.size || '1rem'};
  line-height: 137.5%;
  margin-bottom: 2.5rem;
`;

export const FieldHeading = styled.div`
  color: ${colors.red};
  font-family: ${fontFamilies.bold};
  font-size: 0.75rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`;

export const Label = styled.label`
  color: #222;
  display: flex;
  font-size: 0.875rem;
  font-family: ${fontFamilies.medium};
  flex-shrink: 0;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0.375)}rem;
`;
