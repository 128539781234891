import { getProgramCode } from './helperFunctions';

export const birthdateMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
].map((_) => ({ value: _, label: _ }));

export const getBirthdateDays = (month) => {
  let numDays = 31;
  if (month) {
    const numDaysValue = {
      january: 31,
      february: 29,
      march: 31,
      april: 30,
      may: 31,
      june: 30,
      july: 31,
      august: 31,
      september: 30,
      october: 31,
      november: 30,
      december: 31,
    };
    const { value } = month;
    numDays = numDaysValue[value.toLowerCase()];
  }
  return Array.from(Array(numDays), (_, x) => ({
    value: (x + 1).toString(),
    label: (x + 1).toString(),
  }));
};

const currentYear = new Date().getFullYear();

export const birthdateYears = Array.from(Array(currentYear - 1899), (_, x) => ({
  value: (x + 1900).toString(),
  label: (x + 1900).toString(),
})).reverse();

export const graduationYears = Array.from(Array(currentYear - 1929), (_, x) => ({
  value: (x + 1930).toString(),
  label: (x + 1930).toString(),
})).reverse();

export const majors = [
  'Advertising (ADVT)',
  'Advertising Illustration (ADIL)',
  'Broadcast Cinema (GBCP)',
  'Communications (COMM)',
  'Art Criticisim & Theory (CRIT)',
  'Entertainment Design (ENTX) (ENTD)',
  'Environmental Design (ENVL)',
  'Fashion Illustration (FASH)',
  'Film (FILM)',
  'Fine Art (FINE)',
  'Fine Art Media (FAME)',
  'Grad Art (GART)',
  'Grad Environmental Design (GENV)',
  'Grad Film (GFLM)',
  'Grad Industrial Design (GIDP)',
  'Grad Media Design Practices (GMDP)',
  'Graphic Design (GRPH)',
  'Graphics / Packaging (GRPK)',
  'Grad Transportation Design (GTRN)',
  'Illustration (ILLU)',
  'Industrial Design (INDU)',
  'Interaction Design (INTR)',
  'New Media (NEWM)',
  'Photography (PHOT)',
  'Product Design (PROD)',
  'Transportation Design (TRAN)',
  'Grad Graphic Design (GGFX)',
]
  .sort()
  .map((_) => ({ value: _, label: _ }));

export const industries = [
  'Accounting',
  'Advertising',
  'Airlines/Aviation',
  'Alternative Medicine',
  'Animation',
  'Apparel & Fashion',
  'Architecture & Planning',
  'Architecture/Engineering',
  'Art Direction',
  'Arts and Crafts',
  'Augmented Reality',
  'Automotive',
  'Aviation & Aerospace',
  'Banking',
  'Biotechnology',
  'Branding',
  'Broadcast Media',
  'Building Materials',
  'Business',
  'Capital Markets',
  'Character Design',
  'Chemicals',
  'Civic & Social Organization',
  'Civil Engineering',
  'Commercial Real Estate',
  'Computer Games',
  'Computer Hardware',
  'Computer Software',
  'Construction',
  'Consulting',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Cosmetics',
  'Costume Design',
  'Culinary Arts',
  'Defense & Space',
  'Design',
  'Digital Media',
  'Editorial Design',
  'Education',
  'Education Management',
  'E-Learning',
  'Electrical/Electronic Manufacturing',
  'Entertainment',
  'Entertainment Design',
  'Entrepreneurship',
  'Environmental Design',
  'Environmental Services',
  'Events Services',
  'Exhibition Design',
  'Fashion',
  'Film/TV',
  'Finance',
  'Financial Services',
  'Fine Art',
  'Fishery',
  'Food & Beverages',
  'Food Production',
  'Foundation',
  'Furniture',
  'Gambling & Casinos',
  'Gaming',
  'Government',
  'Government Administration',
  'Government Relations',
  'Graphic Design',
  'Health & Wellness',
  'Health, Wellness and Fitness',
  'Higher Education',
  'Hospital & Health Care',
  'Hospitality',
  'Human Resources',
  'Illustration',
  'Import and Export',
  'Individual & Family Services',
  'Industrial Automation',
  'Industrial Design',
  'Industries',
  'Information Services',
  'Information Technology',
  'Information Technology and Services',
  'Insurance',
  'Interaction Design',
  'Interior Design',
  'International Affairs',
  'International Trade and Development',
  'Internet',
  'Investment Management',
  'Jewelry Design',
  'Landscape Design',
  'Law Enforcement',
  'Law Practice',
  'Legal',
  'Legal Services',
  'Libraries',
  'Logistics and Supply Chain',
  'Luxury Goods & Jewelry',
  'Management Consulting',
  'Maritime',
  'Marketing',
  'Marketing and Advertising',
  'Mechanical or Industrial Engineering',
  'Media Design',
  'Media Production',
  'Medical Devices',
  'Medical Practice',
  'Mental Health Care',
  'Military',
  'Military and Protective Services',
  'Mining & Metals',
  'Motion Pictures and Film',
  'Museums and Institutions',
  'Music',
  'Newspapers',
  'Non-Profit',
  'Nonprofit Organization Management',
  'Online Media',
  'Package/Freight Delivery',
  'Packaging',
  'Packaging and Containers',
  'Pattern Design',
  'Performing Arts',
  'Pharmaceuticals',
  'Philanthropy',
  'Photography',
  'Primary/Secondary Education',
  'Printing',
  'Printmaking',
  'Product/Industrial Design',
  'Professional Training & Coaching',
  'Program Development',
  'Public Policy',
  'Public Relations',
  'Public Relations and Communications',
  'Public Safety',
  'Publishing',
  'Ranching',
  'Real Estate',
  'Recreational Facilities and Services',
  'Religious Institutions',
  'Renewables & Environment',
  'Research',
  'Restaurants',
  'Retail',
  'Science',
  'Shipbuilding',
  'Social Services',
  'Software',
  'Sound Design',
  'Sporting Goods',
  'Sports',
  'Staffing and Recruiting',
  'Supermarkets',
  'Telecommunications',
  'Textiles',
  'Think Tanks',
  'Tobacco',
  'Toy Manufacturing',
  'Translation and Localization',
  'Transportation Design',
  'Transportation/Trucking/Railroad',
  'Typography',
  'UI/UX',
  'Urban Planning',
  'Utilities',
  'Veterinary',
  'Visual Effects/Motion Graphics',
  'Web Design/Development',
  'Wholesale',
  'Wine and Spirits',
  'Wireless',
  'Writing and Editing',
].map((_) => ({ value: _, label: _ }));

export const locationTypes = [
  { label: 'Continent', value: 'Continent' },
  { label: 'Country', value: 'Country' },
  { label: 'State/Territory', value: 'StateTerritory' },
  { label: 'City', value: 'City' },
];
