import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import { viewports } from '../../utils/variables';
import LocationFinder from './LocationFinder';
import togglePageLoading from '../../data/mutations/togglePageLoading';
import getPageLoading from '../../data/queries/getPageLoading';
import PageLoader from '../shared/PageLoader';

const LocationPin = ({ fetchLocation, highlighted }) => {
  const [locationLoading, setLocationLoading] = useState(false);
  return (
    <Mutation
      mutation={togglePageLoading}
      refetchQueries={[{ query: getPageLoading }]}
    >
      {(handleTogglePageLoading) => (
        <LocationFinder
          fetchLocation={fetchLocation}
          toggleLoading={async (pageLoading) => {
            await handleTogglePageLoading({ variables: { pageLoading } });
            setLocationLoading(pageLoading);
          }}
        >
          {({ geolocate }) =>
            !locationLoading ? (
              <>
                <Base
                  height="40"
                  viewBox="0 0 40 40"
                  width="40"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={!highlighted ? geolocate : () => {}}
                  highlighted={highlighted}
                >
                  <g fill="none" fillRule="evenodd">
                    <circle cx="20" cy="20" r="18.5" strokeWidth="3" />
                    <path d="m23.2415259 18.6270291c-.031039 1.6801561-1.4035038 3.0526209-3.0836598 3.0836599-1.7840693.0337381-3.2375055-1.4196981-3.2037675-3.2037674.0310391-1.6815056 1.4021544-3.0539704 3.0836599-3.0836599 1.7840693-.0323886 3.236156 1.4196981 3.2037674 3.2037674m2.5816374-5.8555799c-3.1619322-3.16193225-8.2887698-3.16193225-11.450702 0-3.1632817 3.1632817-3.1632817 8.2901193 0 11.450702l5.1308861 5.1308861c.3279341.3292836.8609957.3292836 1.1889297 0l5.1308862-5.1308861c3.1619322-3.1605827 3.1619322-8.2874203 0-11.450702" />
                  </g>
                </Base>
              </>
            ) : (
              <PageLoader />
            )
          }
        </LocationFinder>
      )}
    </Mutation>
  );
};

export default memo(LocationPin);

const Base = styled.svg`
  left: 0.9375rem;
  cursor: pointer;
  height: 2.5rem;
  pointer-events: ${(props) => (props.highlighted ? 'none' : 'auto')};
  position: fixed;
  top: 0.9375rem;
  width: 2.5rem;
  z-index: ${(props) => (props.highlighted ? '100001' : '5')};

  & circle {
    fill: #fffff7;
    stroke: #ebebe3;
  }

  & path {
    fill: #ccccc6;
  }

  @media ${viewports.medium} {
    bottom: 1.25rem;
    left: 1.25rem;
    position: absolute;
    right: auto;
    top: auto;

    &:hover {
      & circle {
        fill: #ccccc6;
        stroke: #ccccc6;
      }

      & path {
        fill: #fff;
      }
    }
  }
`;
