import gql from 'graphql-tag';

const resetPassword = gql`
  mutation resetPassword($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token) {
      id
      email
    }
  }
`;

export default resetPassword;
