import React, { memo } from 'react';
import styled from 'styled-components';

const ClearIcon = ({ className, onClick }) => (
  <Base
    className={className}
    onClick={onClick}
    viewBox="0 0 9.47 9.47"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" strokeMiterlimit="10" strokeWidth="1.2">
      <path d="m.24.24 9 9" />
      <path d="m9.24.24-9 9" />
    </g>
  </Base>
);

export default memo(ClearIcon);

const Base = styled.svg`
  height: 0.5625rem;
  width: 0.5625rem;
  stroke: #fff;
`;
