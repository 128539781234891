import React, { memo } from 'react';

const FilterIcon = () => (
  <svg height="19" viewBox="0 0 22 19" width="22" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="#ccc" strokeWidth="2" transform="translate(1 1)">
      <path d="m.44736842 2.23684211h19.23684208" strokeLinecap="square" />
      <circle cx="12.973684" cy="2.236842" fill="#fff" r="2.236842" />
      <path d="m.44736842 14.7631579h19.23684208" strokeLinecap="square" />
      <circle cx="12.973684" cy="14.763158" fill="#fff" r="2.236842" />
      <path
        d="m.44736842 8.5h19.23684208"
        strokeLinecap="square"
        transform="matrix(-1 0 0 -1 19.68421 17)"
      />
      <circle
        cx="6.710526"
        cy="8.5"
        fill="#fff"
        r="2.236842"
        transform="matrix(-1 0 0 -1 13.421052 17)"
      />
    </g>
  </svg>
);

export default memo(FilterIcon);
