import React, { memo } from 'react';
import Button from '../styled/Button';
import LoadingIcon from './LoadingIcon';

const SubmitButton = ({
  disabled,
  submitting,
  children,
  className,
  onClick,
}) => (
  <Button
    onClick={onClick}
    type="submit"
    className={className}
    disabled={disabled}
  >
    {!submitting ? children : <LoadingIcon />}
  </Button>
);

export default memo(SubmitButton);
