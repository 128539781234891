import React, { memo, useContext, useState } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { fontFamilies, colors, viewports } from '../../utils/variables';
import PieChart from './PieChart';
import { AlumniFetcherContext } from './AlumniFetcher';
import { ActiveFiltersContext } from './ActiveFilters';
import getInfographic from '../../data/queries/getInfographic';
import LoadingIcon from '../shared/LoadingIcon';

const statsItems = [
  {
    name: 'Majors',
    key: 'majors',
    colorPalette: ['#00C1A1', '#55D6BE', '#AAEADA', '#EBEBE3'],
  },
  {
    name: 'Class Year',
    key: 'classYears',
    colorPalette: ['#FF4CDB', '#FF96E7', '#FFC7EF', '#EBEBE3'],
  },
  {
    name: 'Industries',
    key: 'industries',
    colorPalette: ['#FF9C00', '#FFBD52', '#FFDEA5', '#EBEBE3'],
  },
];

const Infographic = () => {
  const { searchValue, locationVariables } = useContext(AlumniFetcherContext);
  const { activeFilters } = useContext(ActiveFiltersContext);
  const [dataLoaded, setDataLoaded] = useState(false);
  return (
    <Query
      query={getInfographic}
      variables={{
        locationId: +locationVariables.id,
        searchValue,
        activeFilters,
      }}
      onCompleted={() => {
        setDataLoaded(true);
      }}
    >
      {({ data, loading }) => {
        // console.log(dataLoaded, loading);
        if (!dataLoaded || loading) {
          return <LoadingIcon />;
        }
        let infographic;
        if (data) {
          infographic = data.infographic;
        } else {
          return <LoadingIcon />;
        }

        return (
          <>
            <Base>
              {statsItems.map(({ name, key, colorPalette }) => (
                <ChartContainer key={key}>
                  <PieChart colorScale={colorPalette} stats={infographic[key]} />
                  <Stats>
                    <Title>{name}</Title>
                    {infographic[key].map((item, index) => (
                      <StatContainer key={item.name}>
                        <Bullet backgroundColor={colorPalette[index]} />
                        <Stat>
                          {item.name} <Percentage>{item.percent}%</Percentage>
                        </Stat>
                      </StatContainer>
                    ))}
                  </Stats>
                </ChartContainer>
              ))}
            </Base>
            <Footnote>*Showing the top three for each category at this location</Footnote>
          </>
        );
      }}
    </Query>
  );
};

export default memo(Infographic);

const Base = styled.div`
  justify-content: space-between;
  padding-bottom: 4rem;
  position: relative;
  width: 100%;

  @media ${viewports.medium} {
    display: flex;
    left: 50%;
    position: absolute;
    top: 33%;
    transform: translateX(-50%);
    width: 62.5%;
  }
`;

const ChartContainer = styled.div`
  display: flex;
  flex: 0 0 33.33%;
  flex-direction: column;
  margin: 1rem auto 2rem;
  padding: 0 1.875rem;

  @media ${viewports.medium} {
    display: block;
    flex-direction: row;
    margin: 0;
  }
`;

const Title = styled.div`
  color: ${colors.contentBlack};
  font-family: ${fontFamilies.bold};
  font-size: 1rem;
  line-height: 20px;
  margin: 0 0 0.625rem;

  @media ${viewports.medium} {
    margin: 2.75rem 0 0.625rem;
  }
`;

const Stats = styled.div`
  margin-top: 0.875rem;

  @media ${viewports.medium} {
    margin-top: 0;
  }
`;

const StatContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 0.75rem;
`;

const Stat = styled.div`
  align-items: center;
  color: #adada8;
  font-family: ${fontFamilies.bold};
  line-height: 125%;
`;

const Bullet = styled.span`
  background: ${(props) => props.backgroundColor};
  border-radius: 50%;
  display: block;
  flex: 0 0 0.625rem;
  margin-right: 0.625rem;
  height: 0.625rem;
  width: 0.625rem;
`;

const Percentage = styled.div`
  color: #ccccc6;
  display: inline;
  font-family: ${fontFamilies.regular};
`;

const Footnote = styled.div`
  bottom: 1.375rem;
  color: #adada8;
  display: none;
  font-size: 0.75rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 62.5%;

  @media ${viewports.medium} {
    display: block;
  }
`;
