import React, { PureComponent } from 'react';
import ForgotPassword from './ForgotPassword';

class ForgotPasswordController extends PureComponent {
  state = { activeScreen: undefined };

  setActiveScreen = screen => this.setState({ activeScreen: screen });

  render() {
    const { children, toggleSidebar } = this.props;
    const { activeScreen } = this.state;

    return activeScreen !== undefined ? (
      <ForgotPassword
        toggleSidebar={toggleSidebar}
        activeScreen={activeScreen}
        setActiveScreen={this.setActiveScreen}
      />
    ) : (
      children({ activeScreen, setActiveScreen: this.setActiveScreen })
    );
  }
}

export default ForgotPasswordController;
