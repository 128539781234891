import React, { memo } from 'react';

const RightNavArrow = ({ className }) => (
  <svg
    height="14"
    viewBox="0 0 10 14"
    width="10"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="m927 37.4545455.039773-.0397728 2.545454 2.5454546-7.039772 7.0397727-2.545455-2.5454545 4.454545-4.4545455-4.454545-4.4545455 2.545455-2.5454545z"
      fillRule="evenodd"
      transform="translate(-920 -33)"
    />
  </svg>
);

export default memo(RightNavArrow);
