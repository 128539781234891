import React, { memo, Fragment, useContext } from 'react';
import styled from 'styled-components';
import { MultiPageFormContext } from './MultiPageForm';
import ProgressNavIndicator from './ProgressNavIndicator';
import ProgressNavDivider from './ProgressNavDivider';

const ProgressNav = ({ numScreens, firstIndex }) => {
  const { formScreen, flow } = useContext(MultiPageFormContext);
  return (
    formScreen < numScreens + firstIndex && (
      <Base>
        <Nav>
          {numScreens &&
            Array.from(Array(numScreens).keys()).map(screenIndex => {
              return (
                <Fragment key={screenIndex}>
                  <ProgressNavIndicator
                    active={formScreen === screenIndex + firstIndex}
                    completed={formScreen > screenIndex}
                    screenIndex={screenIndex}
                  />
                  {screenIndex !== numScreens - 1 && (
                    <ProgressNavDivider
                      screenIndex={screenIndex}
                      flow={flow}
                      formScreen={formScreen}
                      firstIndex={firstIndex}
                      completed={
                        flow === 'forward'
                          ? formScreen > screenIndex + firstIndex
                          : formScreen > screenIndex - 1 + firstIndex
                      }
                    />
                  )}
                </Fragment>
              );
            })}
        </Nav>
      </Base>
    )
  );
};

export default memo(ProgressNav);

const Base = styled.div`
  margin-bottom: 2.1875rem;
  text-align: center;
`;

const Nav = styled.nav`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;
  z-index: 0;
`;
