import React, { memo } from 'react';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';
import { colors } from '../../utils/variables';

const ErrorMessage = ({ visible, error, height, className }) => {
  const transition = useTransition(visible, null, {
    from: { h: 0, o: 0 },
    enter: { h: height || 18, o: 1 },
    leave: { h: 0, o: 0 },
  });
  return transition.map(
    ({ item, key, props }) =>
      item && (
        <animated.div
          key={key}
          style={{
            height: props.h.interpolate(h => `${h}px`),
            opacity: props.o.interpolate(o => o),
          }}
        >
          <Base className={className}>{error}</Base>
        </animated.div>
      )
  );
};

export default memo(ErrorMessage);

const Base = styled.div`
  color: ${colors.red};
  font-size: 0.75rem;
  line-height: 125%;
  overflow: hidden;
  position: relative;
  top: 0.25rem;
  width: 100%;
`;
