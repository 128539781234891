import React, { useState, memo, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useMedia, useWindowSize } from 'the-platform';
import shortid from 'shortid';
import PageVisibility from 'react-page-visibility';
import Indicator from '../shared/Indicator';
import { AlumniFetcherContext } from './AlumniFetcher';
import indicatorMarkers from './indicatorMarkers';
import { viewports } from '../../utils/variables';
import IndicatorHover from '../shared/IndicatorHover';

const WorldIndicators = ({ locations }) => {
  const [indicators, setIndicators] = useState([]);
  const [docVisible, setDocVisible] = useState(true);

  const hasHoverState = (indicator) => {
    const { label } = indicator;
    const isActive = locations.some((location) => location.name === label);
    return isActive ? label : null;
  };

  const createIndicators = () => {
    if (docVisible) {
      const locationIndicators = indicatorMarkers.map((indicator) => ({
        ...indicator,
        key: shortid.generate(),
      }));
      setIndicators(locationIndicators);
    }
  };

  useEffect(() => {
    createIndicators();
    const sceneInterval = setInterval(createIndicators, 5000);
    return () => clearInterval(sceneInterval);
  }, []);

  const { fetchLocation } = useContext(AlumniFetcherContext);
  const { width, height } = useWindowSize();
  const mapWidth = 1385 * 1.1;
  const mapHeight = 750 * 1.2;
  const medium = useMedia(viewports.medium);
  return (
    <PageVisibility onChange={(isVisible) => setDocVisible(isVisible)}>
      {medium ? (
        <Base>
          {indicators.map((indicator) => {
            // if (indicator.label === 'Oceania') {
            //   debugger;
            // }

            return (
              hasHoverState(indicator) && (
                <Indicator
                  color={indicator.color}
                  size={indicator.size}
                  x={`${(indicator.x / mapWidth) * 100}%`}
                  y={`${(indicator.y / mapHeight) * 100}%`}
                  delay={indicator.delay}
                  location={indicator.label}
                  key={indicator.key}
                  onClick={() =>
                    fetchLocation({
                      level: 1,
                      id: +indicator.id,
                    })
                  }
                />
              )
            );
          })}
        </Base>
      ) : (
        <MobileBase>
          {indicators.map(
            (indicator) =>
              hasHoverState(indicator) && (
                <IndicatorHover
                  color={indicator.color}
                  size={indicator.size}
                  location={indicator.label}
                  key={indicator.key}
                  onClick={() =>
                    fetchLocation({
                      level: 1,
                      id: +indicator.id,
                    })
                  }
                />
              )
          )}
        </MobileBase>
      )}
    </PageVisibility>
  );
};

export default memo(WorldIndicators);

const Base = styled.div`
  bottom: 0;
  display: block;
  left: 0;
  padding-bottom: 54.1054451%;
  padding-top: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const MobileBase = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  margin: 1.25rem auto;
  width: 18.75rem;
`;
