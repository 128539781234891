import React, { memo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { viewports } from '../../utils/variables';
import IndicatorHover from './IndicatorHover';
import IndicatorCircle from './IndicatorCircle';

const Indicator = ({ color, x, y, size, location, onClick, removeIndicator }) => {
  const [visible, setVisible] = useState(false);

  const opacitySpring = useSpring({
    from: { o: location ? 1 : 0 },
    to: { o: visible || location ? 1 : 0 },
    delay: visible ? 0 : 600,
    config: {
      tension: 30,
      friction: 20,
    },
    onRest: removeIndicator,
  });

  useEffect(() => {
    setVisible(true);
    const timeout = setTimeout(() => setVisible(false), 1750);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Base x={x} y={y} size={size} onClick={onClick} location={location}>
      <animated.div style={{ opacity: opacitySpring.o.interpolate(o => o) }}>
        <Svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 100 100"
          color={color}
          size={size}
          location={location}
        >
          {[
            {
              radius: 50,
              opacity: 0.15,
              delay: 0,
            },
            {
              radius: 35,
              opacity: 0.3,
              delay: 200,
            },
            {
              radius: 25,
              opacity: 0.45,
              delay: 400,
            },
          ].map(({ radius, opacity, delay }) => (
            <IndicatorCircle radius={radius} opacity={opacity} delay={delay} key={radius} />
          ))}
          <circle cx="50" cy="50" r="8" />
        </Svg>
      </animated.div>
      {location && <Hover size={size} color={color} location={location} />}
    </Base>
  );
};

export default memo(Indicator);

const Base = styled.span`
  height: ${props => (props.location ? '7.125' : props.size)}rem;
  left: ${props => props.x};
  margin: ${props => (props.location ? '0 1rem' : '0')};
  position: ${props => (props.location ? 'static' : 'absolute')};
  top: ${props => props.y};
  transform-origin: center;
  transform: translate3d(-50%, -50%, 0);
  width: ${props => (props.location ? 'auto' : `${props.size}rem`)};
  z-index: 1;

  @media ${viewports.medium} {
    height: ${props => props.size}rem;
    position: absolute;
    width: ${props => props.size}rem;
  }
`;

const Svg = styled.svg`
  display: none;
  fill: ${props => props.color};
  height: ${props => props.size}rem;
  transform-origin: center;
  visibility: hidden;
  width: ${props => props.size}rem;

  @media ${viewports.medium} {
    display: block;
    visibility: visible;
  }

  ${Base}:hover & {
    opacity: ${props => (props.location ? 0 : 1)};
    visibility: ${props => (props.location ? 'hidden' : 'visible')};
  }
`;

const Hover = styled(IndicatorHover)`
  @media ${viewports.medium} {
    ${Base}:hover & {
      opacity: 1;
      visibility: visible;
    }
  }
`;
