import React, { memo } from 'react';
import styled from 'styled-components';
import { fontFamilies, colors, viewports } from '../../utils/variables';
import { FieldHeading } from '../styled/Typography';

const Info = ({ alum }) => {
  return (
    alum && (
      <Base>
        {(alum.major || alum.graduationYear) && (
          <>
            <FieldHeading>Education</FieldHeading>
            <Fields>
              {alum.major && (
                <Field>
                  <FieldTitle>Major</FieldTitle>
                  <FieldInfo>{alum.major}</FieldInfo>
                </Field>
              )}
              {alum.graduationYear && alum.graduationYear !== -1 && (
                <Field>
                  <FieldTitle>Class Year</FieldTitle>
                  <FieldInfo>{alum.graduationYear}</FieldInfo>
                </Field>
              )}
            </Fields>
          </>
        )}
        {((alum.currentCompany && alum.visibleFields.currentCompany) ||
          (alum.currentJobTitle && alum.visibleFields.currentJobTitle) ||
          (alum.currentIndustry && alum.visibleFields.currentIndustry)) && (
          <>
            <FieldHeading>Career</FieldHeading>
            <Fields>
              {alum.currentCompany && alum.visibleFields.currentCompany && (
                <Field>
                  <FieldTitle>Company</FieldTitle>
                  <FieldInfo>{alum.currentCompany}</FieldInfo>
                </Field>
              )}
              {alum.currentJobTitle && alum.visibleFields.currentJobTitle && (
                <Field>
                  <FieldTitle>Job Title</FieldTitle>
                  <FieldInfo>{alum.currentJobTitle}</FieldInfo>
                </Field>
              )}
              {alum.currentIndustry && alum.visibleFields.currentIndustry && (
                <Field>
                  <FieldTitle>Industry</FieldTitle>
                  <FieldInfo>{alum.currentIndustry}</FieldInfo>
                </Field>
              )}
            </Fields>
          </>
        )}
        {((alum.email && alum.visibleFields.email) ||
          (alum.website && alum.visibleFields.website)) && (
          <>
            <FieldHeading>Contact</FieldHeading>
            <Fields>
              {alum.email && alum.visibleFields.email && (
                <Field>
                  <FieldTitle>Email</FieldTitle>
                  <FieldInfo>{alum.email}</FieldInfo>
                </Field>
              )}
              {alum.website && alum.visibleFields.website && (
                <Field>
                  <FieldTitle>Website</FieldTitle>
                  <FieldInfo>
                    <WebsiteLink
                      target="_blank"
                      rel="noopener noreferrer"
                      href={alum.website}
                    >
                      {alum.website}
                    </WebsiteLink>
                  </FieldInfo>
                </Field>
              )}
            </Fields>
          </>
        )}
        {alum.bio && alum.visibleFields.bio && (
          <>
            <FieldHeading>About</FieldHeading>
            <AboutText>{alum.bio}</AboutText>
          </>
        )}
      </Base>
    )
  );
};

export default memo(Info);

const Base = styled.div`
  background-color: #ffffff;
  box-shadow: -3px 0 10px 3px rgba(0, 0, 0, 0.08);
  padding: 3.75rem;

  @media ${viewports.medium} {
    height: 100vh;
    margin-left: 30%;
    width: 68%;
  }
`;

const Fields = styled.div`
  margin-bottom: 2.5rem;
`;

const Field = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-family: ${fontFamilies.medium};
  font-size: 0.875rem;
  line-height: 18px;
  margin-bottom: 0.625rem;
`;

const FieldTitle = styled.div`
  color: #222222;
  width: 7rem;
`;

const FieldInfo = styled.div`
  color: #777777;
  font-family: ${fontFamilies.regular};
  font-size: 0.875rem;
  line-height: 18px;
`;

const WebsiteLink = styled.a`
  color: ${colors.blue};
  text-decoration: none;

  @media ${viewports.medium} {
    &:hover {
      color: ${colors.hoverBlue};
    }
  }
`;

const AboutText = styled.p`
  color: #777777;
  font-size: 0.875rem;
  line-height: 20px;
`;
