import React, { memo } from 'react';
import styled from 'styled-components';
import { useMedia } from 'the-platform';
import { useSpring, animated } from 'react-spring';
import AnimatedText from './AnimatedText';
import { fontFamilies, viewports } from '../../utils/variables';
import Button from '../styled/Button';

const Content = ({ activeScene, toggleSidebar, visible }) => {
  const medium = useMedia(viewports.medium);

  const subtitleSpring = useSpring({
    from: { o: 0, y: 2 },
    to: { o: 1, y: 0 },
    delay: 900,
  });

  const buttonTransition = useSpring({
    from: { o: 0 },
    to: { o: 1 },
    delay: 1200,
  });

  return (
    <Base visible={visible}>
      <AnimatedText activeScene={activeScene} />
      <SubtitleContainer>
        <animated.div
          style={{
            opacity: subtitleSpring.o.interpolate(o => o),
            transform: subtitleSpring.y.interpolate(y => `translateY(${y}rem)`),
          }}
        >
          <Subtitle>Network with ArtCenter alumni across the globe</Subtitle>
        </animated.div>
      </SubtitleContainer>
      {!medium && (
        <animated.div
          style={{
            opacity: buttonTransition.o.interpolate(o => o),
          }}
        >
          <RegisterButton onClick={() => toggleSidebar('register')}>Register</RegisterButton>
        </animated.div>
      )}
    </Base>
  );
};

export default memo(Content);

const Base = styled.div`
  color: #111;
  left: 1.25rem;
  position: absolute;
  transform: translate3d(0, -50%, 0);
  top: 50%;
  transition: visibility 1ms linear ${props => !props.visible && '1000ms'};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  width: 90%;
  z-index: 10;

  @media ${viewports.medium} {
    left: 5rem;
    visibility: visible;
    width: 100%;
  }
`;

const SubtitleContainer = styled.div`
  height: 4rem;
  left: 0;
  margin-top: 1rem;
  overflow: hidden;
`;

const Subtitle = styled.h2`
  font-size: 0.925rem;
  font-family: ${fontFamilies.medium};
  letter-spacing: -0.3px;
  line-height: 125%;

  @media ${viewports.medium} {
    font-size: 1.125rem;
  }
`;

const RegisterButton = styled(Button)`
  margin-left: 0;
  margin-top: 2rem;
`;
